
/**
 *  routes.jsx
 *  @version: 1.0.0
 *  @author: DWIT MÉXICO - Sergio
 *  @description: Manejo de todas las rutas de la aplicación
 * 	@process: 4
 */

import React, { Component, useEffect, useState } from "react";
import { BrowserRouter, Route, Switch, Redirect, useHistory } from "react-router-dom";

// Conexión con el Context API
import { GlobalContext, Consumer } from "../context";

// Páginas Web
import Login from '../pages/login';

import toaster from "toasted-notes";
import Error from '../components/errors/error';
//Restaurantes
import Res_Config from '../pages/restaurantes/configuracion';
import Res_Platos from '../pages/restaurantes/platos';
import Res_Pedidos from '../pages/restaurantes/pedidos';
import PasswordForgot from "../pages/passwordForgot";
import PasswordReset from "../pages/passwordReset";
import cogoToast from 'cogo-toast';
import "toasted-notes/src/styles.css";
import {Howl} from 'howler';
/**Socket */
import Socket from './socket';

import Request from './httpClient';

const req = new Request();

const PrivateRoute = ({ component: Component, ...rest }) => (
    <Route
        {...rest}
        render={
            props => {
                const usuario = window.localStorage.getItem("iGateAdminR");
                if (usuario) {
                    return <Component {...props} />;
                } else {
                    let path = window.location.pathname;
                    if(path.includes(".")){
                        window.location = "https://documentos.appmosphera.com/"+path;
                    }
                    
                    return (
                        <Redirect to={{ pathname: "/", state: { from: props.location } }} />
                    );
                }
            }
        }
    />
);

/*const VigenciaPassword = Consumer(({ context }) => {
	let history = useHistory();
	const { location } = history;

	async function validarVencimiento(usuario) {
		const res = await req.post('/users/password/vencimiento', usuario);
		if (!res.error) {
			if (res.vencida) {
				const modal = document.getElementById('expiry-change-password');

				if (modal) {
					context.setExpiratePassword(true);
					modal.classList.remove('hide-dinamic-modal');
					modal.classList.add('show-dinamic-modal');
				}
			}
		}
	}

	useEffect(() => {
		if (!(location.pathname === '/login' || location.pathname === '/' || location.pathname === '/contraseña/actualizar')) {
			const usuario = window.localStorage.getItem("iGateAdmin");
                if (usuario) {
					validarVencimiento(JSON.parse(usuario));
				}
		}
	}, [location.pathname]);

	return <CambiarPassword />
});*/

const Routes = (props) => {
	const [beepSond, setbeepSond] = useState('https://documentos.appmosphera.com/sounds/beep.mp3');
	const [sound, setSound] = useState({
		sound:       new Howl({
			src:        'https://documentos.appmosphera.com/sounds/beep.mp3',
			html5:      true,
			// loop:       true,
		})
	});


	function playSound(url){
		console.log("Playing sound");
		const audio = new Audio(url);
		audio.play();
	}

	useEffect(() => {

		Socket.connect();

		Socket.on('newPedidoRestaurante', (data) => {

			if(data && data.id_estado < 4) {
				try {
					playSound("https://cdn.freesound.org/previews/616/616698_12364629-lq.mp3");
				} catch (error) {
					console.log(error);
				}
			}
			
			const selectMensaje = (data) => {

				if(!data) return 'Se ha realizado un nuevo pedido en restaurantes.';

				if(data.id_estado === 5) return "Se ha cancelado pedido";

				return 'Se ha realizado un nuevo pedido en restaurantes.';
			}

			if(data && data.id_estado === 4) {
				cogoToast.success("Se ha entregado pedido por repartidor", { hideAfter: 8 }).finally(() => console.log("Escondiendo Toast"));
			} else if(data.update === undefined) {
				cogoToast.info(selectMensaje(data), { hideAfter: 8 }).finally(() => console.log("Escondiendo Toast"));
			} 

		});
    });


    function renderRoutes() {
        // RUTAS PRIVADAS
        let routes = [
            {path:'/restaurantes/configuracion',                component:Res_Config,               private:false},
            {path:'/restaurantes/platos',                       component:Res_Platos,               private:false},
            {path:'/restaurantes/pedidos',                      component:Res_Pedidos,              private:false},
		];

        let routesRender = [];
        
        routesRender = routes.map( (route, index)  =>{
			if (route.private)
            	return <PrivateRoute key={index} exact path ={route.path}  component={route.component} />
			else
				return <Route key = {index} exact path = {route.path} component = {route.component} />
        });

        routesRender.push(<Route exact path ={'/'} component={Login} key={routes.length + 2}/>);
        routesRender.push(<Route exact path ={'/login'} component={Login} key={routes.length + 3}/>);
		routesRender.push(<Route exact path={"/password/forgot"} component={PasswordForgot} key={"password/forgot"}/>);
		routesRender.push(<Route exact path={"/password/reset/:token"} component={PasswordReset} key={"password/reset"}/>);
        routesRender.push(<PrivateRoute component={Error} key={routes.length + 11}/>);
        
        return routesRender;
    }

	return (
		<GlobalContext>
			<BrowserRouter>
				{/*<VigenciaPassword />*/}
				<div className="flex main" style={{ height: "100%" }}>
					<div className="column full" style={{ height: "100%" }}>
						<Switch>
							{renderRoutes()}
						</Switch>
					</div>
				</div>
			</BrowserRouter>
		</GlobalContext>
	);
}


export default Routes;