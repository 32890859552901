/**
 *  httpClient.js
 *  @version: 1.0.0
 *  @author: DWIT MÉXICO - Sergio
 *  @description: Cliente HTTP para todas las peticiones Web
*/

import request from "superagent";
import { URL_API } from './urlsApi';

class Request {

    get(url, data) {
        const result = request
            .get(URL_API + url)
            .query(data)
            .set({ 'igate_key': '5fcae9a7be1dfa53da44c353492bc73d' })
            .then(res => {
                return res.body;
            })
            .catch(err => {
                //console.log(err.message);
                return { error: true, message: err.message };
            });
        return result;
    }

    post(url, data) {
		const result = request
			.post(URL_API + url)
            .send(data)
			.set({ 'igate_key': '5fcae9a7be1dfa53da44c353492bc73d' })
            .then(res => {
                return res.body;
            })
            .catch(err => {
                //console.log(err.message);
                return { error: true, message: err.message };
            });
        return result;
    }

    put(url, data) {
		const result = request
			.put(URL_API + url)
            .send(data)
			.set({ 'igate_key': '5fcae9a7be1dfa53da44c353492bc73d' })
            .then(res => {
                return res.body;
            })
            .catch(err => {
                //console.log(err.message);
                return { error: true, message: err.message };
            });
        return result;
    }

    postFile(url, data, formdata) {
		const result = request
			.post(URL_API + url)
            // .send(data)
            .accept('application/json')
            .set('Content-Type', 'multipart/form-data')
            .set("Content-Type", "application/octet-stream")
			.set({'igate_key': '5fcae9a7be1dfa53da44c353492bc73d' })
            .attach(formdata)
            .type('form')

            .then(res => {
                return res.body;
            })
            .catch(err => {
                //console.log(err.message);
                return { error: true, message: err.message };
            });
            // .set("Content-Type", "application/octet-stream")

        return result;
	}

	//Nuevo Metodo Post File (Personal)
	async new_postFile(url, files, data) {
		const response = await new Promise((res) => {
			const postRequest = request.post(`${URL_API}${url}`);

			if (Array.isArray(files)) {
				files.forEach((file, index) => {
					if (file) {
						postRequest.attach(`file_${index + 1}`, file);
					}
				});
			}
			if (data) {
				const keys = Object.keys(data);
				keys.forEach((key) => {
                    // console.log(key);
					if (data[key] !== undefined) {
                        // console.log(data);
						postRequest.field(key, data[key]);
					}
				});
			}

			postRequest
				.set({ 'igate_key': '5fcae9a7be1dfa53da44c353492bc73d' })
				.set("Accept", "application/json")
				.then((resp) => {
					res(resp.body || {error: true, message: "error indefinido"});
				})
				.catch((error) => {
					res(error);
				});
		});

		return response;
	}
}

export default Request;
