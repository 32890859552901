import React from 'react';
import moment from 'moment-timezone';

export default function DiasCierreTab(props) {

    const { diasCierre } = props;

    const RenderBody = () => {

        return diasCierre.length > 0 ? <ContentView/> : <NoContentView/>
    }

    const ContentView = () => {

       return diasCierre.map((dia, index) => {

           return <React.Fragment key={index}>
               <tr style={{ padding: 2}}>
                   <td className="text-left">{dia.descripcion}</td>
                   <td className="text-center">{moment(dia.dia_cierre).format("DD/MM/YYYY")}</td>
               </tr>
           </React.Fragment>
       })
    }

    const NoContentView = () => {

        return (
            <tr style={{ pointerEvents: "none" }}>
                <td colSpan="2">
                    <div className="justify-center">
                        <h4>No se encontraron resultados.</h4>
                    </div>
                </td>
            </tr>
        ) 
    }

    return (
        <div className="column">
            <h4>Dias de Cierre</h4>
            <div className="white-space-4"></div>
            <table className='table-hover'>
                <thead className="sticky-th">
                    <tr>
                        <th className="text-left">Motivo</th>
                        <th className="text-center">Fecha</th>
                    </tr>
                </thead>
                <tbody className='overflow-auto hideScrollbar' style={{ maxHeight: 225 }}>
                    <RenderBody />
                </tbody>
            </table>
        </div>
    )
}
