
export function disponibilidadReducer(disponibilidad, action) {

    const modifyCondominio = (prop, value) => {

        return disponibilidad.map((item, index) => {
            if (item.id === action.key) {

                const condominio = Object.assign({}, item);
                condominio[prop] = value;

                return condominio;
            } else {
                return item;
            }
        })
    }

    switch (action.type) {

        case disponibilidadAction.set: {
            return action.values;
        }
        case disponibilidadAction.modify: {
            return modifyCondominio(action.modify, action.value);
        }
        default: {
            throw Error('Unknown action: ' + action.type);
        }

    }

}

const disponibilidadAction = {
    set: "setDisponibilidades",
    modify: "modifyDisponibilidad",
}

