import React, { Component } from 'react';
import { Helmet } from "react-helmet";
import cogoToast from 'cogo-toast';

//Middlewares
import Permissions from '../../middlewares/Permissions';

//Context
import { Consumer } from "../../context";

//Components
import Header from "../../components/header/header";
import SidebarOperacion from "../../components/header/sidebarOperacion";
import Controls from '../../components/controls/controls';
import DinamicModal from '../../components/modals/dinamicModal/dinamicModal';
import Paginador from "../../components/paginador/paginador";
import ConfiguracionTable from '../../components/restaurantes/configuracion/table';
import Form from '../../components/restaurantes/configuracion/restauranteModal/form';
import DiasCierre from '../../components/restaurantes/configuracion/diasCierre';
import DisponibilidadCondominiosTab from "../../components/restaurantes/configuracion/restauranteModal/disponibilidadCondominiosTab";
import DiasCierreTab from '../../components/restaurantes/configuracion/restauranteModal/diasCierreTab';
import SuperModal, { SubmitType } from '../../components/modals/superModal/SuperModal';
import ModalCore from '../../components/modals/core/ModalCore';

//Helpers
import sortableData from '../../helpers/sortableDataTable';
import ValidityHelper from '../../helpers/inputValidityHelper';

// HTTP Client
import Request from "../../core/httpClient";

const request = new Request();
const validityHelper = new ValidityHelper();
const modal = new ModalCore();

let values = {};
let nuevoDiaCierre = {};

class Configuracion extends Component {
	constructor(props) {
		super(props);

		this.state = {
			showModal: false,
			commonAreas: [],
			categories: [],
			categoria: 0,
			filterData: [],
			keys: [],
			page: 1,
			filter: '',
			subfraccionamientos: [],
			dias_cierre: [],
			subfraccionamiento: localStorage.getItem('subfrac') || 0,
			fraccionamiento: localStorage.getItem('frac') || 0,
			escritura: true,
			lectura: true,
			logo: require("../../img/logo-AppMosphera-r.png"),
			menu: null,
			idModalOpen: "",
			direction: {
				nombre: 'desc'
			},
			filterColumn: {
				col: '',
				filt: ''
			},
			newRestaurant: {horario_semanal: null, metodos_pago: null,}
		}

		//this.validarPermisos();
	}

	async componentDidMount() {

		this.getRestaurantes();
		this.getDiasCierre();

	}

	/*async validarPermisos(){
		let permisos = new Permissions();
		//  permisos.getResult();
		let permisosInfo = await permisos.getResult()
		await this.setState({escritura: permisosInfo.escritura, lectura: permisosInfo.lectura});
	}*/

	componentDidUpdate(prevProps, prevState) {

	}

	changeRestaurant(property, value){
        this.setState({ newRestaurant: {...this.state.newRestaurant, [property]: value} });
    }

	async openModal(idModal) {
		await this.setState({ message: null, imageArea: '' });
		modal.openModal(idModal);
		this.setState({ isModalOpen: idModal });
		const input = document.getElementById('name');
		if (input) {
			input.focus();
		}
	}

	closeModal(idModal) {
		const modal = document.getElementById(idModal);
		if (modal) {
			modal.classList.add('hide-dinamic-modal');
			modal.classList.remove('show-dinamic-modal');
		}
	}

	/*funcion del paginador*/
	setPage(page) {
		this.setState({ page });
	}

	async reload() {

		this.getRestaurantes();
		this.getDiasCierre();
	}

	changeFrac() {
		this.getRestaurantes()
	}

	subChange(sub) {
		this.setState({ subfraccionamiento: sub });
	}

	setFilter(filter) {
		this.setState({ filter: filter.toLowerCase() });
	}

	filter(Restaurantes) {
		Restaurantes = Restaurantes.filter(res => {
			if (res.nombre?.toLowerCase().indexOf(this.state.filter) !== -1) {
				return true;
			} else {
				return false;
			}
		});
		return Restaurantes;
	}

	async getRestaurantes() {
		this.setState({ loadingRestaurantes: true });

		const user = await this.props.context.loadUser();
		if (user) {
			if (user.auth) {

				const res = await request.post('/restaurantes/getAll', { idUser: user.id.Id_Usuario });

				if (Array.isArray(res.restaurantes)) {
					this.setState({ restaurantes: res.restaurantes });
				} else {
					this.setState({ empty: true, restaurantes: [], message: res.message || 'No se pudo obtener información.' });
				}
			}
		}

		this.setState({ loadingRestaurantes: false });
	}

	async getDiasCierre() {

		this.setState({ loadingDiasCierre: true });

		try {

			const user = await this.props.context.loadUser();
			
			const data = {
				idUser: user.id.Id_Usuario
            };
			
			const response = await request.get("/restaurantes/diasCierre/getAll", data);

			if (response) {

				const { dias_cierre } = response;

                if (response.error) {

                    cogoToast.error(response.message, {
                        position: "bottom-right",
                    });
                };

                if (dias_cierre) {

					const newDiasCierre = dias_cierre.map(item => {

						const itemCopy = {...item};

						itemCopy.id_restaurantes = JSON.parse(item.id_restaurantes);

						return itemCopy;

					})

					this.setState({ dias_cierre: newDiasCierre });
                };
            };

		} catch (error) {
			console.log(error);
		}

		this.setState({ loadingDiasCierre: false });
	}

	async addDiasCierre() {

		try {

			const user = await this.props.context.loadUser();

			if(!user) return;

			const data = {
				dia_cierre: nuevoDiaCierre.dia_cierre,
				descripcion: nuevoDiaCierre.descripcion,
				idRestaurantes: JSON.stringify(nuevoDiaCierre.id_restaurantes),
				idUser: user.id.Id_Usuario
			};

			const response = await request.post("/restaurantes/diasCierre/add", data);

			if (!response) return;

			if (response.error) {
				cogoToast.error(response.message, {
					position: "bottom-right",
				});
			};

			if (response.added) {

				cogoToast.success("Fecha feriada agregada correctamente.", {
					position: "bottom-right"
				});
			
				this.getDiasCierre();
			};

		} catch (error) {
			console.log(error);
		}
	}

	async removeDiasCierre(dia_cierre) {

		try {

			const user = await this.props.context.loadUser();

			if(!user) return;

			const data = {
				dia_cierre,
				idUser: user.id.Id_Usuario
			};

			const response =  await request.post("/restaurantes/diasCierre/delete", data);

			if(!response) return;

			if (response.error) {
				cogoToast.error(response.message, {
					position: "bottom-right",
				});
			};

			if (response.deleted) {

				cogoToast.success("Fecha eliminada correctamente.", {
					position: "bottom-right"
				});

				this.getDiasCierre();
			};


		} catch (error) {
			console.log(error);
		}
	}

	sortableTable(key) {
		let sd = new sortableData();
		const { items, direction } = sd.sortBy(key, this.state.restaurantes, this.state.direction[key]);

		this.setState({
			restaurantes: items,
			direction: {
				[key]: direction
			},
			filterColumn: {
				col: key,
				filt: direction
			}
		});
	}

	render() {
		let restaurantes = [];

		if (Array.isArray(this.state.restaurantes)) {
			restaurantes = this.state.restaurantes;
			restaurantes = this.filter(restaurantes);
		}
		let menu =  this.state.menu;

		return (
			<div className="admin column">
				<Helmet>
					{/*<title>{process.env.REACT_APP_NAME} - Configuración Restaurantes</title>*/}
				</Helmet>
				<Header
					reload={this.reload.bind(this)}
					change={this.changeFrac.bind(this)}
					sidebar={true}
					active={'activas'}
					parent={'areas'}
					panel={'panel3'}
					nav={'restaurantes'}

					menu={this.state.menu != null ? this.state.menu : null}
				/>

				<div className="row">
					<div className="column" id="content">
						<div className="justify-center">
							<div className="container column">
								{this.state.lectura ?
									<React.Fragment>
										<Controls title="Configuración restaurantes"
											nuevo={() => this.setState({ idModalOpen: "newRestaurante" })}
											dataSet={restaurantes}
											setFilter={this.setFilter.bind(this)}
											subChange={this.subChange.bind(this)}
											newButton={true}
											selectSub={false}
										/>
										<div className="navigation align-center justify-end" style={{ marginBottom: 16}}>
											<button
												type="button"
												className="btn btn-primary btn-small color-white"
												onClick={() => this.openModal("editDiasCierre")}
											> 
												<i className="fas fa-calendar-times font-text" />
												&nbsp; Días Feriados
											</button>
										</div>
										{this.state.loadingRestaurantes ?
											<div className="row justify-center">
												<i className="fas fa-spinner fa-spin"></i>
											</div>
											:
											<ConfiguracionTable
												restaurantes={this.state.restaurantes}
												page={this.state.page}
												escritura={this.state.escritura}
												reload={this.reload.bind(this)}
												sortBy={this.sortableTable.bind(this)}
												filterColumn={this.state.filterColumn}
												formModal={this.EditRestauranteModal.bind(this)}
											/>
										}
										<div className="white-space-16"></div>
										<Paginador pages={Math.ceil(restaurantes.length / 7)} setPage={this.setPage.bind(this)} />

									</React.Fragment> :
									null
								}

							</div>
						</div>
					</div>
				</div>
				{
					this.DiasCierreModal()
				}
				{
					this.NewRestauranteModal()
				}
			</div>
		);
	}

	EditRestauranteModal(modalProps, formProps, disponibilidadProps) {

		const idModal = "editRestaurante";

		const menuScreens = this.renderEditResScreens(idModal, formProps, disponibilidadProps)

		return (
			<SuperModal
				{...modalProps}
				id={idModal}
				size={"static-nav"}
				title={"Editar Restaurante"}
				menuScreens={menuScreens}
				formProps={SuperModal.SetFormProps({ type: SubmitType.ALL, noValidate: true })}
				submitProps={SuperModal.SetFooterSubmitProps({ show: true, label: "Guardar", actionCheck: modalProps.onSubmit })}
				cancelProps={SuperModal.SetFooterCancelProps({ show: true, label: 'Cancelar'})}
				// nextProps={SuperModal.SetFooterNextProps({ show: true, label: "Siguiente", actionCheck: modalProps.preventNext })}
				// previousProps={SuperModal.SetFooterPreviousProps({ show: true, label: "Previo" })}
			/>
		)
	}

	NewRestauranteModal(){

		const idModal = 'newRestaurante';

		const openModal = this.state.idModalOpen === idModal;

		const menuScreens = this.renderNewResScreens(idModal, openModal);

		return (
			<SuperModal
				id={idModal}
				size={"static-nav"}
				title={"Añadir restaurante"}
				menuScreens={menuScreens}
				openModal={openModal}
				onCloseModal={() => this.setState({ idModalOpen: "" })}
				loading={this.state.loadingRestaurantes}
				submitProps={SuperModal.SetFooterSubmitProps({ show: true, label: "Guardar", actionCheck: this.handleSubmit.bind(this) })}
				cancelProps={SuperModal.SetFooterCancelProps({ show: true, label: 'Cancelar'})}
			/>
		)
	}

	renderNewResScreens(idModal, openModal){

		return [
			{
				title: "Nuevo Restaurante",
				component: (
					<Form
					    restaurante={this.state.newRestaurant}
						isModalOpen={openModal}
						idModal={idModal}
						getValues={this.getValues.bind(this)}
						subfraccionamientos={this.state.subfraccionamientos}
						onChangeRestaurant={this.changeRestaurant.bind(this)}
					/>
				)
			}
		]
	}

	renderEditResScreens(idModal, formProps, disponibilidadProps){

		return [
			{
				title: "General",
				component: (
					<Form
						{...formProps}
						idModal={idModal}
						validateCats={true}
						validateHors={true}
						subfraccionamientos={this.state.subfraccionamientos}
						DiasCierreTab={(props) => 
							<DiasCierreTab
								{...props}
								idModal={idModal}
								diasCierreDisponibles={this.state.dias_cierre}
							/>
						}
					/>
				)
			},
			{
				title: "Disponibilidad por Condominio",
				component: (
					<DisponibilidadCondominiosTab
						{...disponibilidadProps}
						idModal={idModal}
					/>
				)		
			}
		]
	}

	DiasCierreModal() {

		const idModal = 'editDiasCierre';

		return (
			<DinamicModal
				idModal={idModal}
				sizeModalContainer={'big'}
				title={'Días feriados'}
				closeModal={() => this.setState({ isModalOpen: "" })}
			>
				<DiasCierre
					idModal={idModal}
					onValueChange={this.getNuevoDiaCierre.bind(this)}
					restaurantes={this.state.restaurantes}
					diasCierre={this.state.dias_cierre}
					addDiaCierre={this.addDiasCierre.bind(this)}
					removeDiaCierre={this.removeDiasCierre.bind(this)}
					loadingDiasCierre={this.state.loadingDiasCierre} 
				/>
			</DinamicModal>
		)
	}

	getNuevoDiaCierre(data) {
		nuevoDiaCierre = {...nuevoDiaCierre, ...data};
	}

	getValues(data) {
        values = {...values, ...data};
	}

	async handleSubmit(event) {
		event.preventDefault();

		const validInputs = validityHelper.checkCustomValidity(event.target);

		if(!validInputs) return false;

		const user = await this.props.context.loadUser();

		const res = await request.post('/restaurantes/create', { 
			...this.state.newRestaurant, 
			...values, 
			idRestauranteAdmin: user.id.id_restaurante_administracion });
		
		if (res.created) {
			this.reload();
			this.closeModal('newRestaurante');
			cogoToast.success('Restaurante agregado.', {
				position: 'bottom-right',
			});
		} else {
			//this.setState({message: res.message || 'No se pudo agregar el restaurante.'});
			cogoToast.error('No se pudo agregar restaurante.', {
				position: 'bottom-right',
			});
		}
		this.setState({ loadingSave: false });

		return res.created === true;
	}
}

export default Consumer(Configuracion);