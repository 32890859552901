import React, { Component } from 'react';
import { Helmet } from "react-helmet";
import Header from "../../components/header/header";
import SidebarOperacion from "../../components/header/sidebarOperacion";
import Controls from '../../components/controls/controls';
import Paginador from "../../components/paginador/paginador";
import Request from "../../core/httpClient";
import Permissions from '../../middlewares/Permissions';
import PlatosTable from '../../components/restaurantes/platos/table';
import ModalCore from '../../components/modals/core/ModalCore';
import { Consumer } from "../../context";

const request = new Request();
const modal = new ModalCore();

let values = {};

class Platos extends Component {
	constructor(props){
		super(props);

		this.state = {
			showModal: 				false,
			restaurantes: 			[],
			page: 					1,
			filter: 				'',
			subfraccionamiento: 	localStorage.getItem('subfrac') || 0,
            fraccionamiento: 		localStorage.getItem('frac') || 0,
			escritura:				true,
			lectura:				true,
			logo:					require ("../../img/logo-AppMosphera-r.png"),
            menu:					null,
            isModalOpen:            false,
            categorias:             [],
            horarios:               [],
            id_restaurante:         localStorage.getItem('plaIdRes') || 0,
            id_categoria: 			localStorage.getItem('plaIdCat') || 0
		}

		//this.validarPermisos();
	}

    componentDidMount() {
        this.getRestaurantes();
	}

	/*async validarPermisos(){
		let permisos = new Permissions();
		//  permisos.getResult();
		let permisosInfo = await permisos.getResult()
		await this.setState({escritura: permisosInfo.escritura, lectura: permisosInfo.lectura});
	}*/

	componentDidUpdate(prevProps, prevState) {
		
	}

	async openModal(idModal) {
		await this.setState({ message: null, imageArea: ''});
        modal.openModal(idModal);
        this.setState({isModalOpen: true });
        const input = document.getElementById('name');
        if (input) {
            input.focus();
        }
    }

	closeModal(idModal) {
        modal.closeModal(idModal);
	}

	/*funcion del paginador*/
	setPage(page) {
		this.setState({ page });
	}

	reload() {
		this.getRestaurantes()
	}

	changeFrac() {
		this.getRestaurantes()
	}

	subChange(sub) {
		this.setState({ subfraccionamiento: sub });
	}

	setFilter(filter) {
		this.setState({ filter: filter.toLowerCase() });
	}

	filter(Restaurantes) {
		Restaurantes = Restaurantes.filter(res => {
			if (res.nombre?.toLowerCase().indexOf(this.state.filter) !== -1) {
				return true;
			} else {
				return false;
			}
		});
		return Restaurantes;
	}

    async getRestaurantes() {
        this.setState({loading: true});

        const user = await this.props.context.loadUser();
        if (user) {
            if (user.auth) {
                const data = {
                    idUser: user.id.Id_Usuario
                }
                const res = await request.post('/restaurantes/getAll', data);
                //console.log("Res: ", res);
                if (Array.isArray(res.restaurantes)) {
                    this.setState({restaurantes: res.restaurantes});
                    const id_restaurante = localStorage.getItem('plaIdRes');
                    if (id_restaurante) {
                        this.setState({id_restaurante});
                        this.getCategorias(id_restaurante);
                    }
                } else {
                    // console.log('Empty');
                    this.setState({empty: true, restaurantes: []/*, message: res.message || 'No se pudo obtener información.'*/});
                    localStorage.removeItem('plaIdRes');
                    this.setState({ id_restaurante: 0 });
                }
            }
        }

        this.setState({loading: false});
    }

    getCategorias(id_restaurante) {
        if (id_restaurante == 0) {
            localStorage.setItem('plaIdCat', 0);
            this.setState({categorias: [], id_categoria: 0});
            return;
        }
        let categorias = [];
        const restaurante = this.state.restaurantes.find(res => res.id_restaurante == id_restaurante);
        if (restaurante) {
            categorias = JSON.parse(restaurante.categorias || '[]');
            categorias = categorias.filter(cat => cat.id_status > 0);
            this.setState({categorias});
        }
    }

    changeRestaurante(event) {
        const id_restaurante = event.target.value;
        this.setState({id_restaurante});
        localStorage.setItem('plaIdRes', id_restaurante);
        this.getCategorias(id_restaurante);
    }

    changeCategoria(event) {
        const id_categoria = event.target.value;
        this.setState({id_categoria});
        localStorage.setItem('plaIdCat', id_categoria);
    }

    findPedidos() {
        
    }

	render() {
        let restaurantes = [];
        let categorias = [];
		let dataSet;

		if (Array.isArray(this.state.restaurantes)) {
			restaurantes = this.state.restaurantes;
			dataSet = restaurantes;
			restaurantes = this.filter(restaurantes);
        }

        if (Array.isArray(this.state.categorias)) {
            categorias = this.state.categorias;
        }

		let menu =  this.state.menu;
		
		return (
			<div className="admin column">
				<Helmet>
					{/*<title>{process.env.REACT_APP_NAME} - Platos</title>*/}
				</Helmet>
				<Header 
					reload	=	{this.reload.bind(this)} 
					change	=	{this.changeFrac.bind(this)} 
					sidebar	=	{true} 
					active	=	{'activas'} 
					parent	=	{'restaurante'} 
					panel	=	{'panel3'} 
					nav		=	{'platos'} 

					menu = {this.state.menu != null ? this.state.menu : null}
					/>
					
				<div className="row">
					<div className="column" id="content">
						<div className="justify-center">
							<div className="container column">
                                {this.state.lectura &&
                                <React.Fragment>
                                    <Controls title="Platos"
                                        nuevo		=	{this.openModal.bind(this, "newPlato")}
                                        dataSet		=	{dataSet}
                                        setFilter	=	{this.setFilter.bind(this)}
                                        subChange	=	{this.subChange.bind(this)} 
                                        newButton	=	{this.state.escritura}
                                        selectSub	=	{false}
                                    />
                                    <div className="row justify-start align-center">
                                        <div className="column justify-center">
                                            <p>Restaurante:</p>
                                            <select className="input input-modals" value={this.state.id_restaurante} onChange={this.changeRestaurante.bind(this)}>
                                                <option value="">Selecciona un restaurante</option>
                                                {restaurantes.map(res => <option key={res.id_restaurante} value={res.id_restaurante}>{res.nombre}</option>)}
                                            </select>
                                        </div>
                                        &nbsp;
                                        <div className="column justify-center">
                                            <p>Categoría:</p>
                                            <select className="input input-modals" value={this.state.id_categoria} onChange={this.changeCategoria.bind(this)}>
                                                <option value="0">Todas</option>
                                                {categorias.map(cat => <option key={cat.id} value={cat.id}>{cat.categoria}</option>)}
                                            </select>
                                        </div>
                                        {//&nbsp;
                                            /*
                                            <div className="column justify-center" style={{width:'30%'}}>
                                                <div className="white-space-16"/>
                                                <button
                                                    className="btn btn-primary btn-small color-white"
                                                    disabled={this.state.loading}
                                                    onClick={this.findPedidos.bind(this)}>
                                                    {!this.props.loading ?
                                                        <i className="fas fa-search" />
                                                        : <i className="fas fa-spinner fa-spin" />
                                                    }
                                                    &nbsp; OBTENER
                                                </button>
                                            </div>*/
                                        }
                                    </div>
                                    <div className="white-space-16" />
                                    {this.state.loading ?
                                        <div className="row justify-center">
                                            <i className="fas fa-spinner fa-spin"></i>
                                        </div>
                                        :
                                        <PlatosTable
                                            restaurantes    =   {this.state.restaurantes}
                                            page            =   {this.state.page}
                                            escritura       =   {this.state.escritura}
                                            reload          =   {this.reload.bind(this)}
                                            id_restaurante  =   {this.state.id_restaurante}
                                            id_categoria    =   {this.state.id_categoria}
                                        />
                                    }
                                </React.Fragment>
                            }
							</div>
						</div>
					</div>
				</div>
			</div>
		);
    }
}

export default Consumer(Platos);
