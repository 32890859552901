import React, { Component }from 'react';

class StringHelper  {
    constructor(){
        let persona = [{nombre:'Hernan', apellido: 'Romero', edad: 31},{nombre:'Andres', apellido: 'Romero', edad: 21},{nombre:'Katherine', apellido: 'Feliz', edad: 22}]
        this.getFilteredData(persona,'nombre,apellido,edad','andres 22')
    }
    /**
     * 
     * @param {Array a filtrar} data 
     * @param {Indexes del array separados por comas} indexes 
     * @param {valores a buscar separados por espacios} pattern 
     * Ejemplo
     * [{nombre:'',apellido:''}{nombre:'',apellido:''}],'nombre,edad','Emma 36'
     */
    getFilteredData(data,indexes,pattern){
        // console.log(this.superFilterData(data,indexes,pattern));
    }

    superFilterData(data,indexes,pattern){
        let datosFiltrados = [];
        if(data.length > 0){
            let atributes   = indexes.split(',');
            pattern         = this.eliminarCaracteresRaros(pattern.toString().toLowerCase().trim());
            let patterns    = pattern.split('_');
            let value, boo = false;


            
            data.forEach(dat => {
                boo = false;
                atributes.forEach(attr => {
                    value = dat[attr].toString().toLowerCase().trim();
                    // console.log( '%c%s','color: green; background: yellow; font-size: 24px;',dat['nombre'])
                    patterns.forEach(pat => {
                        // console.log(`${value} -MATCH- ${pat}`)
                        if(value.match(pat)){
                            // console.log("Matched");
                            boo = true;
                            return;
                        }
                    });
                    
                    
                });
                if(boo){
                    datosFiltrados.push(dat);
                }
            });
            

        }
        if(datosFiltrados.length > 0) return datosFiltrados;
        return data;
        
    }

    eliminarCaracteresRaros(string,eliminarEspacios){
        // pero dejando la ñ

        var specialChars = "!@#$^&%*()+=-[]\/{}|:<>?,._";
        // Los eliminamos todos
        for (var i = 0; i < specialChars.length; i++) {
            string= string.replace(new RegExp("\\" + specialChars[i], 'gi'), '');
        }   
        string = string.toLowerCase();
        if(eliminarEspacios){
            string = string.replace(/ /g,"");
        }else{
            string = string.replace(/ /g,"_");
        }

        string = string.replace(/á/gi,"a");
        string = string.replace(/é/gi,"e");
        string = string.replace(/í/gi,"i");
        string = string.replace(/ó/gi,"o");
        string = string.replace(/ú/gi,"u");
        string = string.replace(/ü/gi,"u");

        string = string.replace(/(_)\1+/gmi, "$1");
        // eliminar el ultimo caracter si es _

        let el_ultimo_caracter_es_guion_bajo = true;

        while(el_ultimo_caracter_es_guion_bajo){

            let ultimo_caracter = string.charAt(string.length-1);
            if(ultimo_caracter === "_"){
                string = string.slice(0, -1);
            }else{
                el_ultimo_caracter_es_guion_bajo = false;
            }
        }

        if(string.charAt(0) === "_"){
            string = string.slice(1, string.length);
        }



        return string;
                
    }

    // string en el cual buscar coincidencias ej: "CASA HABITACION 500"
    // palabra a buscar "CASA 500"
    // Sensitivo a mayusculas, mandar como minuscuila si no se desea
    multiMatch(string, busqueda ){
        let hizoMatch = false;
        if(busqueda){
            let busquedas = busqueda.split("_");

            busquedas.forEach(bus => {
                if(string.match(bus)) {
                    hizoMatch =  true;
                };
            });

        }

        return hizoMatch
        
    }


    
        
}
    



export default  StringHelper;