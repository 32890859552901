import React, { useState, useEffect } from "react";
import Resizer from 'react-image-file-resizer';
import Request from "../../../core/httpClient";
import TimeField from "react-simple-timefield";
import emptyImg from "../../../img/empty_image.png";
const request = new Request();

const Form = (props) => {
    const [prevProps, setPrevProps] = useState(props);
    const [Plato, setPlato] = useState({ id_horario: "" });
    const [imagePlato, setImagePlato] = useState('');

    useEffect(() => {
        //if (prevProps.isModalOpen !== props.isModalOpen) {
            if (props.plato) {
                const { plato } = props;

				if (plato.imagen) {
					if (plato.imagen.indexOf('data:image/') === 0) {
						const data = {
							img: plato.imagen,
							id: plato.id_plato,
							type: 2
						}
						base64ToUrl(data);
					} else {
						setImagePlato(plato.imagen);
					}
				} else {
					setImagePlato(plato.imagen);
				}
                setPlato(plato);
            } else {
                setPlato({
					id_restaurante: '',
					nombre: '',
					codigo: '',
					descripcion: '',
					promocion: '',
					precio: '',
					id_categoria: '',
					id_horario: '',
					activo: false
				});
                setImagePlato('');
            }
        //}
        //setPrevProps(props);
        
    }, [props.plato]);

	useEffect(() => {
		props.getValues({ ...Plato, imagen: imagePlato });
	}, [Plato, imagePlato])

	async function base64ToUrl(info) {
		const data = {
			type: info.type,
			img: info.img,
			idfrac: localStorage.getItem('frac') || 0,
			id: info.id,
			module: "restaurantes"
		}

		const res = await request.post("/restaurantes/base64ToUrl", data);
		if (res) {
			if (!res.error) {
				setImagePlato(res.url);
			}
		}
	}

    async function changeImg(event) {
        let file = event.target;
        if(file) {
            if(file.files[0]) {
                Resizer.imageFileResizer (
                    file.files[0],
                    400,//337
                    200,//180
                    'JPEG',
                    30,
                    0,
                    async uri => {
                        await setImagePlato(uri);
                    },
                    'base64'
                );
            }
        }
	}

	const getHorarioRango = () => {

		const horario = props.horarios.find(hor => hor.id === Plato.id_horario);

		return horario || { hora_inicio: "--:--", hora_fin: "--:--" };
	}

	let horarioLimites = getHorarioRango();

    return (
        <div className="full column align-center">
			<div className="input-form-content row full align-center">
				<div className="column label-normal">
					<p>
						<b>Nombre:</b>
					</p>
				</div>
				<div className="column full">
					<input 
						type		=	"text"
						name		=	"Nombre"
						id          =   "nombre"
						value		=	{Plato.nombre || ''}
						onChange    =   {(event) => setPlato({...Plato, nombre: event.target.value})}
						required
						className	=	"input input-modals"						
					/>
				</div>
			</div>
			<div className="white-space-8"></div>
			<div className="input-form-content row full align-center">
				<div className="column label-normal">
					<p>
						<b>Categoría:</b>
					</p>
				</div>
				<div className="column full">
					<select 
						name		=	"Propina" 
						value		=	{Plato.id_categoria || ''}
						onChange    =   {(event) => setPlato({...Plato, id_categoria: event.target.value})}
						className	=	"input input-modals"
						required
					>
						<option value="">Categoría</option>
						{Array.isArray(props.categorias) &&
							props.categorias.map(cat => <option key={cat.id} value={cat.id}>{cat.categoria}</option>)
						}
					</select>
				</div>
			</div>
			<div className="white-space-8"></div>
			<div className="input-form-content row full align-center">
				<div className="column" style={{ width: "16.7%" }}>
					<p>
						<b>Horario:</b>
					</p>
				</div>
				<div className="column" style={{ flex: 1 }}>
					<select
						name="Horario"
						value={Plato.id_horario || ''}
						onChange={(event) => setPlato({ ...Plato, id_horario: +event.target.value })}
						className="input input-modals"
						required
					>
						<option value="">Horario</option>
						{Array.isArray(props.horarios) &&
							props.horarios.map(hor =>
								<option key={hor.id} value={hor.id}>{hor.descripcion}</option>
							)
						}
					</select>
				</div>
				<div className="row label-duo-normal space align-center">
					<div className="column">
						<p>
							<b style={{ whiteSpace: "nowrap" }}>Desde:</b>
						</p>
					</div>
					<div className="column full space-8">
						<TimeField
							value={horarioLimites.hora_inicio}
							style={{ flex: 1 }}
							input={<input className="input input-modals" disabled readOnly />}
						/>
					</div>
					<div className="column space">
						<p>
							<b style={{ whiteSpace: "nowrap" }}>Hasta:</b>
						</p>
					</div>
					<div className="column full space-8">
						<TimeField
							value={horarioLimites.hora_fin}
							style={{ flex: 1 }}
							input={<input className="input input-modals" disabled readOnly />}
						/>
					</div>
				</div>
			</div>
			<div className="white-space-8"></div>
			<div className="input-form-content row full align-center">
				<div className="column label-duo-normal">
					<p>
						<b>Código:</b>
					</p>
				</div>
				<div className="column full">
					<input 
						type		=	"text"
						name		=	"Codigo"
						id          =   "codigo"
						value		=	{Plato.codigo || ''}
						onChange    =   {(event) => setPlato({...Plato, codigo: event.target.value})}
						required
						className	=	"input input-modals"						
					/>
				</div>
				<div className="column label-duo-normal space">
					<p>
						<b>Precio:</b>
					</p>
				</div>
				<div className="column full">
					<input 
						type		=	"number"
						name		=	"Propina" 
						value		=	{Plato.precio || ''}
						onChange    =   {(event) => setPlato({...Plato, precio: event.target.value})}
						className	=	"input input-modals"
					/>
				</div>
			</div>
			<div className="white-space-8"></div>
			<div className="input-form-content row full align-center">
				<div className="column label-duo-normal">
					<p>
						<b>Promoción:</b>
					</p>
				</div>
				<div className="column full">
					<input 
						type		=	"number"
						name		=	"Entrega" 
						value		=	{Plato.promocion || ''}
						onChange    =   {(event) => setPlato({...Plato, promocion: event.target.value})}
						className	=	"input input-modals"
					/>
				</div>
				<div className="column label-duo-normal space">
					<p>
						<b>Activo:</b>
					</p>
				</div>
				<div className="column full">
					<div className="check" >
						<label className="container-check" >
							<input 
								type		=	"checkbox" 
								name		=	"active"
								checked     =   {Plato.activo || false}
								onChange    =   {(event) => setPlato({...Plato, activo: event.target.checked})}
							/>
							<span className="checkmark" ></span>
						</label>
					</div>
				</div>
			</div>
			<div className="white-space-8"></div>
			<div className="row full">
				<div className="input-form-content column full align-start">
					<div className="row ">
						<p>
							<b>Descripción:</b>
						</p>
					</div>
					<div className="row full">
						<textarea 
							type		=	"text"
							name		=	"Propina" 
							value		=	{Plato.descripcion || ''}
							onChange    =   {(event) => setPlato({...Plato, descripcion: event.target.value})}
							className	=	"textarea input input-modals text-big"
							rows        =   "2"
							maxLength   =   "300"
						/>
					</div>
				</div>
				<div className="responsive-img column space align-start">
					<div className="img-restaurant space">
						<div className="container-img justify-center">
							<img src={imagePlato ? imagePlato : emptyImg} className="img-duo-preview" id="previewArea" />
							<div className="middle-overlay">
								<label className="btn-upload-image column align-center justify-center">
									<i className="fas fa-file-upload"></i>
									<input type="file" id="imagenLogo" name="AreaImage" accept="image/*" onChange={changeImg.bind(this)} />
									<div className="white-space-8"></div>
									<p className="color-white">Subir imagen del plato</p>
								</label>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
    )
}

export default Form;
