import Request from '../core/httpClient';
const request = new Request();

export function menuSidebar(data, event) {
    if (Array.isArray(data)) {
        const catalogos_menus = data.filter(item => item.pantalla === -1).map(data => data);
		const administracion_menus = data.filter(item => item.pantalla === -2).map(data => data);
		const operacion_menus = data.filter(item => item.pantalla === -3).map(data => data);
		// console.log(catalogos_menus);
		// console.log(administracion_menus);
		// console.log(operacion_menus);



        window.localStorage.setItem('catalogos_menus', JSON.stringify(catalogos_menus));
		window.localStorage.setItem('administracion_menus', JSON.stringify(administracion_menus));
		window.localStorage.setItem('operacion_menus', JSON.stringify(operacion_menus));
		window.localStorage.setItem('submenu', '');
		//event.setState({menusCatalogos: catalogos_menus, menusAdministacion: administracion_menus});
    } else {
        window.localStorage.setItem('catalogos_menus', '');
		window.localStorage.setItem('administracion_menus', '');
		window.localStorage.setItem('operacion_menus', '');
        window.localStorage.setItem('submenu', '');
	}
	/** Reload */
	//window.location.reload();
};

export async function getMenus(id_fraccionamiento, perfil) {
	// MENU USUARIO PERMISOS
	// console.log("BUSCANDO MENUS DEL NAV");
	const data = { idFraccionamiento: id_fraccionamiento, perfil };
	const response = await request.post("/fraccionamientos/get/menus/parents", data);
	if (response.parents) {
		// console.log(response.parents);
		menuSidebar(response.parents, this);
		return response.parent;
	}
	
}

export async function menuCatalogo() {

}

export async function menuAdministracion() {

}