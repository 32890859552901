import React from 'react';

export default function MetodosPagoSelect(props) {

    const { values, onValueChanged, direction } = props;

    const selectedDirection = direction !== "row" ? "column-direction" : "row-direction";

    const checkSelectionLength = () => {

        if (values && values.length > 0) {

            const valuesChecked = values.filter(item => item.checked === true).length;

            if (valuesChecked === values.length) {
                return "Todos";
            }

            if (valuesChecked === 1) {
                return `${valuesChecked} seleccionado`;
            }

            if (valuesChecked > 0) {
                return `${valuesChecked} seleccionados`
            }

            return "Seleccionar";


        } else {

            return "Sin Opciones"
        }
    }

    const onItemPressed = (id, itemChecked) => {
        onValueChanged({ id, prop: "activo", value: itemChecked ? 1 : 0 });
    }

    const onCambioChanged = (id, cambio) => {
        onValueChanged({id, prop: "cambio", value: cambio});
    }

    return (
        <div className="column checkbox-select full">
            {direction !== "row" &&

                <div style={{ borderBottomStyle: "solid", borderBottomWidth: 1, borderBottomColor: "#999999", padding: 4 }}>
                    <p>
                        <b>{checkSelectionLength()}</b>
                    </p>
                </div>
            }
            <ul className={selectedDirection} style={{ backgroundColor: "#FFFFFF", maxHeight: 100, overflow: 'auto' }}>
                {values && values.length > 0 && values.map((value, index) => {

                    const id = value.id_metodo_pago;
                    const metodo = value.metodo_pago;
                    const checked = value.activo === 1 ? true : false;
                    const cambio = value.cambio;

                    return (
                        <li key={id}
                            className="horarioSemanalItem abierto"
                            onChange={(event) => { if(event.defaultPrevented) return; onItemPressed(id, event.target.checked)} }
                            onClick={(event) => { if(event.defaultPrevented) return; event.target.getElementsByTagName("input")[0].click()}}
                            style={{ display: "flex", flex: 1, flexDirection: "row", cursor: "pointer", width: "100%", height: "100%", padding: 8, paddingInline: 16 }}
                        >
                            <div className='flex checkbox-container' style={{ pointerEvents: "none" }}>
                                <div className="space-16 checkbox-order" style={{ display: "flex", alignItems: "center"}}>
                                    <label className="container-check">
                                        <input
                                            type="checkbox"
                                            name={"checkbox" + index}
                                            disabled={false}
                                            checked={checked}
                                            onClick={(event) => event.stopPropagation()}
                                            readOnly
                                        />
                                        <div className="checkmark"></div>
                                    </label>
                                </div>
                                <div className="text-order " style={{ display: "flex", alignItems: "center"}}>
                                    <p>
                                        <b style={{ whiteSpace: "nowrap"}}>{metodo}</b>
                                    </p>
                                </div>
                                {  
                                    id === 1 &&
                                    <div className="row full justify-center align-center cambio-order">
                                        <div className="column auto space-16">
                                            <p>
                                                <b className="font-mini">Cambio:</b>
                                            </p>
                                        </div>
                                            <div className="column space-8 cashPrefix" 
                                                onChange={(event) => event.preventDefault()}
                                                onClick={(event) =>  event.preventDefault()}
                                                style={{ flex: 1, pointerEvents: 'all', minWidth: 80 }}>
                                                <div className="row full cash">
                                                <input
                                                    type="number"
                                                    className="input input-modals"
                                                    value={cambio}
                                                    min={0}
                                                    max={1000}
                                                    disabled={!checked}
                                                    onChange={(event) => onCambioChanged(id, event.target.value) }
                                                />
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>

                        </li>
                    )
                })}
            </ul>
        </div>
    )
}
