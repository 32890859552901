
export default class ValidityHelper {

    setCustomValidityReset = (modalId) => {

        const form = document.getElementById(modalId);

        const resetCustomValidity = (event) => {

            if (event.target.name) {
                event.target.setCustomValidity("");
            }
        }

        form.addEventListener("input", resetCustomValidity);

        return () => {
            form.removeEventListener("input", resetCustomValidity);
        }
    }

    checkCustomValidity = (target) => {

        const inputs = Array.from(target);

        for (let index = 0; index < inputs.length; index++) {
            const curr = inputs[index];

            if (!curr.name) continue;

            curr.checkValidity();

            if (!curr.validity.valid) {
                curr.setCustomValidity("Favor de llenar el campo: ");
                curr.reportValidity();
                return false;
            }
        }

        return true
    }
} 
