import React, { useEffect, useState } from 'react';
import moment from 'moment-timezone';
import cogoToast from "cogo-toast";

//Hooks
import { useMultiLoading } from '../../../hooks/loading/multiLoading';
import { useDiasCierre } from '../../../hooks/restaurantes/configuracion/restauranteHooks';

//Helpers
import ValidityHelper from '../../../helpers/inputValidityHelper';

const loadingStatesInit = {
    addLoading: false,
    removeLoading: null
}

const minDate = moment().format("YYYY-MM-DD");

const validityHelper = new ValidityHelper();

export default function DiasCierre(props) {

    const { idModal, restaurantes, diasCierre = [], addDiaCierre, removeDiaCierre, loadingDiasCierre, onValueChange } = props;

    const { diasCierreActions, diasCierreStates } = useDiasCierre({
        dia_cierre: "", descripcion: ""
    });

    const { loadingStates, setLoading } = useMultiLoading(loadingStatesInit);

    const [editarIndex, setEditarID] = useState(null);

    useEffect(() => {
        
        const validityDismount = validityHelper.setCustomValidityReset("diasCierreForm" + idModal);

        return validityDismount;

    }, [])

    useEffect(() => {
      
        if(diasCierre) {

            diasCierreActions.setDiasList(diasCierre);
        }

    }, [diasCierre])
    
    useEffect(() => {
      
        onValueChange(diasCierreStates.diaSelected);

    }, [diasCierreStates.diaSelected])

    useEffect(() => {
      
        onValueChange({ id_restaurantes: diasCierreStates.restauranteSelected });
    
    }, [diasCierreStates.restauranteSelected])
        
    const timeout = async (ms) => {

        return new Promise(resolve => setTimeout(resolve, ms))
    }

    const handleAddDiaCierre = async (event) => {

        const form = document.getElementById(idModal).getElementsByTagName("form")[0];

        const validInputs = validityHelper.checkCustomValidity(form);

        if (!validInputs) return;

        if (diasCierreStates.restauranteSelected.length <= 0) {

            const sinRestaurantes = "Agregar al menos un restaurante a la fecha";

            cogoToast.warn(sinRestaurantes, {
                position: 'bottom-right'
            });
            return;
        }

        setLoading('addLoading', true);

        await addDiaCierre();

        setLoading('addLoading', false);
    }

    const handleRemoveDiaCierre = async (index) => {

        setLoading('removeLoading', index);

        await removeDiaCierre(diasCierreStates.diaslist[index].dia_cierre);

        setLoading('removeLoading', null);
    }

    const handleAbrirTablaRestaurantes = (index) => {

        if(index === editarIndex){
            
            setEditarID(null)
        }
        else{

            setEditarID(index);
        }

    }

    const RenderBody = () => {

        if(loadingDiasCierre){

            return <LoadingView/>;
        }

        if(diasCierre.length !== 0){

            return <ContentView/>;
        }
        else{

            return <NoContentView/>;
        }
    }

    const LoadingView = () => {

        return (
            <tr style={{ pointerEvents: "none"}}>
                <td colSpan="4">
                    <div className="white-space-8"></div>
                    <div className="justify-center">
                        <i className="fas fa-spinner fa-spin"></i>
                    </div>
                    <div className="white-space-8"></div>
                </td>
            </tr>
        )
    }

    const ContentView = () => {

        return diasCierreStates.diaslist.map((item, index) => {

            const abrirTabla = editarIndex === index;

            return <React.Fragment key={index}>
                <tr className={abrirTabla === index ? "row-active" : ""}>
                    <td style={{ width: "20%" }}>{moment(item.dia_cierre).format("DD/MM/YYYY")}</td>
                    <td style={{ width: "45%" }}>{item.descripcion}</td>
                    <td className="text-center" style={{ width: "20%" }}>
                        <i className="font-small fas fa-info-circle cursor-pointer"
                            onClick={() => handleAbrirTablaRestaurantes(index)} />
                    </td>
                    <td
                        className="text-center" style={{ width: "15%" }}>
                        {loadingStates.removeLoading === index
                            ? <i className="fas fa-spinner fa-spin color-red"></i>
                            : <i className="fas fa-times-circle color-red cursor-pointer"
                                onClick={() => handleRemoveDiaCierre(index)}>
                            </i>}
                    </td>
                </tr>
                {
                    abrirTabla &&

                    <tr className="acordeon-row">
                        <td className='acordeon' colSpan={4}>
                            <div className='column full'>
                                <h4>Restaurantes</h4>
                                <table>
                                    <thead>
                                        <tr>
                                            <th>{"Restaurante"}</th>
                                            <th>{"Activo"}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            diasCierreStates.diaslist[index].id_restaurantes.map(idRes => {

                                                const restaurante = restaurantes.find(res => res.id_restaurante === idRes);

                                                return (
                                                    <tr>
                                                        <td>{restaurante.nombre}</td>
                                                        <td className='text-center'>{restaurante.activo === 1 ? "Activo" : "Inactivo"}</td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </td>
                    </tr>
                }
            </React.Fragment>
        })
    }

    const NoContentView = () => {

        return (
            <tr style={{ pointerEvents: "none" }}>
                <td colSpan="4">
                    <div className="justify-center">
                        <h4>No se encontraron resultados.</h4>
                    </div>
                </td>
            </tr>
        ) 
    }

    return (
        <div className="card-table card-owners column" id={"diasCierreForm" + idModal} style={{ height: "100%" }}>
            <div className="row full">
                <div className="column width-45">
                    <h4>Agregar Dia de Cierre</h4>
                    <div className="white-space-8"></div>
                    <div className="column">
                        <p>Fecha:</p>
                        <input
                            className="input"
                            type="date"
                            name="fecha"
                            required
                            min={minDate}
                            value={diasCierreStates.diaSelected.dia_cierre}
                            onChange={(event) => diasCierreActions.setDiaSelected("dia_cierre", event.target.value)}
                        />
                    </div>
                    <div className="white-space-8"></div>
                    <div className="column">
                        <p>Descripción: </p>
                        <input
                            type="text"
                            className="input"
                            name="descripcion"
                            required
                            placeholder='Agrega Descripcion...'
                            value={diasCierreStates.diaSelected.descripcion}
                            onChange={(event) => diasCierreActions.setDiaSelected("descripcion", event.target.value)}
                        />
                    </div>
                    <div className="white-space-8"></div>
                    <div className="full column" >
                        <table className='table-hover'>
                            <thead className="sticky-th">
                                <tr>
                                    <th className="text-left" colSpan={2} style={{ padding: 0, paddingBottom: 8 }}><p>Restaurantes a agregar:</p></th>
                                </tr>
                            </thead>
                            <tbody style={{ height: "30vh" }}>
                                {
                                    restaurantes &&

                                    restaurantes.map((item, index) =>

                                        <tr key={index}>
                                            <td style={{ whiteSpace: "nowrap" }}>{item.nombre}</td>
                                            <td className='text-center justify-end' style={{ paddingRight: 12, paddingBlock: 16 }}>
                                                <label className="container-check">
                                                    <input
                                                        type="checkbox"
                                                        name="Activo"
                                                        disabled={false}
                                                        checked={diasCierreStates.restauranteSelected.includes(item.id_restaurante)}
                                                        onClick={(event) => diasCierreActions.modifyRestaurantesSelected(item.id_restaurante, event.target.checked)}
                                                        readOnly
                                                    />
                                                    <span className="checkmark" ></span>
                                                </label>
                                            </td>
                                        </tr>
                                    )
                                }
                            </tbody>
                        </table>
                    </div>
                    <div className="white-space-8"></div>
                    <div>
                        <button className="btn btn-primary color-white full" type="button" onClick={handleAddDiaCierre}>
                            {loadingStates.addLoading ? <i className="fas fa-spinner fa-spin"></i> : 'Agregar'}
                        </button>
                    </div>
                </div>
                <div className="full column" style={{ borderLeft: "1px solid #EEE", marginLeft: 8, paddingInline: 8 }}>
                    <h4>Dias de Cierre</h4>
                    <div className="white-space-8"></div>
                    <table className='table-hover' style={{ height: loadingDiasCierre || diasCierre.length === 0 ? "100%" : "auto" }}>
                        <thead className="sticky-th">
                            <tr>
                                <th className="text-left" style={{ width: "20%" }}>Fecha</th>
                                <th className="text-left" style={{ width: "45%" }}>Descripción</th>
                                <th className="text-center" style={{ width: "20%" }}>Restaurantes</th>
                                <th className="text-center" style={{ width: "15%" }}>Quitar</th>
                            </tr>
                        </thead>
                        <tbody style={{ height: "50vh" }}>
                            <RenderBody/>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}
