import React, { Component } from "react";
import DinamicModal from '../../modals/dinamicModal/dinamicModal';
import PlatosForm from './form';
import ModalBorrar from '../../modals/modalDelete';
import Request from '../../../core/httpClient';
import PlatosTable from './platosTable';
import cogoToast from 'cogo-toast';
import sortableData from '../../../helpers/sortableDataTable';

const request = new Request();

let values = {};

class RestaurantesTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            restaurante:        {},
            isModalOpen:        false,
            data:               {},
            platos:             [],
            id_restaurante:     this.props.id_restaurante,
            id_categoria:       this.props.id_categoria,
            categorias:         [],
            horarios:           [],
            plato:              { id_horario: 0 },
            direction: {
                nombre: 'desc',
                categoria: 'desc',
                precio: 'desc'
            },
            filterColumn: {
                col: '',
                filt: ''
            },
            fraccionamiento: localStorage.getItem('frac') || 0
        }
    }

    componentDidMount() {
        const id_restaurante = this.props.id_restaurante;
        this.setPlatos(id_restaurante);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.id_restaurante !== this.props.id_restaurante) {
            this.setPlatos(this.props.id_restaurante);
        }
        if (prevProps.id_categoria !== this.props.id_categoria) {
            this.setState({id_categoria: this.props.id_categoria});
        }
    }

    setPlatos(id_restaurante) {
        if (id_restaurante) {
            let categorias = [], horarios = [];
            const { restaurantes } = this.props;
            const res = Array.isArray(restaurantes)? restaurantes.find(res => res.id_restaurante == id_restaurante) : [];
            if (res) {
                if (res.categorias) {
                    categorias = JSON.parse(res.categorias).filter(cat => cat.id_status > 0);
                }
                if (res.horarios) {
                    horarios = JSON.parse(res.horarios).filter(hor => hor.id_status > 0);
                }
            }
            this.setState({id_restaurante, categorias, horarios});
            this.getPlatos(id_restaurante);
        } else {
            this.getPlatos(0);
        }
    }

    async getPlatos(id_restaurante) {
        this.setState({loadingPlatos: true, message: null});
        const res = await request.post('/restaurantes/platos/get', { id_restaurante });
        if (res.platos) {
            this.setState({platos: res.platos});
        } else {
            this.setState({platos: []/*, message: res.message || 'No se obtuvo la lista de platos'*/});
        }
        this.setState({loadingPlatos: false});
    }

    selectPlato(plato) {
        this.setState({plato, isModalOpen: true});
    }

    selectDeletePlato(id_plato) {
        this.setState({id_plato});
    }

    sortableTable(key) {
        let sd = new sortableData();
        const { items, direction } = sd.sortBy(key, this.state.platos, this.state.direction[key]);

        this.setState({
            platos: items,
            direction: {
                [key]: direction
            },
            filterColumn: {
                col: key,
                filt: direction
            }
        });
    }

    render() {
        return (
            <>
                <PlatosTable
                    platos              = {this.state.platos || []}
                    escritura           = {this.props.escritura}
                    loading             = {this.state.loadingPlatos}
                    categorias          = {this.state.categorias}
                    horarios            = {this.state.horarios}
                    id_restaurante      = {this.state.id_restaurante}
                    id_categoria        = {this.state.id_categoria}
                    selectDeletePlato   = {this.selectDeletePlato.bind(this)}
                    page                = {this.props.page}
                    reload              = {this.props.reload.bind(this)}
                    sortBy              = {this.sortableTable.bind(this)}
                    filterColumn        = {this.state.filterColumn}
                />

                <ModalBorrar
					id          =   {this.state.id_plato}
					delete      =   {this.handleDelete.bind(this)}
                />

                <DinamicModal
					idModal				=	{'newPlato'}
					sizeModalContainer	=	{'big'}
					title				=	{'Añadir plato'}
					success				=	{this.handleNew.bind(this)}
					showBtnSuccess		=	{this.props.escritura}
					btnTextSuccess		=	{"GUARDAR"}
					showBtnCancel		=	{true}
					btnTextCancel		=	{"CANCELAR"}
					loadingSave			=	{this.state.loadingSave}
                    message				=	{this.state.messageNew}
                    closeModal          =   {() => this.setState({isModalOpen: false})}
				>
					<PlatosForm 
                        isModalOpen         = {this.state.isModalOpen}
                        getValues           = {this.getValues.bind(this)}
                        nuevo               = {true}
                        categorias          = {this.state.categorias}
                        horarios            = {this.state.horarios}
                        id_restaurante      = {this.state.id_restaurante}/>
				</DinamicModal>
            </>
        )
    }

    getValues(data) {
        values = data;
    }

    async handleNew(event) {
        event.preventDefault();
        this.setState({loadingSave: true, messageNew: null});
        const res = await request.post('/restaurantes/platos/create', { ...values, id_restaurante: this.state.id_restaurante, idFraccionamiento: this.state.fraccionamiento });
        if (res.created) {
            this.props.reload();
            cogoToast.success('Plato agregado.', {
                position: 'bottom-right',
            });
        } else {
            //this.setState({messageNew: res.message || 'No se pudo agregar el plato.'});
            cogoToast.error('No se pudo agregar plato.', {
                position: 'bottom-right',
            });
        }
        this.setState({loadingSave: false});
    }

    async handleDelete(id) {
        let data = {
            id_plato: id,
        }
        const response = await request.post("/restaurantes/platos/delete", data);
        if (response && !response.error) {
            if (response.deleted && !response.empty) {
                //this.setState({ message: response.message });
                this.props.reload();
                cogoToast.success('Plato eliminado.', {
                    position: 'bottom-right',
                });
            } else {
                this.setState({
                    empty: true,
                    //message: response.message,
                    loading: false
                });
                cogoToast.error('No se pudo eliminar plato.', {
                    position: 'bottom-right',
                });
            }
        } else {
            this.setState({
                empty: true,
                //message: response.message,
                loading: false
            });
            cogoToast.error('No se pudo eliminar plato.', {
                position: 'bottom-right',
            });
        }
    }

    /*openRow(res) {
        console.log(res);
		window.localStorage.setItem('openRowRest', res.id_restaurante);
		if(this.state.id_restaurante == res.id_restaurante) {
			window.localStorage.removeItem('openRowRest');
			this.setState({id_restaurante: 0});
			return;
        }
        let categorias = [], horarios = [];
        if (res.categorias) {
            categorias = JSON.parse(res.categorias).filter(cat => cat.id_status > 0);
        }
        if (res.horarios) {
            horarios = JSON.parse(res.horarios).filter(hor => hor.id_status > 0);
        }
        console.log(res);
        this.setState({id_restaurante: res.id_restaurante, categorias, horarios});
        this.getPlatos(res.id_restaurante);
	}*/
}

export default RestaurantesTable;
