import React, { useState, useEffect, useCallback, forwardRef, useRef } from 'react';
import Draggable from 'react-draggable';
import ModalCore from '../core/ModalCore';

const modal = new ModalCore();

export const SubmitType = {

    NONE: 'none',
    LAST: 'last',
    ALL: 'all'
}

const defaultFormProps = {

    type: SubmitType.LAST,
    noValidate: true
}

const defaultFooterButtonProps = {

    submit: { show: true, label: "Guardar", actionCheck: null },
    cancel: { show: true, label: "Cancelar", actionCheck: null },
    next: { show: false, label: "Siguiente", actionCheck: null },
    previous: { show: false, label: "Regresar", actionCheck: null },
}

export default function SuperModal(props) {

    const {
        openModal,
        id, 
        title,
        menuScreens,
        size,        
        onCloseModal,
        onMenuChange,
        loading,

        formProps = SuperModal.SetFormProps(defaultFormProps),
        submitProps = SuperModal.SetFooterSubmitProps(defaultFooterButtonProps.submit),
        cancelProps = SuperModal.SetFooterCancelProps(defaultFooterButtonProps.cancel),
        nextProps = SuperModal.SetFooterNextProps(defaultFooterButtonProps.next),
        previousProps = SuperModal.SetFooterPreviousProps(defaultFooterButtonProps.previous),

    } = props;

    const screensCount = menuScreens.length;

    const screenNames = menuScreens.map(menu => menu.title);

    const screenComponents = menuScreens.map(menu => menu.component);

    const [menuActivoIndex, setMenuActivoIndex] = useState(0);

    const [internalLoading, setInternalLoading] = useState(false);

    const bodyRef = useRef();

    const closeModal = useCallback(() => {

        onCloseModal();
        modal.superCloseModal(id);

    }, [onCloseModal, id]);

    useEffect(() => {

        if(openModal){
            bodyRef.current.scrollTo(0,0);
            setMenuActivoIndex(0);
            modal.superOpenModal(id);
        }

    }, [id, openModal]);

    useEffect(() => {
        const handleKeyDown = (e) => {
            if(e.key === "Escape"){
                closeModal();
            }
        }
        window.addEventListener('keydown', handleKeyDown);
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        }
    }, [closeModal]);

    const footerInteraction = (interaction, event) => {

        switch (interaction) {

            case "submit":
                closeModal();
                break;
            case "next":
                changeToNextMenu(event);
                break;
            case "previous":
                changeToPreviousMenu(event);
                break;
            case "cancel":
                closeModal();
                break;
            default:
                break;
        }
    }

    const changeMenu = (menuIndex) => {

        setMenuActivoIndex(menuIndex);

        onMenuChange?.(menuIndex);
    }

    const changeToNextMenu = (event) => {

        const newMenuIndex = Math.min(menuActivoIndex + 1, screensCount);

        changeMenu(newMenuIndex);
    }

    const changeToPreviousMenu = (event) => {

        const newMenuIndex = Math.max(menuActivoIndex - 1, 0);

        changeMenu(newMenuIndex);
    }

    const handleSubmit = async (event) => {

        event.preventDefault();

        setInternalLoading(true);

        const lastScreen = screensCount === 0 || screensCount === menuActivoIndex + 1;

        if(lastScreen){

            const check = await submitProps.actionCheck?.(event);

            if(check) footerInteraction("submit", event);
        }
        else
        {
            const check = await nextProps.actionCheck?.(event);

            if(check) footerInteraction("next", event);
        }

        setInternalLoading(false);
    }

    return (
        <div className="super-modal-container super-hide" id={id}>
            <Draggable handle=".super-header" cancel='.super-header-exit'>
                <div className={`super-modal ${size}`}>
                    <SuperModal.Header
                        id={id}
                        menuActivoIndex={menuActivoIndex}
                        onCloseModal={closeModal}
                    >
                        {title}
                    </SuperModal.Header>
                    <SuperModal.SubHeader
                        id={id}
                        menuActivoIndex={menuActivoIndex}
                        onChangeMenu={changeMenu}
                        submitType={formProps.type}
                    >
                        {screenNames}
                    </SuperModal.SubHeader>
                    <FormView 
                        formProps={formProps}
                        onSubmit={handleSubmit}
                    >
                        <SuperModal.Body
                            ref={bodyRef}
                            menuActivoIndex={menuActivoIndex}
                            screenComponents={screenComponents}
                        />
                        <SuperModal.Footer
                            id={id}
                            menuActivoIndex={menuActivoIndex}
                            screensCount={screensCount}
                            onFooterInteraction={footerInteraction}
                            internalLoading={internalLoading}
                            loading={loading}
                            submitType={formProps.type}
                            submitProps={submitProps}
                            cancelProps={cancelProps}
                            nextProps={nextProps}
                            previousProps={previousProps}
                        />
                    </FormView>
                </div>
            </Draggable>
        </div>
    )
}

const FormView = (props) => {

    const { children, formProps, onSubmit } = props;

    const formNeeded = formProps.type === SubmitType.ALL || formProps.type === SubmitType.LAST;

    return  formNeeded ?
        <form className="column full" noValidate={formProps.noValidate} onSubmit={onSubmit}>
            {children}
        </form>
        :
        <div className="column full">
            {children}
        </div>
}

SuperModal.SetFormProps = ({ type, noValidate }) => {

    const props = {
        type: type || defaultFormProps.type,
        noValidate: noValidate || defaultFormProps.noValidate
    };

    return props;
}

SuperModal.SetFooterSubmitProps = ({ show, label, actionCheck }) => {

    const props = { 
        show: show || defaultFooterButtonProps.submit.show, 
        label: label || defaultFooterButtonProps.submit.label, 
        actionCheck: actionCheck || defaultFooterButtonProps.submit.actionCheck 
    };

    return props;
}

SuperModal.SetFooterCancelProps = ({ show, label, actionCheck  }) => {

    const props = { 
        show: show || defaultFooterButtonProps.cancel.show, 
        label: label || defaultFooterButtonProps.cancel.label, 
        actionCheck: actionCheck || defaultFooterButtonProps.cancel.actionCheck 
    };

    return props;
}

SuperModal.SetFooterNextProps = ({ show, label, actionCheck }) => {

    const props = { 
        show: show || defaultFooterButtonProps.next.show, 
        label: label || defaultFooterButtonProps.next.label, 
        actionCheck: actionCheck || defaultFooterButtonProps.next.actionCheck 
    };

    return props;
}

SuperModal.SetFooterPreviousProps = ({ show, label, actionCheck }) => {
    
    const props = { 
        show: show || defaultFooterButtonProps.previous.show, 
        label: label || defaultFooterButtonProps.previous.label, 
        actionCheck: actionCheck || defaultFooterButtonProps.previous.actionCheck 
    };

    return props;
}

SuperModal.Header = function SuperModalHeader(props) {

    const { id, children, onCloseModal } = props;

    return(
        <div className="super-header row align-center">
            <div className="column modal-container justify-center">
                <h3 className="title">{children}</h3>
            </div>
            <button className="super-header-exit btn-modal-close justify-center align-center"
                onClick={() => onCloseModal()}
            >
                <i className="fas fa-times" ></i>
            </button>
        </div>
    )
}

SuperModal.SubHeader = function SuperModalSubHeader(props) {

    const { id, menuActivoIndex, children, onChangeMenu, submitType } = props;

    // const startDisablingIndex = submitType === SubmitType.ALL ? menuActivoIndex + 1 : -1;

    return(

        children.length > 1 &&

        <div className="super-sub-header" id={`sub-header-${id}`}>
            {   
                children.map((screenName, index) => 
                    <button 
                        key={index}
                        className={menuActivoIndex === index ? "super-item super-item-active" : "super-item" }
                        style={{ flex: 1 }}
                        type="button"
                        // disabled={startDisablingIndex === index}
                        onClick={() => onChangeMenu(index)}
                    >
                        <p>{screenName}</p>
                    </button>
                )
            }
        </div>
    )
}

SuperModal.Body = forwardRef(function SuperModalBody(props, ref) {

    const { menuActivoIndex, screenComponents } = props;

    return (
        <div ref={ref} className="super-body align-center" style={{ overflowY: "auto", position: 'relative' }}>
            {
                screenComponents &&

                screenComponents.map((screen, index) => {

                    return <div
                        key={index}
                        className='modal-container full'
                        style={{ position: "relative", display: menuActivoIndex === index ? "flex" : "none", justifyContent: "center" }}
                    >
                        {screen}
                    </div>;
                })
            }
        </div>
    )
});

SuperModal.Footer = function SuperModalFooter(props) {

    const { 
        id, menuActivoIndex, screensCount, onFooterInteraction, submitType, loading, internalLoading,
        submitProps, nextProps, previousProps, cancelProps, 
    } = props;

    const SelectView = () => {

        if(screensCount === 0 || screensCount === menuActivoIndex + 1){

            return LastScreenView();
        }

        if(menuActivoIndex === 0){

            return FirstScreenView();
        }

        if(menuActivoIndex > 0){

            return MiddleScreenView();
        }
    }

    const FirstScreenView = () => {

        return (
            <div className="row justify-end">
                <SuperModal.FooterNextButton 
                    {...nextProps}
                    submitType={submitType} 
                    onInteraction={onFooterInteraction}
                    loading={internalLoading || loading}
                />
            </div>
        )
    }

    const MiddleScreenView = () => {

        return (
            <div style={{ width: "100%", height: "100%", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                <SuperModal.FooterPreviousButton
                    {...previousProps}
                    onInteraction={onFooterInteraction}
                />
                <SuperModal.FooterNextButton
                    {...nextProps}
                    submitType={submitType}
                    onInteraction={onFooterInteraction}
                    loading={internalLoading || loading}
                />
            </div>
        )
    }

    const LastScreenView = () => {

        return (
            <div style={{ width: "100%", height: "100%", display: "flex", flexDirection: "row-reverse", justifyContent: "space-between", alignItems: "center" }}>
                <div>
                    <SuperModal.FooterCancelButton 
                        {...cancelProps}
                        onInteraction={onFooterInteraction} 
                    />
                    <SuperModal.FooterSubmitButton 
                        {...submitProps}
                        submitType={submitType} 
                        onInteraction={onFooterInteraction}
                        loading={internalLoading || loading}
                    />
                </div>
                <SuperModal.FooterPreviousButton
                    {...previousProps}
                    onInteraction={onFooterInteraction} 
                />
            </div>
        )
    }

    return (
        <div className="super-footer align-center">
            <div className="row full justify-center">
                <div className="container">
                    <SelectView/>
                </div>
            </div>
        </div>
    )
}

SuperModal.FooterSubmitButton = function SuperModalFooterSubmitButton(props){

    const { show, label, actionCheck, onInteraction, submitType, loading } = props;

    const buttonType = submitType === SubmitType.LAST || submitType === SubmitType.ALL ? "submit" : "button";

    const onCallback = (event) => {

        if(actionCheck?.(event) === false) return;

        onInteraction("submit");
    }

    return ( show && 

        <button className="btn-action-success" type={buttonType} onClick={buttonType === "button" ? onCallback : undefined}
            style={{ position: "relative", color: loading ? "transparent" : "auto" }}>
            {label}
            {loading &&
                <div className="row justify-center align-center" style={{ position: "absolute", top: 0, left: 0, right: 0, bottom: 0 }}>
                    <i className="fas fa-spinner fa-spin" style={{ color: "white" }}></i>
                </div>
            }
        </button>
    )
}

SuperModal.FooterCancelButton = function SuperModalFooterCancelButton(props){

    const { show, label, actionCheck, onInteraction } = props;

    const onCallback = (event) => {

        if(actionCheck?.(event) === false) return;

        onInteraction("cancel");
    }

    return ( show &&

        <button className="btn-action-cancel" type="button" onClick={onCallback}>
            {label}
        </button>
    )
}

SuperModal.FooterNextButton = function SuperModalFooterNextButton(props){

    const { show, label, actionCheck, onInteraction, submitType, loading } = props;

    const buttonType = submitType === SubmitType.ALL ? "submit" : "button";

    const onCallback = (event) => {

        if(actionCheck?.(event) === false) return;
        
        onInteraction("next");
    }

    return ( show &&

        <button className='btn-action-process' type={buttonType} onClick={buttonType === "button" ? onCallback : undefined}
            style={{ position: "relative", color: loading ? "transparent" : "auto" }}>
            {label}
            {loading &&
                <div className="row justify-center align-center" style={{ position: "absolute", top: 0, left: 0, right: 0, bottom: 0 }}>
                    <i className="fas fa-spinner fa-spin" style={{ color: "white" }}></i>
                </div>
            }
        </button>
    )
}

SuperModal.FooterPreviousButton = function SuperModalFooterPreviousButton(props){

    const { show, label, actionCheck, onInteraction } = props;

    const onCallback = (event) => { 

        if(actionCheck?.(event) === false) return;
        
        onInteraction("previous");
    }

    return ( show &&

        <button className='btn-action-process' type="button" onClick={onCallback}>
            {label}
        </button>
    )
}
