import React,{ useState} from 'react';

const Clipboard = (props) => {
    const {url} = props;

    const [copySuccess, setCopySuccess] = useState(false);

    const handleCopyURL = (copy) => {
        navigator.clipboard.writeText(copy)

        .then(() => {
            setCopySuccess(true);
            setTimeout(() => setCopySuccess(false), 2000);
        })
        .catch((err) => console.error('Error al copiar al portapapeles', err));
    }

    return (
        <>
        <div className="clipboard">
            <a href={url} target="_blank" rel="noopener noreferrer">
                {url}
            </a>
            <button 
                type="button" 
                onClick={() => handleCopyURL(url)}
            >
                <i className="fas fa-copy"/>
            </button>
       </div>
            {copySuccess && <div className="copy-success">¡URL copiada!</div>}
        </>

    );
}

export default Clipboard;