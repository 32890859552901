import React, { Component } from "react";
import cogoToast from 'cogo-toast';

//Components
import ModalBorrar from '../../modals/modalDelete';

//Helper
import ValidityHelper from "../../../helpers/inputValidityHelper";

// HTTP Client
import Request from '../../../core/httpClient';
const request = new Request();

const validityHelper = new ValidityHelper();

let values = {};

class ConfiguracionTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            restaurante:        {},
            selectedRestaurantID: null,
            isModalOpen:        false,
            data:               {},
            fraccionamiento: localStorage.getItem('frac') || 0,
        }
    }

    changeRestaurant(property, value){
        this.setState({ restaurante: {...this.state.restaurante, [property]: value} });
    }

    async openModal(idModal, restaurante) {

		this.setState({ restaurante, message: null, id_restaurante_row: restaurante.id_restaurante });

        this.setState({ isModalOpen: true });
    }

    openModalDelete(restaurant) {
		this.setState({selectedRestaurantID: restaurant.id_restaurante, id_restaurante_row: restaurant.id_restaurante});
		let modal = document.getElementById("deleteModal");
		if(modal) {
			modal.classList.remove('hide-modal');
			modal.classList.add('show-modal');
		}
	}

    render() {
        let restaurantes = [];
        if(Array.isArray(this.props.restaurantes))
            restaurantes = this.props.restaurantes;
        return (
            <div className="card-owners column" id="card-owners">
                <div className="row card-table">
                    <table className="full">
                        <thead className="sticky-th">
                            <tr className="text-left">
                                <th className="text-left">
                                    <div className="row">
                                        Nombre &nbsp;
										<div className="align-center">
                                            {this.props.filterColumn.col === 'nombre' ? this.props.filterColumn.filt === 'asc' ?
                                                <i className="fas fa-sort-down" onClick={this.props.sortBy.bind(this, 'nombre')}></i>
                                                :
                                                <i className="fas fa-sort-up" onClick={this.props.sortBy.bind(this, 'nombre')}></i>
                                                :
                                                <i className="fas fa-sort" onClick={this.props.sortBy.bind(this, 'nombre')}></i>
                                            }
                                        </div>
                                    </div>
                                </th>
                                <th className="text-start">Activo</th>
                                <th className="text-center th-options-small">Editar</th>
                                <th className="text-center th-options-small">Eliminar</th>
                            </tr>
                        </thead>
                        <tbody>
                            {restaurantes.slice((this.props.page - 1) * 7, this.props.page * 7).map((restaurant, key) => (
								<React.Fragment key={key}>
                                    <tr className={this.state.id_restaurante_row === restaurant.id_restaurante ? 'row-active' : undefined}>
										<td className="text-left">{restaurant.nombre}</td>
                                        <td>
                                            <label className="container-check" >
                                                <input
                                                    type="checkbox"
                                                    checked={restaurant.activo? true : false}
                                                    readOnly
                                                    disabled/>
                                                <span className="checkmark" ></span>
                                            </label>
                                        </td>
										<td className="text-left">
                                            <button 
                                                className="btn-full justify-center align-center btn-mini btn-secondary color-white"
                                                type="button"
                                                onClick={this.openModal.bind(this,"editRestaurante", restaurant)}
                                            >
                                                    <i className="font-small fas fa-pen" />
                                            </button>
										</td>
										<td className="text-center">
                                            <button className="btn-full justify-center align-center btn-mini btn-secondary color-white" 
                                                    type		=	'button' 
                                                    onClick		=	{this.openModalDelete.bind(this, restaurant)} 
                                                    style		=	{{ padding: '5px' }} 
                                                    disabled	=	{!this.props.escritura}
                                            >
                                                <i className="font-text fas fa-trash-alt " />
                                            </button>
										</td>
									</tr>
								</React.Fragment>
                            ))}

                        </tbody>
                    </table>
                </div>
                { this.props.formModal &&

                    this.renderFormModal()
                }

                <ModalBorrar
					id          =   {this.state.selectedRestaurantID}
					delete      =   {this.handleDelete.bind(this)}
                />
            </div>
        )
    }

    renderFormModal(){

        const modalProps = {
            loading: this.state.loadingSave,
            openModal: this.state.isModalOpen,
            onCloseModal: () => this.setState({ isModalOpen: false }),
            onSubmit: this.handleSubmit.bind(this),
            preventNext: this.handleFormValidity.bind(this)
        }

        const formProps = {
            isModalOpen: this.state.isModalOpen,
            restaurante: this.state.restaurante,
            getValues: this.getValues.bind(this),
            onChangeRestaurant: this.changeRestaurant.bind(this),
        }

        const disponibilidadProps = {
            id_restaurante: this.state.restaurante.id_restaurante,
            restauranteDisponibilidad: this.state.restaurante.fraccionamientos,
            getValues: this.getValues.bind(this)
        }

        return this.props.formModal(modalProps, formProps, disponibilidadProps);
    }

    getValues(data) {
        values = {...values, ...data};
    }

    handleFormValidity(event){

        event.preventDefault();

        const validInputs = validityHelper.checkCustomValidity(event.target);

        return validInputs;
    }

    isRestaurantDataValid(restaurant) {
        if (!restaurant.nombre) {
            cogoToast.error('Por favor, ingrese un nombre del restaurante', {
                position: 'bottom-right',
            })  
            return false    
        }

        if (!restaurant.telefono){
            cogoToast.error('Por favor, ingrese un teléfono válido', {
                position: 'bottom-right',
            })
            return false
        }

        if (!restaurant.tipo_comida) {
            cogoToast.error('Por favor, ingrese el tipo de comida del restaurante', {
                position: 'bottom-right',
            })
            return false
        }

        if(!restaurant.metodos_pago) {
            cogoToast.error('Por favor, seleccione al menos un método de pago', {
                position: 'bottom-right',
            })
            return false
        }
        
        return true;
    }

    async handleSubmit(event) {

        event.preventDefault();

        const validInputs = validityHelper.checkCustomValidity(event.target);
        if (!validInputs) return false;

        const data = { ...this.state.restaurante, ...values };

        if (!this.isRestaurantDataValid(data)) return false;

        this.setState({ loadingSave: true, message: null});

        const res = await request.post('/restaurantes/update', { ...data });
        
        if (res.updated) {
            this.props.reload();
            cogoToast.success('Datos de restaurante actualizados.', {
                position: 'bottom-right',
            });
        } else {
            cogoToast.error('No se pudieron actualizar datos de restaurante.', {
                position: 'bottom-right',
            });
        }

        this.setState({loadingSave: false});

        return res.updated === true;
    }

    async handleDelete(id) {

        let data = {
            id_restaurante: id,
        }
        const response = await request.post("/restaurantes/delete", data);
        
        if (response && !response.error) {
            if (response.deleted && !response.empty) {
                //this.setState({ message: response.message, });
                this.props.reload();
                cogoToast.success('Restaurante eliminado.', {
                    position: 'bottom-right',
                });

            } else {
                this.setState({
                    empty: true,
                    //message: response.message,
                    loading: false
                });
                cogoToast.error('No se pudo eliminar restaurante.', {
                    position: 'bottom-right',
                });
            }
        } else {
            this.setState({
                empty: true,
                //message: response.message,
                loading: false
            });
            cogoToast.error('No se pudo eliminar restaurante.', {
                position: 'bottom-right',
            });
        }
    }
}

export default ConfiguracionTable;
