
export default class ModalCore {

    /**
    *
    * @param {id Modal} id 
    */
    superOpenModal(id) {

        let modal = document.getElementById(id);
        if (modal) {
            modal.classList.remove('hide-super-modal');
            modal.classList.add('show-super-modal');
        }

    }

    /**
     * 
     * @param {id Modal} id 
     */
    superCloseModal(id) {
        let modal = document.getElementById(id);
        if (modal) {
            modal.classList.remove('show-super-modal');
            modal.classList.add('hide-super-modal');
        };
    };
   
       //##### MODALES VIEJOS #####
   
    /**
     * 
     * @param {id modal} idModal
     * Abre los modales antiguos DinamicModal
     */
    openModal(idModal) {

        const modal = document.getElementById(idModal);
        if (modal) {
            modal.getElementsByClassName('dinamic-body')[0].scrollTo(0,0);
            modal.classList.remove('hide-dinamic-modal');
            modal.classList.add('show-dinamic-modal');
        };
    };

    /**
     * 
     * @param {id modal} idModal
     * Cierra los modales antiguos DinamicModal 
     */
    closeModal(idModal) {
        const modal = document.getElementById(idModal);
        if (modal) {
            modal.classList.add('hide-dinamic-modal');
            modal.classList.remove('show-dinamic-modal');
        };
    };
   
    /**
     * 
     * @param {id Modal detele} idModal 
     * Abre modal de eliminar antiguo
     */
    openModalDelete(idModal = 'deleteModal') {
        let modal = document.getElementById(idModal);
        if (modal) {
            modal.classList.remove('hide-modal');
            modal.classList.add('show-modal');
        };
    };

    /**
     * 
     * @param {id Modal detele} idModal 
     * Cierra modal de eliminar antiguo
     */
    closeModalDelete(idModal = 'deleteModal') {
        let modal = document.getElementById(idModal)

        if (modal) {
            modal.classList.remove('show-modal');
            modal.classList.add('hide-modal');
        };
    };
}

export const ModalCoreModules = {

    Header: "header",
    SubHeader: "subheader",
    Body: "body",
    Footer: "footer",
    SubmitButton: "submitbutton",
    NextButton: "nextbutton",
    PreviousButton: "previousbutton",
    CancelButton: "cancelbutton"
}