import React from "react";

export default function TableHeadSort(props) {

    const { title, orderName, orderKey, onOrderKeyChange, className } = props;

    return (
        <div className={"row " + className}>
            {title} &nbsp;
            <div>
                {orderKey.name === orderName ? (
                    orderKey.direction === "asc" ? (
                        <i className="fas fa-sort-up" onClick={() => onOrderKeyChange({ name: orderName, direction: "desc" })}></i>
                    ) : (
                        <i className="fas fa-sort-down" onClick={() => onOrderKeyChange({ name: orderName, direction: "asc" })}></i>
                    )
                ) : (
                    <i className="fas fa-sort" onClick={() => onOrderKeyChange({ name: orderName, direction: "asc" })}></i>
                )}
            </div>
        </div>
    )
}