import React, { useEffect } from 'react';

import { useDisponibilidad } from '../../../../hooks/restaurantes/configuracion/restauranteHooks';

//Helper
import StringHelper from "../../../../helpers/StringHelper";

export default function DisponibilidadCondominiosTab(props) {

    const { restauranteDisponibilidad = [], idModal, id_restaurante, getValues } = props;

    const { disponibilidadStates, disponibilidadActions } = useDisponibilidad(filtrarDisponibilidad);
    
    useEffect(() => {

        if (!restauranteDisponibilidad || restauranteDisponibilidad.length <= 0) return;

        const dispParsed = JSON.parse(restauranteDisponibilidad);

        disponibilidadActions.setList(dispParsed);

    }, [restauranteDisponibilidad])

    useEffect(() => {

        if (id_restaurante) {
            disponibilidadActions.setFilter("");
        }

    }, [id_restaurante])

    useEffect(() => {

        const disponibilidad = JSON.stringify(disponibilidadStates.list);
        getValues({ disponibilidad })

    }, [disponibilidadStates.list])

    return (
        <div className="restaurante-disponibilidad column">
            <div className='row full'>
                <div className='flex align-center'>
                    <h4>Disponibilidad por Condominio</h4>
                </div>
                <div className="input-clean space-8">
                    <input
                        className="input input-filter"
                        type="text"
                        id="filterData"
                        placeholder="Buscar"
                        value={disponibilidadStates.filter}
                        onChange={(event) => disponibilidadActions.setFilter(event.target.value)}>
                    </input>
                    <i className="fas fa-search font-small" ></i>
                </div>
            </div>
            <div className="white-space-16"></div>
            <div className='column align-center overflow-auto hideScrollbar' style={{ flex: 1 }}>
                {disponibilidadStates.filteredList.length <= 0 ?

                    <div className='flex align-center justify-center' style={{ flex: 1, opacity: 0.6 }}>
                        <div className="column align-center">
                            <i className="fas fa-city color-dark" style={{ fontSize: 48 }}></i>
                            <div className='white-space-8'></div>
                            <h5>Sin resultados</h5>
                        </div>
                    </div>

                    :

                    disponibilidadStates.filteredList.map((item, index) => {

                        const key = item.id;
                        const nombre = item.fraccionamiento;
                        const disponible = item.disponible;
                        const tiempoEnvio = item.tiempo_envio_min;
                        const costoEnvio = item.costo_envio

                        return (
                            <div id={idModal + "condominio" + key} key={index} className={'condominioItem app-active' + (item.disponible ? " active" : "")} name="subfrac">
                                <div style={{ display: "flex", flex: 1, justifyContent: "space-between", alignItems: "center", paddingInline: 4 }}>
                                    <h4>{nombre}</h4>
                                    <div style={{ display: "flex" }}>
                                        <div className="column label-duo-normal space justify-center">
                                            <p>
                                                <b>Disponible:</b>
                                            </p>
                                        </div>
                                        <div className="space-16">
                                            <label className="container-check">
                                                <input
                                                    type="checkbox"
                                                    className="inputDisponible"
                                                    id={"input-" + key}
                                                    checked={disponible}
                                                    onChange={(event) => disponibilidadActions.modifyList("disponible", event.target.checked ? 1 : 0, key, index)}
                                                />
                                                <span className="checkmark" ></span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div className='envioContainer'>
                                    <div className='envioLabel'>
                                        <p>
                                            <b className='color-dark'>Por Envío</b>
                                        </p>
                                    </div>
                                    <div className="column auto">
                                        <p>
                                            <b className="font-mini">Tiempo:</b>
                                        </p>
                                    </div>
                                    <div className="column space-8 timeMinSuffix" style={{ flex: 1 }}>
                                        <div className="row full time">
                                            <input
                                                type="number"
                                                className="input input-modals"
                                                value={tiempoEnvio}
                                                min={0}
                                                max={1000}
                                                onChange={(event) => disponibilidadActions.modifyList("tiempo_envio_min", event.target.value, key)}
                                            />
                                        </div>
                                    </div>
                                    <div className="column auto space-16">
                                        <p>
                                            <b className="font-mini">Costo:</b>
                                        </p>
                                    </div>
                                    <div className="column space-8 cashPrefix" style={{ flex: 1 }}>
                                        <div className="row full cash">
                                            <input
                                                type="number"
                                                className="input input-modals"
                                                value={costoEnvio}
                                                min={0}
                                                max={1000}
                                                onChange={(event) => disponibilidadActions.modifyList("costo_envio", event.target.value, key)}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    )
}

const filtrarDisponibilidad = (searchFilter, list) => {

    let sh = new StringHelper();

    if(searchFilter === "") return list;

    const newCondominios = list.reduce((result, item, index) => {

        let condominio = sh.eliminarCaracteresRaros(item.fraccionamiento, true);
        let datosBusqueda = sh.eliminarCaracteresRaros(searchFilter);

        if (sh.multiMatch(condominio, datosBusqueda)) {
            result.push(item);
        }

        return result;

    }, []);

    return newCondominios;
}


