/**
 *  header.jsx
 *  @version: 1.0.0
 *  @author: DWIT MÉXICO - Sergio
 *  @description: Componente header
 */

import React, { Component, Fragment } from "react";
// import Logo from "../../img/logo.png";
import { Link } from "react-router-dom";
import { Consumer } from "../../context";
import Request from "../../core/httpClient";
import { PassThrough } from "stream";
import Rightbar from './rightbar';
import LogoImg from '../../img/LogoWebAdministracionSmaller.png';
/**Socket */
import Socket from '../../core/socket';
import moment from 'moment';
import cogoToast from 'cogo-toast';

// Helpers
import { checkProgramados, resetTimer } from "../../pages/restaurantes/pedidos/programadosHelper";

const request = new Request();

class Header extends Component {
	constructor(props) {
		super(props);
		this.state = {
			user: { idUsuario: 0, idPerfil: 0, idFraccionamiento: 0, idAdministracion: 0 },
			denied: false,
			fraccionamientos: [],
			subfraccionamientos: [],
			fraccionamiento: 0,
			fraccionamientoNombre: localStorage.getItem('fracName'),
			subfraccionamiento: 0,
			pedidos_pendientes: 0,
			hidden: false,
			pedidosRead: this.props.nav !== 'pedidos' ? localStorage.getItem('pedidos-read') : localStorage.setItem('pedidos-read', "read")
		};
	}

	/*componentDidUpdate(prevProps) {
		if(this.props.context.fracActual !== prevProps.context.fracActual) {
			this.setState({fraccionamientoNombre: localStorage.getItem('fracName')});
		}
	}*/

	openNav() {
		let sidebar = document.getElementById('sidebar');
		if (sidebar.classList.contains('active')) {
			sidebar.classList.remove('active');
			sidebar.style.minWidth = "0%";
		}
		else {
			sidebar.classList.add('active');
			sidebar.style.minWidth = "20%";
		}
	}

	render() {
		let nombre = "";
		if (this.props.context?.user?.id?.administracion) {
			nombre = this.props.context.user.id.administracion.toUpperCase();
		}
		return (
			<React.Fragment>
				<header className="justify-center header-slide">
					<div className="row full">
						{/*<div className="justify-between">*/}
						<div className="options row align-center justify-center" style={{ width: "40%" }}>
							<div className="flex space-8" style={{ flex: 1, justifyContent: "center", alignItems: "center" }}>
								<Link to="/restaurantes/configuracion" className="responsive-img auto">
									<img
										src={LogoImg}
										alt={`Logo ${process.env.REACT_APP_NAME}`}
										title={`Logo ${process.env.REACT_APP_NAME}`}
									/>
								</Link>
							</div>
							<div className="row space-16" style={{ flex: 1, width: "100%" }}>
								<h4 className="accordion color-white text-center">{nombre}</h4>
							</div>
						</div>
						<div className="row justify-end" style={{ flex: 1 }}>
							<nav className="align-center justify-end full">
								{this.state.denied ? (
									<div className="row justify-end">
										<div className="nav-item auto">
											<button
												href="#one"
												className="font-regular color-white"
												onClick={this.handleLogout.bind(
													this
												)}>
												<i className="fas fa-sign-out-alt font-text" />Salir
											</button>
										</div>
									</div>
								) : (

									<div className="row justify-end">
										<div className="nav-item auto text-center" id="restaurantes">
											<Link
												to='/restaurantes/configuracion'
												className="font-regular color-white">
												<i className="far fa-building"></i>{" "}
												Restaurantes
											</Link>
										</div>
										<div className="nav-item auto text-center" id="platos">
											<Link
												to='/restaurantes/platos'
												className="font-regular color-white">
												<i className="fas fa-utensils"></i>{" "}
												Platos
											</Link>
										</div>
										<div className="nav-item auto text-center" id="pedidos" style={{ position: "relative"}}>
											<Link
												to='/restaurantes/pedidos'
												className="font-regular color-white">
												<i className="fas fa-shipping-fast font-text"></i>{" "}
												Pedidos
											</Link>
											<button id="reload-pedidos" onClick={() => this.getPedidos()} style={{ visibility: "hidden"}}></button>
											{this.state.pedidos_pendientes > 0 &&
												<div className={"pedido-estado-icon " + this.state.pedidosRead}>
													<b className="color-white" style={{ zIndex: 1 }}>{this.state.pedidos_pendientes}</b>
													<div style={{ position: 'absolute', height: "100%", width: "100%", zIndex: 0, borderRadius: 20, rotate: "-225deg", /*borderTopStyle: "solid", borderTopWidth: 3, borderTopColor: "#FF7777",*/ backgroundColor: "#FF0000" }}></div>
												</div>
											}
										</div>
										<div className="nav-item auto">
											<a
												href="#one"
												className="font-regular color-white"
												onClick={this.handleLogout.bind(
													this
												)}>
												<i className="fas fa-sign-out-alt font-text " />{" "}
												Salir
											</a>
										</div>
										{/*<div className="nav-item auto">
												<a	onClick={() => {
														const leftbar = document.getElementById('rightsidebar');
														if(leftbar) {
															leftbar.classList.toggle('open');
														}
													}}
													href="#settings"
													className="font-regular color-white">
													<i className="fas fa-cogs font-text" />
												</a>
											</div>*/}
										&nbps;
									</div>
								)}
							</nav>
						</div>
						{/*</div>*/}
					</div>
				</header>
				<Rightbar/>
			</React.Fragment>
		);
	}

	componentDidMount() {
		this.load();
		this.getPedidos();

		Socket.on('newPedidoRestaurante', (data) => {

			if(this.props.nav !== 'pedidos') {

				if(data && data.id_estado !== 1) return;

				localStorage.setItem('pedidos-read', "unread");
				this.setState({ pedidosRead: "unread" });
			}
            this.getPedidos();
        });

		//console.log("Location: ", window.location.pathname);
		let final = { val: window.location.pathname };

		let location = final.val === "/catalogos/subfraccionamientos" || final.val === '/tablero' ||
			final.val === '/catalogos/presupuestos/concepto' || final.val === '/administracion/areas/instalaciones' ||
			final.val === '/administracion/areas/reservacion' || final.val === '/administracion/areas/historial' ||
			final.val === '/entornos' || final.val === '/perfiles' || final.val === '/users';
		if (location) {
			this.setState({ hidden: true })

		} else {
			this.setState({ hidden: false })
		}

		if (document.getElementById('sidebar')) {
			document.getElementById('sidebar').classList.add('active');
			document.getElementById('sidebar').style.minWidth = "20%";
		}

		const allMenuItems = document.getElementsByClassName('nav-item');
		for (let index = 0; index < allMenuItems.length; index++) {
			if (allMenuItems[index].classList.contains('nav-active')) {
				allMenuItems[index].classList.remove('nav-active');
			}
			if (allMenuItems[index].classList.contains('active-item')) {
				allMenuItems[index].classList.remove('active-item');
			}
		}

		switch (this.props.nav) {
			case 'restaurantes':
				document.getElementById(this.props.nav).classList.add('nav-active');
				break;
			case 'platos':
				document.getElementById(this.props.nav).classList.add('nav-active');
				break;
			case 'pedidos':
				document.getElementById(this.props.nav).classList.add('nav-active');
				break;
			default:
				break;
		}

		/*let acc = document.getElementsByClassName("accordion");
		let i;

		for (i = 0; i < acc.length; i++) {
			acc[i].addEventListener("click", function () {
				this.classList.toggle("active");
				var panel = this.nextElementSibling;
				if (panel.style.maxHeight) {
					panel.style.maxHeight = null;
				} else {
					panel.style.maxHeight = panel.scrollHeight + "px";
				}
			});
		}*/
	}

	componentWillUnmount() {
		resetTimer();
	}

	async load() {
		const user = await this.props.context.loadUser();

		if (user) {
			// console.log(user);
			if (user.auth) {
				this.setState({
					user: {
						idUsuario: user.id.Id_Usuario
					}
				});
			} else {
				this.props.context.logout();
				window.location = '/login';
			}
		}
	}

	async getPedidos() {

		let id_usuario = 0;
        let user = await this.props.context.loadUser();
        if (user) {
            if (user.auth) {
                id_usuario = user.id.Id_Usuario;
            }
        }

		const data = { id_restaurante: 0,
			id_fraccionamiento: 0,
			desde: moment().add(-1, "day").format(),
            hasta: moment().format(),
            id_usuario 
        };

        const res = await request.post('/restaurantes/pedidos/get', data);

        if (res.pedidos) {
            const { pedidos } = res;

			checkProgramados(pedidos, () => {
				this.getPedidos.bind(this);
				this.props.reload();
			} );

            const pedidosPendientes = pedidos.filter(pedido => pedido.id_estado === 1);

            this.setState({ pedidos_pendientes: pedidosPendientes.length });
        } else {
            this.setState({ pedidos_pendientes: 0 });
        }
	}

	/*async getMenu() {
		this.setState({ loadingDiarios: true });
		let data = {
			idUsuario: this.state.user.idUsuario,
			idPerfil: this.state.user.idPerfil
		};
		const response = await request.post("/users/get/menu", data);

		if (response && !response.error) {
			if (response.menus && !response.denied) {
				this.setState({
					menus: response.menus,
					denied: false
				});
			} else {
				this.setState({
					denied: true
				});
			}
		} else {
			this.setState({
				error: true,
				message: response.message,
				denied: true
			});
		}
	}*/

	handleLogout() {
		this.props.context.logout();
		window.location = "/login";

		Socket.disconnect();
	}

	handleChange(event) {

		if (!event.target.value) {
			return

		}
		this.setState({ subfraccionamiento: event.target.value });

		let data = {

			if: parseInt(document.getElementById("fraccionamiento").value),
			isf: parseInt(document.getElementById("subfrac").value)
		}
		localStorage.setItem('objectKey', JSON.stringify(data));
		this.props.reload()
		this.props.change()
	}
	// traer subfraccionamiento

	async getFraccionamientosMenus() {
		const user = await this.props.context.loadUser();
		if (user) {
			const data = { idFraccionamiento: user.id.id_fraccionamiento };
			const response = await request.post("/fraccionamientos/get/menus", data);
			if (response) {
				console.log(response);
			}
		}
	}
}

export default Consumer(Header);
