import React, { useState, useEffect, useRef } from "react";
import TimeField from 'react-simple-timefield';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import Request from "../../../../core/httpClient";
import emptyImg from "../../../../img/empty_image.png";

// Components
import MetodosPagoSelect from "../../../selectores/MetodosPagosSelect";

//Helpers
import { toggleActiveStatus } from "../../../../helpers/elementClassAdder";
import ValidityHelper from "../../../../helpers/inputValidityHelper";

//Hooks
import {
    useImageLogo,
    useMetodosPago,
    useHorarioSemanal,
    useCategoriasPlatos,
    useHorariosPlatos
}
    from "../../../../hooks/restaurantes/configuracion/restauranteHooks";

const request = new Request();

const validityHelper = new ValidityHelper();

const metodosDePagoInit = [
    { id_metodo_pago: 1, metodo_pago: "Efectivo", activo: 0, cambio: 200 },
    { id_metodo_pago: 2, metodo_pago: "Tarjeta Debito", activo: 0 },
    //{ id_metodo_pago: 3, metodo_pago: "Tarjeta Credito", activo: 0 },
]

const diasDeLaSemana = [
    'Lunes',
    'Martes',
    'Miércoles',
    'Jueves',
    'Viernes',
    'Sábado',
    'Domingo',
]

const horarioSemanalInit = [
    { dia: 1, desde: "06:00", hasta: "18:00", activo: 0 },
    { dia: 2, desde: "06:00", hasta: "18:00", activo: 0 },
    { dia: 3, desde: "06:00", hasta: "18:00", activo: 0 },
    { dia: 4, desde: "06:00", hasta: "18:00", activo: 0 },
    { dia: 5, desde: "06:00", hasta: "18:00", activo: 0 },
    { dia: 6, desde: "06:00", hasta: "18:00", activo: 0 },
    { dia: 7, desde: "06:00", hasta: "18:00", activo: 0 },
]

const horarioPlatoSelectedInit = { descripcion: "", desde: "", hasta: "" };

const Form = (props) => {

    const { DiasCierreTab, validateCats, validateHors, restaurante, getValues, idModal, isModalOpen, onChangeRestaurant } = props;

    const idRestaurantRef = useRef();

    const { image, setImage, changeImage } = useImageLogo(request);

    const [telefonoError, setTelefonoError] = useState(undefined);

    const { metodosDePago, setPagosSelection, modifyPagosSelection } = useMetodosPago(metodosDePagoInit);

    const { horarioSemanal, setHorarioSemanal, modifyHorarioSemanal } = useHorarioSemanal(horarioSemanalInit);

    const { categoriasPlatosStates, categoriasPlatosActions } = useCategoriasPlatos({ validateCategoria: validateCats });

    const { horariosPlatosStates, horariosPlatosActions } = useHorariosPlatos({ initHorarioPlato: horarioPlatoSelectedInit, validateHorario: validateHors });

    /* -- Grid -- */
    const nombreGrid = { gridColumnStart: 1, gridColumnEnd: 12 };
    const telefonoGrid = { gridColumnStart: 1, gridColumnEnd: 12 };
    const activoGrid = { gridColumnStart: 12, gridColumnEnd: 17, gridRowStart: 1, gridRowEnd: 3 };
    const tipoDeComidaGrid = { gridColumnStart: 1, gridColumnEnd: 17 };
    const metodosDePagoGrid = { gridColumnStart: 1, gridColumnEnd: 17 };
    const imagenGrid = { gridRowStart: 5, gridRowEnd: 7, gridColumnStart: 1, gridColumnEnd: 9 };
    const descripcionYPoliticasGrid = { gridRowStart: 5, gridRowEnd: 7, gridColumnStart: 9, gridColumnEnd: 17 };
    const horarioSemanalGrid = { gridColumnStart: DiasCierreTab ? 8 : 1, gridColumnEnd: 17, gridRowStart: 7, gridRowEnd: 8};
    const platosConHorarioGrid = { gridColumnStart: 1, gridColumnEnd: 17 };
    const diasDeCierreGrid = { gridColumnStart: 1, gridColumnEnd: 8, gridRowStart: 7, gridRowEnd: 8 };

    const listEmptyProps = {

        categoriasPlatos: {
            icon: "fas fa-tag color-dark",
            message: "Agrega Categorias"
        },
        horariosPlatos: {
            icon: "fas fa-clock color-dark",
            message: "Agrega Horarios"
        },
        diasCierre: {
            icon: "fas fa-calendar color-dark",
            message: "Agrega Fechas"
        }
    }
    
    useEffect(() => {

        const validityDismount = validityHelper.setCustomValidityReset("restauranteForm" + idModal);
        return validityDismount;

    }, [idModal]);

    useEffect(() => {

        if (restaurante && Object.keys(restaurante).length > 0 && restaurante.id_restaurante !==  idRestaurantRef.current) {
            idRestaurantRef.current = restaurante.id_restaurante;
            
            toggleActiveStatus(restaurante.activo ? 1 : 0, idModal + "activeCheck");

            setPagosSelection(JSON.parse(restaurante?.metodos_pago) || metodosDePagoInit);

            setHorarioSemanal(JSON.parse(restaurante?.horario_semanal) || horarioSemanalInit);

            const cats = restaurante?.categorias ? JSON.parse(restaurante?.categorias) : [];
            categoriasPlatosActions.setList(cats);

            const hors = restaurante?.horarios ? JSON.parse(restaurante?.horarios) : [];
            horariosPlatosActions.setList(hors);
        } 

    }, [restaurante]);

    useEffect(() => {
        setImage(restaurante?.imagen || '', restaurante?.id_restaurante);
    },[restaurante?.imagen]);

    useEffect(() => {

        getValues({ imagen: image });

    }, [image]);

    useEffect(() => {

        const pagos = JSON.stringify(metodosDePago);
        getValues({ metodos_pago: pagos })

    }, [metodosDePago])

    useEffect(() => {

        const horario = JSON.stringify(horarioSemanal);
        getValues({ horario_semanal: horario })

    }, [horarioSemanal])


    useEffect(() => {

        const cats = JSON.stringify(categoriasPlatosStates.list);
        getValues({ categorias: cats });

    }, [categoriasPlatosStates.list])

    useEffect(() => {

        const horsFormat = horariosPlatosStates.list.map((horario) => {
            const { created, newHorario, ...rest } = horario;
            return { ...rest };
        });
        const hors = JSON.stringify(horsFormat);
        getValues({ horarios: hors });

    }, [horariosPlatosStates.list])

    let diasCierre = restaurante?.dias_cierre ? JSON.parse(restaurante?.dias_cierre) : [];

    let categoryList = categoriasPlatosStates.list;
    categoryList = categoryList.filter(cat => cat.id_status > 0);

    let horariosList = horariosPlatosStates.list;
    horariosList = horariosList.filter(hor => hor.id_status > 0);

    return newScreen();

    function newScreen() {

        return (
            <div id={"restauranteForm" + idModal} className="row justify-center" style={{ paddingBlock: 8 }}>
                <div className="restaurante-main hideScrollbar">
                    <div style={{ ...nombreGrid }}>
                        <div className="column auto">
                            <p>
                                <b>Nombre:</b>
                            </p>
                        </div>
                        <div className="column full space-16">
                            <input
                                type="text"
                                name="Nombre"
                                id="nombre"
                                value={restaurante?.nombre || ''}
                                onChange={(e) => onChangeRestaurant('nombre', e.target.value )}
                                required
                                className="input input-modals"
                            />
                        </div>
                    </div>
                    <div id={idModal + "activeCheck"} className="justify-center app-active" style={{ ...activoGrid, cursor: "pointer" }}
                        onChange={(e) => { toggleActiveStatus(e.target.checked ? 1 : 0, idModal + "activeCheck"); onChangeRestaurant( 'activo', e.target.checked ? 1 : 0) }}
                        onClick={(event) => event.target.getElementsByTagName("input")[0].click()}
                    >
                        <div className="column label-duo-normal space" style={{ pointerEvents: "none" }}>
                            <p>
                                <b>Activo:</b>
                            </p>
                        </div>
                        <div className="space-16" style={{ pointerEvents: "none" }}>
                            <label className="container-check">
                                <input
                                    type="checkbox"
                                    name="Activo"
                                    disabled={false}
                                    checked={restaurante?.activo || false}
                                    onClick={(event) => event.stopPropagation()}
                                    readOnly
                                />
                                <span className="checkmark" ></span>
                            </label>
                        </div>
                    </div>
                    <div style={{ ...telefonoGrid }}>
                        <div className="column auto">
                            <p>
                                <b>Telefono:</b>
                            </p>
                        </div>
                        <PhoneInput
                            displayInitialValueAsLocalNumber
                            className="phone-input full space-16"
                            name="Telefono"
                            defaultCountry="MX"
                            maxLength="18"
                            autoComplete='off'
                            value={restaurante?.telefono || ''}
                            numberInputProps={{ required: true }}
                            onChange={(value) => onChangeRestaurant('telefono', value)}
                            error={telefonoError}
                        />
                    </div>
                    <div style={{ ...tipoDeComidaGrid }}>
                        <div className="row" style={{ whiteSpace: "nowrap", alignItems: "center" }}>
                            <p>
                                <b>Tipo De Comida:</b>
                            </p>
                        </div>
                        <div className="full space-16">
                            <input
                                type="text"
                                name="TipoDeComida"
                                id="nombre"
                                value={restaurante?.tipo_comida || ''}
                                onChange={(e) => onChangeRestaurant('tipo_comida', e.target.value)}
                                required
                                className="input input-modals"
                            />
                        </div>
                    </div>
                    <div className="column" style={{ ...metodosDePagoGrid }}>
                        <div className="row align-start">
                            <p>
                                <b style={{ whiteSpace: "nowrap" }}>Metodos de Pago:</b>
                            </p>
                        </div>
                        <div className="white-space-8"></div>
                        <MetodosPagoSelect
                            values={metodosDePago}
                            direction={"row"}
                            onValueChanged={({ id, prop, value }) => modifyPagosSelection(id, prop, value)}
                        />
                    </div>
                    <div className="column" style={{ ...imagenGrid }} >
                        <div className="row align-start">
                            <p>
                                <b>Imagen:</b>
                            </p>
                        </div>
                        <div className="container-img justify-center" style={{ width: "100%" }}>
                            <img src={image ? image : emptyImg} className="img-duo-preview" id="previewArea" alt=""/>
                            <div className="middle-overlay">
                                <label className="btn-upload-image column align-center justify-center">
                                    <i className="fas fa-file-upload"></i>
                                    <input type="file" id="imagenLogo" name="AreaImage" accept="image/*" required={image ? false : true} onChange={(event) => changeImage(event.target)}
                                    />
                                    <div className="white-space-8"></div>
                                    <p className="color-white">Subir imagen del restaurante</p>
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className="column" style={{ ...descripcionYPoliticasGrid }}>
                        <div className="row align-start">
                            <p>
                                <b>Descripción:</b>
                            </p>
                        </div>
                        <div className="row full text-expand">
                            <textarea
                                type="text"
                                name="Descripcion"
                                value={restaurante?.observaciones || ''}
                                required
                                onChange={(e) => onChangeRestaurant('observaciones', e.target.value )}
                                className="textarea input input-modals text-big"
                                style={{ height: "100%" }}
                                rows="2"
                                maxLength="250"
                            />
                        </div>
                        <div className="white-space-8"></div>
                        <div className="row align-start">
                            <p>
                                <b>Políticas de servicio:</b>
                            </p>
                        </div>
                        <div className="row full text-expand">
                            <textarea
                                type="text"
                                name="PoliticasDeServicio"
                                value={restaurante?.politicas_servicio || ''}
                                onChange={(e) => onChangeRestaurant('politicas_servicio', e.target.value)}
                                className="textarea input input-modals text-big"
                                style={{ height: "100%" }}
                                rows="2"
                                maxLength="250"
                            />
                        </div>
                    </div>
                    {
                        DiasCierreTab &&

                        <div className="column full" style={{ ...diasDeCierreGrid }}>
                            <DiasCierreTab
                                diasCierre={diasCierre}
                            />
                        </div>
                    }
                    <div className="column" style={{ ...horarioSemanalGrid }}>
                        <div className="align-start">
                            <h4>Horario Semanal</h4>
                        </div>
                        <div className="white-space-4"></div>
                        <table className="table-hover full">
                            <thead className="sticky-th">
                                <tr className="align-center">
                                    <th>Día</th>
                                    <th>Desde</th>
                                    <th>Hasta</th>
                                    <th>Abierto</th>
                                </tr>
                            </thead>
                            <tbody className="overflow-auto hideScrollbar" style={{ maxHeight: 225 }}>
                                {horarioSemanal.map((hor, index) =>
                                    <tr key={index} id={idModal + "diaSemana" + index} className={"horarioSemanalItem " + (hor.activo ? "abierto" : " ")} style={{ padding: 2 }}>
                                        <td>
                                            <div className="column auto justify-start" style={{ paddingLeft: 12 }}>
                                                <p>
                                                    <b>{diasDeLaSemana[hor.dia - 1] }</b>
                                                </p>
                                            </div>
                                        </td>
                                        <td className="text-center" style={{ paddingInline: 4 }}>
                                            <TimeField
                                                value={hor.desde}
                                                onChange={(event) => modifyHorarioSemanal(hor.dia, "desde", event.target.value)}
                                                input={<input className="input input-modals"></input>}
                                            />
                                        </td>
                                        <td className="text-center" style={{ paddingInline: 4 }}>
                                            <TimeField
                                                value={hor.hasta}
                                                onChange={(event) => modifyHorarioSemanal(hor.dia, "hasta", event.target.value)}
                                                input={<input className="input input-modals"></input>}
                                            />
                                        </td>
                                        <td className="flex justify-center align-center" style={{ paddingLeft: 24, paddingTop: 1 }}>
                                            <label className="container-check">
                                                <input
                                                    type="checkbox"
                                                    checked={hor.activo}
                                                    onChange={(event) => {
                                                        modifyHorarioSemanal(hor.dia, "activo", event.target.checked ? 1 : 0)
                                                    }}
                                                />
                                                <span className="checkmark"></span>
                                            </label>
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                    <div className="column" style={{ ...platosConHorarioGrid }}>
                        <div className="align-start">
                            <h4>Platos</h4>
                        </div>
                        <div className="row full">
                            <div className="column full" style={{ width: "45%" }}>
                                <div className="row align-center">
                                    <div className="column auto">
                                        <p>
                                            <b style={{ whiteSpace: "nowrap" }}>Categoría de Menú:</b>
                                        </p>
                                    </div>
                                    <div className="column full space-8">
                                        <div className="row full input-add">
                                            <input
                                                className="input input-modals"
                                                value={categoriasPlatosStates.selected}
                                                onChange={(event) => categoriasPlatosActions.setSelected(event.target.value)}
                                            />
                                            <button className="space btn-add" type="button"
                                                onClick={() => categoriasPlatosActions.addToList()}
                                            >
                                                <i className="fas fa-plus color-green"></i>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div className="white-space-8"></div>
                                <div className="input-form-content column full align-center overflow-auto" style={{ maxHeight: 182, height: 100 }}>
                                    {
                                        categoryList.length > 0 ? categoryList.map(cat =>
                                            <div className="column justify-center" key={cat.id}>
                                                <div className="column input-add">
                                                    <input
                                                        type="text"
                                                        className="input input-modals"
                                                        name="CategoriaPlato"
                                                        required
                                                        value={cat.categoria || ''}
                                                        onChange={(event) => categoriasPlatosActions.modifyList(cat.id, "categoria", event.target.value)}
                                                    />
                                                    {categoriasPlatosStates.loadingId == cat.id ?
                                                        <i className="fas fa-spinner fa-spin color-red btn-add"></i>
                                                        :
                                                        <button className="space btn-add" type="button"
                                                            onClick={() => categoriasPlatosActions.removeFromList(cat.id, cat.newCategoria)}
                                                        >
                                                            <i className="fas fa-minus color-red"></i>
                                                        </button>
                                                    }
                                                </div>
                                            </div>
                                        )
                                            :
                                            <div className='flex align-center justify-center' style={{ flex: 1, opacity: 0.6 }}>
                                                <div className="column align-center">
                                                    <i className={listEmptyProps.categoriasPlatos.icon} style={{ fontSize: 24 }}></i>
                                                    <div className='white-space-8'></div>
                                                    <h5>
                                                        {listEmptyProps.categoriasPlatos.message}
                                                    </h5>
                                                </div>
                                            </div>
                                    }
                                </div>
                            </div>
                            <div className="space-8"></div>
                            <div className="column full" style={{ width: "55%" }}>
                                <div className="input-form-content row full align-center full">
                                    <div className="row align-center">
                                        <div className="column auto">
                                            <p>
                                                <b>Horario:</b>
                                            </p>
                                        </div>
                                        <div className="column space-8">
                                            <div className="row full input-add">
                                                <input
                                                    type="text"
                                                    className="input input-modals"
                                                    value={horariosPlatosStates.selected.descripcion}
                                                    onChange={(event) => horariosPlatosActions.setSelected("descripcion", event.target.value)}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="white-space-8"></div>
                                    <div className="row align-center space-16">
                                        <div className="column width-70">
                                            <p>
                                                <b style={{ whiteSpace: "nowrap" }}>Desde:</b>
                                            </p>
                                        </div>
                                        <div className="column full space-8">
                                            <div className="row full input-add">
                                                <TimeField
                                                    value={horariosPlatosStates.selected.hora_inicio}
                                                    onChange={(event) => horariosPlatosActions.setSelected("hora_inicio", event.target.value)}
                                                    input={<input className="input input-modals"></input>}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="white-space-8"></div>
                                    <div className="row align-center space-16">
                                        <div className="column width-70">
                                            <p>
                                                <b style={{ whiteSpace: "nowrap" }}>Hasta:</b>
                                            </p>
                                        </div>
                                        <div className="column full space-8">
                                            <div className="row full input-add">
                                                <TimeField
                                                    value={horariosPlatosStates.selected.hora_fin}
                                                    onChange={(event) => horariosPlatosActions.setSelected("hora_fin", event.target.value)}
                                                    input={<input className="input input-modals"></input>}
                                                />
                                                <button className="space btn-add" type="button"
                                                    onClick={() => horariosPlatosActions.addToList()}
                                                >
                                                    <i className="fas fa-plus color-green"></i>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="white-space-8"></div>
                                <div className="input-form-content full column align-center" style={{ maxHeight: 182, height: 100, overflow: 'auto' }}>
                                    {
                                        horariosList.length > 0 ? horariosList.map((hor, index) =>
                                            <div className="row full plato-horario-test" key={hor.id}>
                                                <div className="column justify-center">
                                                    <div className="row input-add">
                                                        <input
                                                            type="text"
                                                            className="input input-modals"
                                                            name="HorarioPlatoDescripcion"
                                                            required
                                                            value={hor.descripcion || ''}
                                                            onChange={(event) => horariosPlatosActions.modifyList(hor.id, "descripcion", event.target.value)}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="column justify-center">
                                                    <div className="row input-add space-16">
                                                        <TimeField
                                                            value={hor.hora_inicio}
                                                            onChange={(event) => horariosPlatosActions.modifyList(hor.id, "hora_inicio", event.target.value)}
                                                            input={<input className="input input-modals"></input>}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="column justify-center">
                                                    <div className="row input-add space-16">
                                                        <TimeField
                                                            value={hor.hora_fin}
                                                            onChange={(event) => horariosPlatosActions.modifyList(hor.id, "hora_fin", event.target.value)}
                                                            input={<input className="input input-modals"></input>}
                                                        />
                                                        {horariosPlatosStates.loadingId == hor.id ?
                                                            <i className="fas fa-spinner fa-spin color-red btn-add"></i>
                                                            :
                                                            <button className="space btn-add" type="button"
                                                                onClick={() => horariosPlatosActions.removeFromList(hor.id, hor.newHorario)}
                                                            >
                                                                <i className="fas fa-minus color-red"></i>
                                                            </button>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                            :
                                            <div className='flex align-center justify-center' style={{ flex: 1, opacity: 0.6 }}>
                                                <div className="column align-center">
                                                    <i className={listEmptyProps.horariosPlatos.icon} style={{ fontSize: 24 }}></i>
                                                    <div className='white-space-8'></div>
                                                    <h5>
                                                        {listEmptyProps.horariosPlatos.message}
                                                    </h5>
                                                </div>
                                            </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Form;
