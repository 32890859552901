import React, { useState, useReducer, useEffect } from 'react'
import cogoToast from 'cogo-toast';
import Resizer from 'react-image-file-resizer';

//Components
import { disponibilidadReducer } from './reducer/disponibilidadReducer';

// HTTPS
import Request from '../../../core/httpClient';

const request = new Request();

export const useImageLogo = (request) => {

    const [imageLogo, setImageLogo] = useState('');

    const base64ToUrl = async (info) => {
        const data = {
            type: info.type,
            img: info.img,
            idfrac: localStorage.getItem('frac') || 0,
            id: info.id,
            module: "restaurantes"
        }

        const res = await request.post("/restaurantes/base64ToUrl", data);
        if (res) {
            // console.log(res);
            if (!res.error) {
                setImageLogo(res.url);
            }
        }
    }

    const changeImage = (file) => {
        
        if (file) {
            if (file.files[0]) {
                Resizer.imageFileResizer(
                    file.files[0],
                    400,//337
                    200,//180
                    'JPEG',
                    30,
                    0,
                    uri => {
                        // console.log(uri)
                        setImageLogo(uri);
                    },
                    'base64'
                );
            }

        }
    }

    const setImage = (imagen, id) => {

        if (imagen.indexOf('data:image/') === 0) {

            const data = {
                img: imagen,
                id: id,
                type: 1
            }

            base64ToUrl(data);

        } else {
            setImageLogo(imagen);
        }
    }

    return {
        image: imageLogo,
        setImage,
        changeImage
    }
}

export const useMetodosPago = (initMetodos) => {

    const [selectedMetodosDePagos, setSelectedMetodosDePagos] = useState(initMetodos);

    const handleSetMetodosPagoSelection = (metodos) => {

        if(Array.isArray(metodos)){

            setSelectedMetodosDePagos(metodos);
        }
    }

    const handleModifyPagoSelection = (id, prop, value) => {

        const newSelection = selectedMetodosDePagos.map(metodo => {

            if (metodo.id_metodo_pago === id) {

                metodo[prop] = value;
            }

            return metodo;
        })

        setSelectedMetodosDePagos(newSelection);
    }

    return {
        metodosDePago: selectedMetodosDePagos,
        setPagosSelection: handleSetMetodosPagoSelection,
        modifyPagosSelection: handleModifyPagoSelection
    }
}

export const useHorarioSemanal = (initHorario) => {

    const [horarioSemanal, setHorarioSemanal] = useState(initHorario);

    const handleSetHorarioSemanal = (horarios) => {

        if(Array.isArray(horarios)){
            setHorarioSemanal(horarios);
        }
    }

    const handleModifyHorarioSemanal = (diaMod, prop, value) => {

        const newSemanal = horarioSemanal.map(dia => {

            if (dia.dia === diaMod) {
                dia[prop] = value;
            }

            return dia;
        })

        setHorarioSemanal(newSemanal);
    }

    return {
        horarioSemanal,
        setHorarioSemanal: handleSetHorarioSemanal,
        modifyHorarioSemanal: handleModifyHorarioSemanal
    }
}

export const useCategoriasPlatos = ({ validateCategoria }) => {

    const [categoriasPlatos, setCategoriasPlatos] = useState([
        //{ categoria: "", id: 0, id_status: 0 }
    ]);
    const [idLoadingCat, setIdLoadingCat] = useState(0);

    const [categoria, setCategoria] = useState("");

    const errorMessages = {

        categoria: "Se debe indicar un nombre de categoría",
    }

    const checkRemoveCategoria = async (id_categoria) => {
        const res = await request.post('/restaurantes/check/categoria', {id_categoria});
        if (!res.checked) {
            if (res.error) {
                cogoToast.error(res.message, {
                    position: 'bottom-right'
                });
                return false;
            }
            cogoToast.error('Esta categoría esta asignada a un plato, no se puede eliminar.', {
                position: 'bottom-right'
            });
            return false;
        } else {
            return true;
        }
    };

    const handleSetCategoriasPlatos = (categorias) => {

        if (Array.isArray(categorias)) {
            setCategoriasPlatos(categorias);
        }
    }

    const handleCategoriaPlatoChange = (id, prop, value) => {

        const newCategoriasPlato = categoriasPlatos.map(cat => {

            if (cat.id === id) {

                cat[prop] = value;
            }

            return cat;
        })

        setCategoriasPlatos(newCategoriasPlato);
    }

    const handleAddCategoriaPlato = () => {

        if (!categoria) {
            cogoToast.warn(errorMessages.categoria, {
                position: 'bottom-right'
            });
            return;
        }

        let id = 1;
        let newCategoriasPlatos = Array.from(categoriasPlatos);

        newCategoriasPlatos.forEach(dia => {
            if (dia.id >= id) id = dia.id + 1;
        })

        newCategoriasPlatos.push({ id, categoria, id_status: 1, newCategoria: true });

        setCategoria("");
        setCategoriasPlatos(newCategoriasPlatos);
    }

    const handleRemoveCategoriaPlato = async (id, newCategoria) => {

        setIdLoadingCat(id);

        let edit = validateCategoria && !newCategoria ? await checkRemoveCategoria(id) : true;

        if (edit) {

            const newCategorias = categoriasPlatos.map(cat => {
                if (cat.id === id) {
                    cat.id_status = 0;
                }
                return cat;
            });

            setCategoriasPlatos(newCategorias);
        }

        setIdLoadingCat(0);
    }

    return {

        categoriasPlatosStates: {
            selected: categoria,
            list: categoriasPlatos,
            loadingId: idLoadingCat
        },
        categoriasPlatosActions: {
            setSelected: setCategoria,
            setList: handleSetCategoriasPlatos,
            modifyList: handleCategoriaPlatoChange,
            addToList: handleAddCategoriaPlato,
            removeFromList: handleRemoveCategoriaPlato
        }
    }
}

export const useHorariosPlatos = ({ initHorarioPlato, validateHorario }) => {

    const [horariosPlatos, setHorariosPlatos] = useState([
        //{ descripcion: "", hasta: "", id: 0, id_status: 0 }
    ]);

    const [idLoadingHor, setIdLoadingHor] = useState(0);

    const [horario, setHorario] = useState(initHorarioPlato);

    const errorMessages = {

        descripcion: "Se debe indicar un nombre de horario.",
        hora_inicio: "Se debe indicar una hora limite."
    }

    const checkRemoveHorario = async (id_horario) => {
        const res = await request.post('/restaurantes/check/horario', {id_horario});
        if (!res.checked) {
            if (res.error) {
                cogoToast.error(res.message, {
                    position: 'bottom-right'
                });
                return false;
            }
            cogoToast.error('Este horario esta asignado a un plato, no se puede eliminar.', {
                position: 'bottom-right'
            });
            return false;
        } else {
            return true;
        }
    }

    function changeHorariosFormat(horarios) {

        const newHorarios = horarios.map(hor => {

            return { ...hor };
        })

        return newHorarios;
    }

    const handleSetHorariosPlatos = (horarios) => {

        if (Array.isArray(horarios)) {
            const horariosReformat = changeHorariosFormat(horarios);
            setHorariosPlatos(horariosReformat);
        }
    }

    const handleHorarioPlatoSelectedChange = (prop, value) => {

        const newHorarioPlato = Object.assign({}, horario);

        newHorarioPlato[prop] = value;

        setHorario(newHorarioPlato);
    }

    const handleHorarioPlatoChange = (id, prop, value) => {

        const newHorariosPlatos = horariosPlatos.map((hor) => {

            if (hor.id === id) {
                hor[prop] = value;
            }

            return { ...hor, change: true };
        })

        setHorariosPlatos(newHorariosPlatos);
    }

    function handleAddHorarioPlato() {

        const errorKeys = Object.keys(errorMessages);

        for (let index = 0; index < errorKeys.length; index++) {
            const key = errorKeys[index];

            if (!horario.hasOwnProperty(key)) continue;

            if (!horario[key]) {
                cogoToast.warn(errorMessages[key], {
                    position: 'bottom-right'
                });
                return;
            }

        }

        let newHorariosPlatos = Array.from(horariosPlatos);

        let id = 1;

        newHorariosPlatos.forEach(horario => {
            if (horario.id >= id) id = horario.id + 1;
        })

        newHorariosPlatos.push({ 
            id,
            ...horario,
            descripcion: horario.descripcion, 
            hora_inicio: horario.hora_inicio, 
            hora_fin: horario.hora_fin, 
            id_status: 1,
            newHorario: true,
        });

        setHorario(initHorarioPlato);
        setHorariosPlatos(newHorariosPlatos);
    }

    const handleRemoveHorarioPlato = async (id, newHorario) => {

        setIdLoadingHor(id)

        let edit = validateHorario && !newHorario ? await checkRemoveHorario(id) : true;

        if (edit) {

            const newHorarios = horariosPlatos.map(horario => {
                if (horario.id === id) {
                    horario.id_status = 0;
                }
                return horario;
            });

            setHorariosPlatos(newHorarios);
        }

        setIdLoadingHor(0)
    }

    return {

        horariosPlatosStates: {
            selected: horario,
            list: horariosPlatos,
            loadingId: idLoadingHor
        },
        horariosPlatosActions: {
            setSelected: handleHorarioPlatoSelectedChange,
            setList: handleSetHorariosPlatos,
            modifyList: handleHorarioPlatoChange,
            addToList: handleAddHorarioPlato,
            removeFromList: handleRemoveHorarioPlato
        }
    }
}

export const useDiasCierre = (selectedInit) => {

    const [diaCierreSelected, setDiaCierreSelected] = useState(selectedInit);

    const [restaurantesSelected, setRestaurantesSelected] = useState([]);

    const [diasCierreList, setDiasCierreList] = useState([]);

    const errorMessages = {

        restaurantesDisponibles: "Todavia hay restaurantes utilizando esta fecha."
    }

    const handleSetDiasCierreList = (diasCierre) => {

        if(Array.isArray(diasCierre)){
  
            setDiasCierreList(diasCierre);
        }
    }

    const handleModifyDiaCierreSelected = (prop, value) => {

        let newDiaCierreSelected = {...diaCierreSelected};

        newDiaCierreSelected[prop] = value;

        setDiaCierreSelected(newDiaCierreSelected);
    }

    const handleModifyRestaurantesSelected = (id, add) => {

        let newRestaurantsSelection = Array.from(restaurantesSelected);

        if(add){

            newRestaurantsSelection.push(id);
        }
        else{

            const removeIndex = newRestaurantsSelection.indexOf(id);
            newRestaurantsSelection.splice(removeIndex, 1);
        }

        setRestaurantesSelected(newRestaurantsSelection);

    }

    const handleAddDiaCierre = () => {

        let newDiaCierreList = Array.from(diasCierreList);

        let newDiaCierre = Object.assign({ id_restaurantes: restaurantesSelected }, diaCierreSelected);

        newDiaCierreList.push(newDiaCierre);

        setRestaurantesSelected([]);
        setDiaCierreSelected(selectedInit);
        setDiasCierreList(newDiaCierreList);
    }

    const handleRemoveDiaCierre = (diaIndex) => {

        const diaCierre = diasCierreList[diaIndex];

        if(diaCierre.idRestaurantes.length > 0){
            cogoToast.warn(errorMessages.restaurantesDisponibles, {
                position: 'bottom-right'
            });
            return;
        }

        let newDiaCierreList = Array.from(diasCierreList);

        newDiaCierreList.splice(diaIndex, 1);

        setDiasCierreList(newDiaCierreList);
    }

    const handleAddRestaurante = (diaIndex, idRes) => {

        let newDiaCierreList = Array.from(diasCierreList);

        newDiaCierreList[diaIndex].idRestaurantes.push(idRes);

        setDiasCierreList(newDiaCierreList);

    }

    return {

        diasCierreInit:{
            selected: selectedInit
        },

        diasCierreStates: {
            diaSelected: diaCierreSelected,
            diaslist: diasCierreList,
            restauranteSelected: restaurantesSelected
        },

        diasCierreActions: {
            setDiaSelected: handleModifyDiaCierreSelected,
            modifyRestaurantesSelected: handleModifyRestaurantesSelected,
            setDiasList: handleSetDiasCierreList,
            addDiaToList: handleAddDiaCierre,
            removeDiaFromList: handleRemoveDiaCierre,
            addRestaurante: handleAddRestaurante,
        }
    }
}

export const useDisponibilidad = (filterMethod) => {

    const [currentDisponibilidad, disponibilidadDispatch] = useReducer(disponibilidadReducer, []);

    const [disponibilidadFiltrados, setDisponibilidadFiltrados] = useState([]);

    const [searchFilter, setSearchFilter] = useState('');

    const disponibilidadAction = {
        set: "setDisponibilidades",
        modify: "modifyDisponibilidad",
    }

    useEffect(() => {

        if (disponibilidadFiltrados.length <= 0) {

            setDisponibilidadFiltrados(currentDisponibilidad);

        } 
        else {

            const dispCopy = Array.from(currentDisponibilidad);

            const nuevosFiltrados = disponibilidadFiltrados.map(filtrado => {

                for (let index = 0; index < dispCopy.length; index++) {
                    const condominio = dispCopy[index];

                    if (condominio.id
                        === filtrado.id) {
                        dispCopy.splice(index, 1);
                        return condominio;
                    }

                }

                return filtrado;
            })

            setDisponibilidadFiltrados(nuevosFiltrados);
        }

    }, [currentDisponibilidad]);

    useEffect(() => {

        const filtrados = filterMethod(searchFilter, currentDisponibilidad);

        setDisponibilidadFiltrados(filtrados);

    }, [searchFilter])

    const setDisponibilidad = (values) => {

        disponibilidadDispatch({
            type: disponibilidadAction.set,
            values: values,
        })

        setDisponibilidadFiltrados(values);
    }

    const modifyDisponibilidad = (prop, value, key) => {

        disponibilidadDispatch({
            type: disponibilidadAction.modify,
            modify: prop,
            value: value,
            key
        })
    }

    return {

        disponibilidadStates: {
            list: currentDisponibilidad,
            filter: searchFilter,
            filteredList: disponibilidadFiltrados
        },
        disponibilidadActions: {
            setList: setDisponibilidad,
            modifyList: modifyDisponibilidad,
            setFilter: setSearchFilter,
            //create: createDisponibilidad
        }
    }
}


