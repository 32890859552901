import React, { Component } from 'react';
import { Helmet } from "react-helmet";
import Header from "../../components/header/header";
import SidebarOperacion from "../../components/header/sidebarOperacion";
import Controls from '../../components/controls/controls';
import Request from "../../core/httpClient";
import Permissions from '../../middlewares/Permissions';
import PedidosTable from '../../components/restaurantes/pedidos/table';
import moment from 'moment';
import cogoToast from 'cogo-toast';
import sortableData from '../../helpers/sortableDataTable';
import toaster from "toasted-notes";
import DatePicker from "react-datepicker";

// Components
import PaginadorParent from '../../components/paginador/PaginadorParent';
import NuevoPedidoModal from './pedidos/components/nuevoPedidoModal';
import MultiSelect from '../../components/selectores/MultiSelect';

// Helpers
import { checkProgramados } from './pedidos/programadosHelper';

// Validations
import { checkValidTime, checkValidDay } from './pedidos/validations';

/**Socket */
import Socket from '../../core/socket';
import { Consumer } from "../../context";

const request = new Request();

const pedidoEstados = [

    "Programado",
    "Pendiente",
    "En proceso",
    "En camino",
    "Entregado",
    "Cancelado"
];

const controlsHeight = 154;
const paginadorParentHeight = 38;

class Pedidos extends Component {
    constructor(props) {
        super(props);

        const endDate = new Date();
        let startDate = new Date();        

        this.state = {
            showModal: false,
            restaurantes: [],
            categorias: [],
            pedidos: [],
            prePagedPedidos: [],
            pagedPedidos: [],
            estadosActivos: Array.from(pedidoEstados, (_,index) => index),
            filterData: [],
            keys: [],
            filter: '',
            subfraccionamiento: localStorage.getItem('subfrac') || 0,
            fraccionamiento: localStorage.getItem('frac') || 0,
            fracNombre: "",
            id_restaurante: localStorage.getItem('pedIdRes') || 0,
            categoriaSelected: '',
            escritura: true,
            lectura: true,
            logo: require("../../img/logo-AppMosphera-r.png"),
            menu: null,
            isModalOpen: false,
            loading: true,
            loadingRestaurantes: true,
            direction: {
                vivienda: 'desc',
                fecha_entrega: 'desc',
                estado: 'desc',
                total: 'desc'
            },
            filterColumn: {
                col: '',
                filt: ''
            },
            endDate: endDate,
            startDate: startDate,
            tableHeight: 0,

            isTestModalOpen: false,
        }

        this.setPedidosBodyHeightCallback = this.setPedidosBodyHeight.bind(this);
        this.pedidosSubmittedCallback = this.pedidoSubmitted.bind(this);
        this.closeModalCallback = () => this.setState({ isModalOpen: false });
    }

    componentDidMount() {
        this.getRestaurantes();
        this.loadPedidos();

        Socket.on('newPedidoRestaurante', (data) => {
            this.loadPedidos();
        });

        const pedidosBody = document.getElementsByClassName("pedidosBody")[0];
        let newTableHeight = pedidosBody.clientHeight - controlsHeight - paginadorParentHeight - 50;

        this.setState({ tableHeight: newTableHeight });

        window.addEventListener("resize", this.setPedidosBodyHeightCallback);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.setPedidosBodyHeightCallback);
    }

    setPedidosBodyHeight(e) {

        const newHeight = document.getElementsByClassName("pedidosBody")[0].clientHeight - controlsHeight - paginadorParentHeight - 50;
        this.setState({ tableHeight: newHeight });
    }

    async getRestaurantes() {
        this.setState({ loadingRestaurantes: true });

        const user = await this.props.context.loadUser();
        if (user) {
            if (user.auth) {

                this.setState({ fracNombre:  user.id.fraccionamiento });

                const data = {
                    idUser: user.id.Id_Usuario
                }
                const res = await request.post('/restaurantes/getAll', data);
                if (Array.isArray(res.restaurantes)) {

                    const fechaHoy = moment();
                    let today = fechaHoy.day();
                    today = today === 0 ? 6 : today - 1;

                    res.restaurantes.forEach((restaurante) => {

                        restaurante.categorias = this.getJSONArray(restaurante, "categorias", cat => cat.id_status > 0);
                        restaurante.metodos_pago = this.getJSONArray(restaurante, "metodos_pago", metodo => metodo.activo === 1);
                        restaurante.horario_semanal = this.getJSONArray(restaurante, "horario_semanal");
                        restaurante.horarios = this.getJSONArray(restaurante, "horarios", hor => hor.id_status === 1);
                        restaurante.dias_cierre = this.getJSONArray(restaurante, "dias_cierre");
                        restaurante.fraccionamientos = this.getJSONArray(restaurante, "fraccionamientos");

                        if(restaurante.horario_semanal.length === 0) {
                            restaurante.cerrado = "Sin horarios";
                            return;
                        }

                        const horarioHoy = restaurante.horario_semanal[today];
                        const validTime = checkValidTime(horarioHoy, fechaHoy.format("YYYY-MM-DD"), fechaHoy);

                        if(validTime !== "Valida") {
                            restaurante.cerrado = "Cerrado";
                            return;
                        }

                        const validDay = checkValidDay(fechaHoy, horarioHoy, restaurante.dias_cierre);

                        if(!validDay.open) {
                            restaurante.cerrado = validDay.descripcion;
                            return;
                        }
                    });

                    this.setState({ restaurantes: res.restaurantes });
                    const id_restaurante = localStorage.getItem('pedIdRes');
                    if (id_restaurante) {
                        this.setState({ id_restaurante });
                    }
                    // this.getPedidos(id_restaurante);
                } else {
                    this.setState({ empty: true, restaurantes: []/*, message: res.message || 'No se pudo obtener información.'*/ });
                }
            }
        }

        this.setState({ loadingRestaurantes: false });
    }

    async getPedidos(id_restaurante, desde, hasta) {

        this.setState({ loading: true });

        let id_usuario = 0;
        let user = await this.props.context.loadUser();

        if (user) {
            if (user.auth) {
                id_usuario = user.id.Id_Usuario;
            }
        }

        const data = {
            id_restaurante,
            id_fraccionamiento: this.state.fraccionamiento,
            desde: moment(desde).format(),
            hasta: moment(hasta).format(),
            id_usuario
        }

        const res = await request.post('/restaurantes/pedidos/get', data);

        if (res.pedidos) {

            const { pedidos } = res;

            pedidos.forEach(pedido => {

                if (pedido.url_entrega) {
                    pedido.url_entrega = `https://visit.appmosphera.app/pedido/${pedido.url_entrega}`
                    //pedido.url_entrega = `https://visit.appmosphera.app/pedido/${pedido.url_entrega}/test`
                    //pedido.url_entrega = `https://visit.appmosphera.app/pedido/${pedido.url_entrega}/dev`
                    //pedido.url_entrega = `http://localhost:8082/pedido/${pedido.url_entrega}`
                    //pedido.url_entrega = `http://localhost:3001/pedido/${pedido.url_entrega}`
                };
            });

            checkProgramados(pedidos);

            this.setState({ 
                pedidos, 
                prePagedPedidos: this.pedidosApplyFilters(pedidos) });

        } else {
            this.setState({ 
                pedidos: [],
                prePagedPedidos: [] 
            });
        }

        this.setState({ loading: false });
    }

    loadPedidos() {

        const reloadPendientesButton = document.getElementById('reload-pedidos');
        reloadPendientesButton.click();

        this.getPedidos(
            this.state.id_restaurante,
            this.state.startDate,
            this.state.endDate
        );
    }

    changeFrac() {
        this.getRestaurantes();
    }

    subChange(sub) {
        this.setState({ subfraccionamiento: sub });
    }

    setFilter(key, value) {

        const filteredPedidos = this.pedidosApplyFilters(this.state.pedidos, { [key]: value });

        this.setState({ [key]: value, prePagedPedidos: [...filteredPedidos]})
    }

    pedidosApplyFilters(pedidos, filters) {

        const filter = filters?.filter !== undefined ? filters.filter : this.state.filter;
        const estadosActivos = filters?.estadosActivos || this.state.estadosActivos;

        const textFilters = [
            (pedido) => pedido.restaurante.toLowerCase().includes(filter),
            (pedido) => pedido.nombre_residente.toLowerCase().includes(filter),
            (pedido) => pedido.telefono_residente.toLowerCase().includes(filter)
        ];

        const estadosActivosFilters = estadosActivos.map((estado) =>
            (pedido) => pedido.id_estado === estado);

        let filtersArrays = [];
        if(filter !== "") filtersArrays.push(textFilters);
        if(!(estadosActivos.length === pedidoEstados.length)) filtersArrays.push(estadosActivosFilters);

        const isAnyFilterValid = (array, pedido) => array.some((filter) => filter(pedido));
        const isEveryFilterArrayValid = (arrays, arrayCheck, pedido) => arrays.every((array) => arrayCheck(array, pedido))

        const filteredPedidos = pedidos.filter(pedido => isEveryFilterArrayValid(filtersArrays, isAnyFilterValid, pedido));

        return filteredPedidos;
    }

    getJSONArray(restaurante, key, filterMethod) {

        return filterMethod ?
            JSON.parse(restaurante[key] || "[]").filter(filterMethod)
            : JSON.parse(restaurante[key] || "[]");
    }

    changeFecha(event) {
        const [startDate, endDate] = event;
        this.setState({ startDate: startDate });
        this.setState({ endDate: endDate });
    }

    changeRestaurante(event) {
        const id_restaurante = event.target.value;
        this.setState({ id_restaurante });
        localStorage.setItem('pedIdRes', id_restaurante);
    }

    sortableTable(key) {
        let sd = new sortableData();
        const { items, direction } = sd.sortBy(key, this.state.prePagedPedidos, this.state.direction[key]);

        this.setState({
            prePagedPedidos: [...items],
            direction: {
                [key]: direction
            },
            filterColumn: {
                col: key,
                filt: direction
            }
        });
    }

    pedidoSubmitted() {
        this.loadPedidos();
        this.closeModalCallback();
    }

    render() {
        let restaurantes = [];

        if (Array.isArray(this.state.restaurantes)) {
            restaurantes = this.state.restaurantes;
        }

        let menu = this.state.menu;

        return (
            <div className="admin column" style={{ flex: 1 }}>
                <Helmet>
                    {/*<title>{process.env.REACT_APP_NAME} - Pedidos</title>*/}
                </Helmet>
                <Header
                    reload={this.loadPedidos.bind(this)}
                    change={this.changeFrac.bind(this)}
                    sidebar={true}
                    active={'activas'}
                    parent={'areas'}
                    panel={'panel3'}
                    nav={'pedidos'}

                    menu={this.state.menu != null ? this.state.menu : null}
                />

                <div className="row pedidosBody" style={{ flex: 1 }}>
                    <div className="column" id="content">
                        <div className="flex justify-center">
                            <div className="container column">
                                {this.state.lectura ?
                                    <React.Fragment>
                                        <Controls title="Pedidos"
                                            nuevo={() => this.setState({ isModalOpen: true })}
                                            dataSet={this.state.pedidos}
                                            setFilter={(value) => this.setFilter("filter", value.toLowerCase())}
                                            subChange={this.subChange.bind(this)}
                                            newButton={true}
                                            selectSub={false}
                                        />
                                        <div className='column justify-start align-center' style={{ overflowY: "auto", maxHeight: this.state.tableHeight, paddingBottom: this.state.pagedPedidos.length === 1 ? 35: "auto" }}>
                                            <div className="row justify-start align-center" style={{ zIndex: 2, paddingBottom: 16 }}>
                                                <div className="column justify-center">
                                                    <p>Restaurante:</p>
                                                    <select
                                                        className="input input-modals"
                                                        value={this.state.id_restaurante}
                                                        disabled={this.state.loadingRestaurantes}
                                                        onChange={this.changeRestaurante.bind(this)}
                                                    >
                                                        <option value="0">Todos</option>
                                                        {restaurantes.map(res => <option key={res.id_restaurante} value={res.id_restaurante}>{res.nombre}</option>)}
                                                    </select>
                                                </div>
                                                &nbsp;
                                                <div className="column justify-center" style={{ maxWidth: "25%", marginRight: 4 }}>
                                                    <p>Pedido Estado:</p>
                                                    <MultiSelect
                                                        options={pedidoEstados}
                                                        selectedOptionsIndeces={this.state.estadosActivos}
                                                        onSelectedChange={(estadosActivos) => this.setFilter("estadosActivos", estadosActivos)}
                                                    />
                                                </div>
                                                <div className="column justify-center">
                                                    <p>Fecha Entrega:</p>
                                                    <div style={{ paddingTop: 2 }}>
                                                        <DatePicker
                                                            className="full input input-modals"
                                                            selected={this.state.startDate}
                                                            onChange={this.changeFecha.bind(this)}
                                                            startDate={this.state.startDate}
                                                            endDate={this.state.endDate}
                                                            selectsRange
                                                            // locale="es"
                                                            dateFormat="dd/MM/yyyy"
                                                        />
                                                    </div>
                                                </div>
                                                &nbsp;
                                                <div className="column justify-center" style={{ width: '30%' }}>
                                                    <div className="white-space-16" />
                                                    <button
                                                        className="btn btn-primary btn-small color-white space-16"
                                                        disabled={this.state.loading}
                                                        onClick={this.loadPedidos.bind(this)}>
                                                        {!this.props.loading ?
                                                            <i className="fas fa-search" />
                                                            : <i className="fas fa-spinner fa-spin" />
                                                        }
                                                        &nbsp; OBTENER
                                                    </button>
                                                </div>
                                            </div>
                                            {this.state.loading ?
                                                <div className="row justify-center align-center" style={{ height: 200 }}>
                                                    <i className="fas fa-spinner fa-spin"></i>
                                                </div>
                                                :
                                                <PedidosTable
                                                    id_restaurante={this.state.id_restaurante}
                                                    pedidos={this.state.pagedPedidos}
                                                    escritura={this.state.escritura}
                                                    reload={this.loadPedidos.bind(this)}
                                                    sortBy={this.sortableTable.bind(this)}
                                                    filterColumn={this.state.filterColumn}
                                                />
                                            }
                                        </div>
                                        <div className="white-space-16"></div>
                                        <PaginadorParent
                                            data={this.state.prePagedPedidos}
                                            onPagedDataChange={({ pagedData }) => this.setState({ pagedPedidos: pagedData })}
                                        />
                                    </React.Fragment> :
                                    null
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <NuevoPedidoModal 
                    idModal={"nuevoPedidoModal"}
                    getUser={this.props.context.loadUser}
                    fraccionamiento={this.state.fracNombre}
                    isModalOpen={this.state.isModalOpen}
                    restaurantes={restaurantes}
                    onCloseModal={this.closeModalCallback}
                    onSuccesfulSubmit={this.pedidosSubmittedCallback}
                />
            </div>
        );
    }

}

export default Consumer(Pedidos);