import React, { useState, useEffect } from 'react';
import NumberFormat from "react-number-format";
import moment from 'moment-timezone';
import Clipboard from '../../clipboard/Clipboar';
import Request from "../../../core/httpClient";

const request = new Request();

const estadoIcons = {

    programado: "fas fa-calendar-alt",
    pendiente: "fas fa-clock",
    proceso: "fas fa-magic",
    camino: "fas fa-shipping-fast",
    entregado: "fas fa-check",
    cancelado: "fas fa-window-close",
}

const pedidoEstados = [

    "Programado",
    "Pendiente",
    "En proceso",
    "En camino",
    "Entregado",
    "Cancelado"
]

const PedidosDetail = (props) => {

    const { pedido, loadingLineas, lineas, getValues, isModalOpen } = props;

    const [estado, setEstado] = useState(0);

    const [loadingUrl, setLoadingUrl] = useState(false);

    useEffect(() => {

        const estadoBotones = document.getElementsByClassName("btn-continuar-estado")[0];

        const progresarEnter = (event) => {

            if (event.target.name !== "procesar") return;

            estadoBotones.classList.add("procesar");
        }

        const progresarLeave = (event) => {

            if (event.target.name !== "procesar") return;

            estadoBotones.classList.remove("procesar");
        }

        estadoBotones.addEventListener("mouseover", progresarEnter);
        estadoBotones.addEventListener("mouseout", progresarLeave);

        return () => {
            estadoBotones.removeEventListener("mouseover", progresarEnter);
            estadoBotones.removeEventListener("mouseout", progresarLeave);
        }

    }, [])

    useEffect(() => {

        if (Object.keys(pedido).length <= 0) return;

        const pedidoEstado = document.getElementsByClassName("pedido-estado")[0];

        if (isModalOpen) {

            setEstado(pedido.id_estado);
            pedidoEstado.style.setProperty("--proceso", pedido.id_estado - 1);
        }
        else {
            setEstado(-1);
            pedidoEstado.style.setProperty("--proceso", -1);
        }

    }, [pedido, isModalOpen]);

    useEffect(() => {

        getValues({ ...pedido, id_estado: estado });

    }, [pedido, estado, getValues]);

    const procesarEstado = () => {

        if (estado >= 4) return;

        const pedidoEstado = document.getElementsByClassName("pedido-estado")[0];

        const nuevoEstado = (estado + 1) % 6;

        if (nuevoEstado < 4) pedidoEstado.style.setProperty("--proceso", nuevoEstado - 1);
        
        setEstado(prevState => (prevState + 1));
    }

    const cancelarEstado = () => {

        setEstado(5);
    }

    const estadoFinalEntregado = (extra) => {

        if (estado % 6 === 4) {
            return "entregado " + extra;
        }

        if (estado % 6 === 5) {
            return "cancelado " + extra;
        }
    }

    const estadoFinalIcon = () => {

        if (estado % 6 < 5) {
            return estadoIcons.entregado;
        } else {
            return estadoIcons.cancelado;
        }
    }

    const handleGenerateURL = async () => {

        setLoadingUrl(true);

        const data = {
            id_pedido: pedido.id_pedido,
            restaurante: pedido.restaurante,
            inquilino: pedido.nombre_residente,
            telefono: pedido.telefono_residente,
            metodo_pago: pedido.metodo_pago,
            total: pedido.total,
            fecha_entrega: pedido.fecha_entrega,
            comentarios: pedido.comentarios,
            fraccionamineto: pedido.fraccionamiento,
            subfraccionamineto: pedido.subfraccionamiento,
            vivienda: pedido.vivienda,
            estado: pedido.estado,
            orden: lineas
        }

        const response = await request.post('/restaurantes/pedidos/invitacion', data);

        console.log(response.url);

        pedido.url_entrega = response.url;
        //pedido.url_entrega = response.url.replace('https://visit.appmosphera.app','http://localhost:3001');

        setLoadingUrl(false);
    }

    return (
        <div className="column" style={{ overflowX: "hidden" }}>
            <div className='pedido-estado column full justfy-center align-center'>
                <div className="row justify-center align-center">
                    <h4>{pedidoEstados[(estado) % 6]}</h4>
                </div>
                <div className='flex justify-center btn-continuar-estado'
                    style={{ display: pedido.id_estado > 3 ? "none" : "flex", width: "50%" }}
                >
                    <button name="cancelar" type="button" className="btn-cancelar"
                        style={{ marginInline: 4, width: pedido.id_estado === 0 ? "50%" : "100%" }}
                        onClick={() => cancelarEstado()}
                    >
                        {"CANCELAR"}
                    </button>
                    <button name='procesar' type="button" className="btn-procesar"
                        style={{ marginInline: 4, display: pedido.id_estado === 0 ? "none" : "block" }}
                        onClick={() => procesarEstado()}
                    >
                        {"PROCESAR"}
                    </button>
                </div>
                <div className={'estado-icons row full justify-center align-center ' + (pedido.id_estado > 3 ? estadoFinalEntregado("init-terminado") : estadoFinalEntregado(""))}>
                    {pedido.id_estado !== 0 ?
                        <>
                            <div>
                                <i className={(estadoIcons.pendiente) + (estado % 6 === 1 ? " active" : "")}></i>
                            </div>
                            <div>
                                <i className={(estadoIcons.proceso) + (estado % 6 === 2 ? " active" : "")}></i>
                            </div>
                            <div>
                                <i className={(estadoIcons.camino) + (estado % 6 === 3 ? " active" : "")}></i>
                            </div>
                            <div>
                                <i className={estadoFinalIcon() + (estado % 6 === 4 || estado % 6 === 5 ? " active" : "")}></i>
                            </div>
                            <div className='next-estado-arrow align-center'>
                                {estado % 6 < 4 &&
                                    <i className="fas fa-arrow-right"></i>
                                }
                            </div>
                        </>
                        :
                        <div style={{ width: "100%", left: 0 }}>
                            <i
                                className={(estado === 0 ? estadoIcons.programado : estadoIcons.cancelado) + " active"}
                                style={ estado === 0 ? 
                                    { backgroundColor: "purple", transitionDuration: "0s"} 
                                    : { backgroundColor: "red", transitionDuration: "500ms" }
                                }
                            />
                        </div>
                    }
                </div>
                <div className="white-space-8"></div>
            </div>
            <div className="white-space-16"></div>
            <div className='column pedidos-modal-responsive' >
                <div className='column full align-center' style={{ padding: 8 }}>
                    <div className="input-form-content row full align-center">
                        <div className="column label-big">
                            <p>
                                <b>Cliente:</b>
                            </p>
                        </div>
                        <div className="column full">
                            <input className='input input-modals' value={pedido.nombre_residente || ''} disabled={true} />
                        </div>

                    </div>
                    <div className="white-space-8"></div>
                    <div className="input-form-content row full align-center">
                        <div className="column label-big">
                            <p>
                                <b>Telefono:</b>
                            </p>
                        </div>
                        <div className="column full">
                            <div className='row align-center input input-modals'>
                                <NumberFormat
                                    className="font-small"
                                    style={{ color: "#075E54", whiteSpace: "nowrap" }}
                                    value={pedido.telefono_residente}
                                    displayType={"text"}
                                    format={"+## ### #### ###"}
                                />
                                <div className='row justify-end full'>
                                    <a style={{ display: "flex", justifyContent: "center", alignItems: "center" }} target="_blank" rel="noopener noreferrer" href={"https://wa.me/" + pedido.telefono_residente}>
                                        <i className="fab fa-whatsapp space-8 " style={{ fontSize: 20, color: "#25D366" }}></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="white-space-8"></div>
                    <div className="input-form-content row full align-center">
                        <div className="column label-big">
                            <p>
                                <b>Tipo de Pago:</b>
                            </p>
                        </div>
                        <div className="column full">
                            <input className='input input-modals' value={pedido.metodo_pago || ""} disabled={true} />
                        </div>
                    </div>
                    <div className="white-space-8"></div>
                    <div className="input-form-content row full align-center">
                        <div className="column label-big">
                            <p>
                                <b>Costo Envio:</b>
                            </p>
                        </div>
                        <div className="column" style={{ width: "30%" }}>
                            <NumberFormat
                                value={pedido.costo_envio || 0}
                                displayType={"text"}
                                thousandSeparator={true}
                                prefix={"$ "}
                                decimalScale={2}
                                fixedDecimalScale={true}
                                className="input input-modals"
                            />
                        </div>
                        <div className="column label-normal space">
                            <p>
                                <b>Total:</b>
                            </p>
                        </div>
                        <div className="column" style={{ width: "40%" }}>
                            <NumberFormat
                                value={pedido.total}
                                displayType={"text"}
                                thousandSeparator={true}
                                prefix={"$ "}
                                decimalScale={2}
                                fixedDecimalScale={true}
                                className="input input-modals"
                            />
                        </div>
                    </div>
                    <div className='white-space-16 full' style={{ borderBottomWidth: 1, borderBottomColor: "#CCC", borderBottomStyle: "solid" }}></div>
                    <div className="input-form-content row full align-center" style={{ paddingTop: 8 }}>
                        <div className="column label-big" >
                            <p>
                                <b>
                                    {pedido.fecha_orden_programada ?
                                        "Fecha programada:"
                                        : "Fecha de pedido:"
                                    }
                                </b>
                            </p>
                        </div>
                        <div className="column full">
                            <input
                                name="fecha_entrega"
                                id="fecha_entrega"
                                value={moment(pedido.fecha_orden_programada || pedido.fecha_create).format('YYYY-MM-DD HH:mm a')}
                                className="input input-modals"
                                readOnly
                                disabled
                            />
                        </div>
                    </div>
                    <div className="white-space-8"></div>
                    <div className="input-form-content row full align-center">
                        <div className="column label-big" >
                            <p>
                                <b>Fecha de entrega:</b>
                            </p>
                        </div>
                        <div className="column full">
                            <input
                                name="fecha_entrega"
                                id="fecha_entrega"
                                value={pedido.fecha_entrega ? moment(pedido.fecha_entrega).format('YYYY-MM-DD HH:mm a') : "--"}
                                className="input input-modals"
                                readOnly
                                disabled
                            />
                        </div>
                    </div>
                    <div className="white-space-8"></div>
                    <div className="row full justify-center">
                        <div className="input-form-content row full align-start">
                            <div className="column label-big">
                                <p>
                                    <b>Comentarios:</b>
                                </p>
                            </div>
                            <div className="column full">
                                <textarea
                                    type="text"
                                    name="Propina"
                                    value={pedido.comentarios || ""}
                                    className="textarea input input-modals"
                                    rows="2"
                                    maxLength="100"
                                    readOnly={true}
                                    disabled={true}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className='column full align-center' style={{ padding: 8 }}>
                    <div className="column full justify-center">
                        <div className="row justify-start align-center">
                            <h4>Platos</h4>
                        </div>
                        <table className="full">
                            <thead>
                                <tr style={{ display: "table", tableLayout: "fixed", width: "100%" }}>
                                    <th className="text-left">Código</th>
                                    <th className="text-left" >Plato</th>
                                    <th className="text-left">Cantidad</th>
                                    <th className="text-right">Precio</th>
                                    <th className="text-right">Importe</th>
                                </tr>
                            </thead>
                            <tbody style={{ display: "block", maxHeight: 200, overflow: "auto" }}>
                                {
                                    loadingLineas ?

                                        <tr className='flex justify-center align-center full' style={{ height: 200 }}>
                                            <td>
                                                <i className="fas fa-spinner fa-spin"></i>
                                            </td>
                                        </tr>
                                        :
                                        lineas.map(plato =>
                                            <tr key={plato.id_plato} style={{ display: "table", tableLayout: "fixed", width: "100%" }} >
                                                <td className="text-left">
                                                    <p>{plato.codigo}</p>
                                                </td>
                                                <td className="text-left">
                                                    <p>{plato.plato}</p>
                                                </td>
                                                <td className="text-left">
                                                    <p>{plato.cantidad}</p>
                                                </td>
                                                <td className="text-right">
                                                    <NumberFormat
                                                        value={plato.precio}
                                                        displayType={"text"}
                                                        thousandSeparator={true}
                                                        prefix={"$ "}
                                                        decimalScale={2}
                                                        fixedDecimalScale={true}
                                                    />
                                                </td>
                                                <td className="text-right">
                                                    <NumberFormat
                                                        value={plato.monto}
                                                        displayType={"text"}
                                                        thousandSeparator={true}
                                                        prefix={"$ "}
                                                        decimalScale={2}
                                                        fixedDecimalScale={true}
                                                    />
                                                </td>
                                            </tr>
                                        )}
                            </tbody>
                        </table>
                        <BotonEntrega
                            url={pedido.url_entrega}
                            disabled={estado < 3 || estado > 4}
                            loadingUrl={loadingUrl}
                            onPress={handleGenerateURL}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

const BotonEntrega = (props) => {

    const { url, disabled, loadingUrl, onPress } = props;

    const showUrl = url ? true : false;

    if(showUrl) return <Clipboard url={url} />

    return <button
        name="generarURL"
        type="button"
        className="btn-url"
        disabled={disabled}
        style={{ marginInline: 4, width: "100%" }}
        onClick={() => !loadingUrl && onPress()}
    >
        {loadingUrl ?
            <div className='flex justify-center align-center full'>
                <i className="fas fa-spinner fa-spin"></i>
            </div>
            : 'Generar URL'
        }
    </button>
}

export default PedidosDetail;
