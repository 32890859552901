import { useState } from "react"

export const useMultiLoading = (initLoadingStates) => {

    const [loadingStates, setLoadingStates] = useState(initLoadingStates);

    const setLoading = (prop, value) => {

        const states = Object.assign({}, loadingStates);

        states[prop] = value;

        setLoadingStates(states);
    }

    const setAllStates = (value) => {

        const states = Object.assign({}, loadingStates);

        Object.keys(states).forEach(key => {
            
            states[key] = value;
        });

        setLoadingStates(states);
    }

    return {
        loadingStates,
        setLoading,
        setAllStates
    }
}