
export const toggleClassName = (className, toggle, element) => {
    
    //const element = document.getElementById(id)

    if (element.classList.contains(className) && !toggle) {

        element.classList.remove(className);
        return;
    }

    if (!element.classList.contains(className) && toggle) {

        element.classList.add(className);
        return;
    }
}

export const toggleToBeAdded = (hovered, id) => {

    const element = document.getElementById(id)

    if (element.classList.contains("toBeAdded") && !hovered) {

        element.classList.remove("toBeAdded");
        return;
    }

    if (!element.classList.contains("toBeAdded") && hovered) {

        element.classList.add("toBeAdded");
        return;
    }
}

export const toggleToBeRemoved = (hovered, id) => {

    const element = document.getElementById(id)

    if (element.classList.contains("toBeRemoved") && !hovered) {

        element.classList.remove("toBeRemoved");
        return;
    }

    if (!element.classList.contains("toBeRemoved") && hovered) {

        element.classList.add("toBeRemoved");
        return;
    }
}

export const toggleAbiertoStatus = (checked, id) => {

    const element = document.getElementById(id);

    if (element.classList.contains("abierto") && checked === false) {

        element.classList.remove("abierto");
        return;
    }

    if (!element.classList.contains("abierto") && checked === true) {

        element.classList.add("abierto");
        return;
    }
}

export const toggleActiveStatus = (checked, id) => {

    const element = document.getElementById(id);

    if (element.classList.contains("active") && checked === 0) {
        element.classList.remove("active");
    }

    if (!element.classList.contains("active") && checked === 1) {
        element.classList.add("active");
    }
}