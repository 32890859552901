/**
 *  passwordReset.jsx
 *  @version: 1.0.0
 *  @author: DWIT MÉXICO
 *  @description: Página para restablecer contraseña
 */

import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Consumer } from "../context";
import Request from "../core/httpClient";
import Login from "../img/LogoAppMosph.png";
import moment from 'moment-timezone';
const request = new Request();

class PasswordReset extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      user: {
        idUsuario: 0
      }
    };
  }

  async componentDidMount() {
    let data = { token: this.props.match.params.token };
    const response = await request.post("/restaurantes/checkToken", data);

    if (response && !response.error) {
      if (response.user && !response.empty) {
        // console.log("Response: ", response);
        this.setState({
          user: { idUsuario: response.user.Id_Usuario }
        });
      } else {
        this.setState({
          empty: response.empty,
          loading: false,
          message: response.message
        });
      }
    } else {
      this.setState({
        error: true,
        loading: false,
        message: response.message
      });
    }
  }

  async handleSubmit(event) {
    event.preventDefault();
    this.setState({ loading: true });
    const form = event.target;

    if (form.newPassword.value === form.confirmPassword.value) {
      let data = {
        idUsuario: this.state.user.idUsuario,
        password: form.newPassword.value
      };
      const response = await request.post("/restaurantes/updatePassword", data);

      if (response && !response.error) {
        if (response.user && !response.empty) {
          
          this.props.context.login({ id: response.user, auth: true, fecha: moment().format() });
          this.props.history.push("/restaurantes/configuracion");
        } else {
          this.setState({
            empty: response.empty,
            loading: false,
            message: response.message
          });
        }
      } else {
        this.setState({
          error: true,
          loading: false,
          message: response.message
        });
      }
    }
  }

  render() {
    return (
      <div className="login column justify-center">
        <Helmet>
          <title>AppMosphera - Login</title>
        </Helmet>
        <div className="justify-center content">
          <div className="container align-center justify-center">
            <div className="card-pwd column">
              <div className="responsive-img justify-center">
                <img
                  src={Login}
                  alt={`Logo ${process.env.REACT_APP_NAME}`}
                  title={`Logo ${process.env.REACT_APP_NAME}`}
                  style={{width: "35%"}}
                />
              </div>
              <div className="white-space-8" />
              <h5 className="text-center">
                Crea una contraseña con al menos 8 caracteres
              </h5>
              <div className="white-space-16" />
              <form className="column" onSubmit={this.handleSubmit.bind(this)}>
                <div className="input-container">
                  <input
                    name="newPassword"
                    type="password"
                    maxLength="64"
                    minLength="8"
                    autoComplete="ÑÖcompletes"
                    placeholder="Nueva contraseña"
                    className="weight-semi text-center"
                    required
                  />
                </div>
                <div className="white-space-8" />
                <div className="input-container">
                  <input
                    name="confirmPassword"
                    type="password"
                    maxLength="64"
                    minLength="8"
                    autoComplete="ÑÖcompletes"
                    placeholder="Confirmar contraseña"
                    className="weight-semi text-center"
                    required
                  />
                </div>
                <div className="white-space-16" />
                <div className="btn-container">
                  <button type="submit" className="btn btn-primary color-white">
                    {this.state.loading ? (
                      <i className="fas fa-spinner fa-spin font-text" />
                    ) : (
                      <span className="color-white">CAMBIAR CONTRASEÑA</span>
                    )}
                  </button>
                </div>
                {this.state.message ? (
                  <div className="column">
                    <div className="white-space-8" />
                    <p className="text-center">{this.state.message}</p>
                  </div>
                ) : null}
                <div className="white-space-16" />
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(Consumer(PasswordReset));
