import React, { Component } from "react";
import { Consumer } from '../../../context';
import DinamicModal from '../../modals/dinamicModal/dinamicModal';
import PedidosDetail from './detail';
import NumberFormat from "react-number-format";
import moment from 'moment-timezone';
import Request from '../../../core/httpClient';
import cogoToast from 'cogo-toast';
import ModalCore from "../../modals/core/ModalCore";

// Components
import TableHeadSort from "../../tables/TableHeadSort";

const request = new Request();
const modal = new ModalCore();

const estadoIcons = [
    "fas fa-calendar-alt programado",
    "fas fa-clock pendiente",
    "fas fa-magic activo",
    "fas fa-shipping-fast activo",
    "fas fa-check entregado" ,
    "fas fa-window-close cancelado"
]

const pedidosModalId = 'pedidoDetail';

let values = {};

class PedidosTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isModalOpen:        false,
            id_restaurante:     this.props.id_restaurante,
            id_pedido:          0,
            pedido:             {},
            lineas:             [],
            user:               {}
        }
        this.handleKeyDown = this.handleKeyDown.bind(this);
        this.onSortKeyChange = ({ name }) => this.props.sortBy(name);
    }

    componentDidMount() {
        this.load();
        document.addEventListener('keyup', this.handleKeyDown);
    }

    componentWillUnmount() {
        document.removeEventListener('keyup', this.handleKeyDown);
    }

    handleKeyDown(e) {

        if(e.key === "Escape"){
            this.setState({ isModalOpen: false });
        }
    }

    async load() {
        const user = await this.props.context.loadUser();
        if (user) {
            this.setState({
                user: {
                    id_usuario: user.id.Id_Usuario,
                    id_administracion: user.id.id_restaurante_administracion
                }
            });

        }
    }

    async openModal(idModal, pedido) {
		this.setState({ pedido: pedido, message: null });
        modal.openModal(idModal);
        this.setState({ isModalOpen: true });
        this.getLineas(pedido.id_pedido);
    }

    async getLineas(id_pedido) {
        this.setState({loadingLineas: true, messageLineas: null});
        const res = await request.post('/restaurantes/pedidos/getlineas', { id_pedido });
        if (res.lineas) {
            this.setState({lineas: res.lineas});
        } else {
            this.setState({lineas: []/*, messageLineas: res.message*/});
        }
        this.setState({loadingLineas: false});
    }

    bottomButtonsEnabled() {

        if(!this.state.pedido) return true;
        return this.state.pedido.id_estado - 1 < 3;
    }

    setFechaText(fechaPedido) {

        const otraFecha = moment(fechaPedido);
        const fechaHoy = moment();
        const sameDay = fechaHoy.isSame(otraFecha, "days");

        if(sameDay) return "Hoy " + otraFecha.format("hh:mm a");

        if(fechaHoy.isAfter(otraFecha, "days")) {
            const pastDay = fechaHoy.add(-1, "day");

            return pastDay.isSame(otraFecha, "days") ?
                "Ayer " + otraFecha.format("hh:mm a")
                : otraFecha.format('DD/MM/YYYY hh:mm a');

        } else {
            const nextDay = fechaHoy.add(1, "day");

            return nextDay.isSame(otraFecha, "days") ?
                "Mañana " + otraFecha.format("hh:mm a")
                : otraFecha.format('DD/MM/YYYY hh:mm a');
        }
    }

    render() {
        let pedidos = [];
        if(Array.isArray(this.props.pedidos))
            pedidos = this.props.pedidos;

        const keySort = { name: this.props.filterColumn.col, direction: this.props.filterColumn.filt };
        
        return (
            <div className="card-table card-owners column" id="card-owners">
                <div className="row">
                    <table className="full">
                        <thead className="sticky-th">
                            <tr className="text-left">
                                <th className="text-left">
                                    <TableHeadSort 
                                         title="Fraccionamiento"
                                         orderName="fraccionamiento"
                                         orderKey={keySort}
                                         onOrderKeyChange={this.onSortKeyChange}
                                    />
                                </th>
                                <th className="text-left">
                                    <TableHeadSort 
                                         title="Unidad"
                                         orderName="vivienda"
                                         orderKey={keySort}
                                         onOrderKeyChange={this.onSortKeyChange}
                                    />
                                </th>
                                <th className="text-left">
                                    <TableHeadSort 
                                         title="Restaurante"
                                         orderName="restaurante"
                                         orderKey={keySort}
                                         onOrderKeyChange={this.onSortKeyChange}
                                    />
                                </th>
                                <th className="text-left">
                                    <TableHeadSort 
                                         title="Nombre"
                                         orderName="nombre_residente"
                                         orderKey={keySort}
                                         onOrderKeyChange={this.onSortKeyChange}
                                    />
                                </th>
                                <th className="text-left">
                                    <TableHeadSort 
                                         title="Fecha de Pedido"
                                         orderName="fecha_entrega"
                                         orderKey={keySort}
                                         onOrderKeyChange={this.onSortKeyChange}
                                    />
                                </th>
                                <th className="text-right th-options-small">
                                    <TableHeadSort 
                                         title="Importe"
                                         orderName="total"
                                         orderKey={keySort}
                                         onOrderKeyChange={this.onSortKeyChange}
                                    />
                                </th>
                                <th className="text-center th-options-small">Contactar</th>
                                <th className="text-left">
                                     <TableHeadSort 
                                         title="Estado"
                                         orderName="estado"
                                         orderKey={keySort}
                                         onOrderKeyChange={this.onSortKeyChange}
                                    />
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {pedidos.map((pedido) => (
                                <React.Fragment key={pedido.id_pedido}>
                                    <tr>
                                        <td className="text-left">{`${pedido.fraccionamiento} - ${pedido.subfraccionamiento}`}</td>
                                        <td className="text-left">{pedido.vivienda}</td>
                                        <td className="text-left">{pedido.restaurante}</td>
                                        <td className="text-left">{pedido.nombre_residente}</td>
                                        <td className="text-left">{this.setFechaText(pedido.fecha_orden_programada || pedido.fecha_create)}</td>
                                        <td className="text-right">
                                            <NumberFormat
                                                value={pedido.total}
                                                displayType={"text"}
                                                thousandSeparator={true}
                                                prefix={"$ "}
                                                decimalScale={2}
                                                fixedDecimalScale={true}
                                            />
                                        </td>
                                        <td className="text-left ">
                                            <div className="row align-center justify-center">
                                                <a style={{ display: "flex", justifyContent: "center", alignItems: "center" }} target="_blank" rel="noopener noreferrer" href={"https://wa.me/" + pedido.telefono_residente}>
                                                    <i className="fab fa-whatsapp space-8 " style={{ fontSize: 24, color: "#25D366" }}></i>
                                                </a>
                                            </div>
                                        </td>
                                        <td className="" style={{ padding: 8, textAlign: "center" }}>
                                            <button
                                                type="button"
                                                onClick={this.openModal.bind(this, pedidosModalId, pedido)}
                                            >
                                                <i className={"pedido-estado-cell " + estadoIcons[pedido.id_estado]}></i>
                                            </button>
                                        </td>
                                    </tr>
                                </React.Fragment>
                            ))
                            }
                        </tbody>
                    </table>
                </div>
                <DinamicModal
                    idModal             =   {pedidosModalId}
                    sizeModalContainer  =   {'big'}
                    title               =   {'DETALLE DEL PEDIDO'}
                    success				=	{this.handleEdit.bind(this)}
					showBtnSuccess		=	{this.bottomButtonsEnabled() ? this.props.escritura : false}
					btnTextSuccess		=	{"ACTUALIZAR"}
					showBtnCancel		=	{this.bottomButtonsEnabled()}
					btnTextCancel		=	{"CANCELAR"}
					loadingSave			=	{this.state.loadingSave}
					message				=	{this.state.message}
                    closeModal          =   {() => this.setState({isModalOpen: false})}
                >
                    <PedidosDetail
                        isModalOpen     = {this.state.isModalOpen}
                        pedido          = {this.state.pedido}
                        lineas          = {this.state.lineas}
                        loadingLineas   = {this.state.loadingLineas}
                        getValues       = {this.getValues.bind(this)}
                    />
                </DinamicModal>
            </div>
        )
    }

    getValues(data) {
        values = data;
    }

    async handleEdit(event) {
        event.preventDefault();
        const data = {
            pedido: values,
            user: this.state.user
        }
        this.setState({loadingSave: true, message: null});
        const res = await request.post('/restaurantes/pedidos/update', data);
        if (res.updated) {
            cogoToast.success('Datos de pedido actualizados.', {
                position: 'bottom-right',
            });
        } else {
            //this.setState({message: res.message || 'No se pudo actualizar el restaurante.'});
            cogoToast.error('No se pudieron actualizar datos de pedido.', {
                position: 'bottom-right',
            });
        }
        this.setState({loadingSave: false});

        if (res.updated) this.props.reload();
    }
}

export default Consumer(PedidosTable);
