import moment from "moment";

export const checkValidDay = (fechaHoy, horarioHoy, diasCierre) => {

    const checkActivoHoy = () => {
        return horarioHoy.activo;
    }

    const checkIsDiasCierre = () => {

        for (let index = 0; index < diasCierre.length; index++) {
            const diaCierreMoment = diasCierre[index].dia_cierre;
            if (fechaHoy.isSame(diaCierreMoment, "d")) {
                return { diaCierre: diasCierre[index].descripcion };
            }
        }

        return "";
    }

    if(!checkActivoHoy()) return { open: false, descripcion: "Cerrado" };

    const diaCierre = checkIsDiasCierre();

    if(diaCierre) return { open: false, descripcion: "Dia festivo" };

    return { open: true };
};

export const checkValidTime = (daySelected, date, time) => {

    if (time.format("YYYY-MM-DD HH:mm:ss") < moment().format('YYYY-MM-DD HH:mm:00')) {
        return "La fecha debe ser mayor que la actual" ;
    }

    if(!time.isBetween(moment(`${date} ${daySelected.desde}`, 'YYYY-MM-DD HH:mm'), moment(`${date} ${daySelected.hasta}`, 'YYYY-MM-DD HH:mm'), "minutes", "[]")){
        return "La hora esta fuera del horario del restaurante"
    }

    return "Valida";
};

export const getHorariosDisabled = (horarios, date = moment()) => {

    const isHoraBetweenPlatosHorarios = (horario, date) => {
        const initDate = moment(horario.hora_inicio, "HH:mm");
        const endDate = moment(horario.hora_fin, "HH:mm");
        const isBetween = date.isBetween(moment(initDate, "HH:mm"), moment(endDate, "HH:mm"), "minutes", "[)");
        return isBetween;
    };

    return horarios.reduce((prev, current) => {
        return { ...prev, [current.id]: !isHoraBetweenPlatosHorarios(current, date) };
    }, {});
};

export const checkOrdenBetweenPlatosHorarios = (orden, horarios, horaProgramada) => {

    const horariosDisabled = getHorariosDisabled(horarios, horaProgramada);

    return orden.every(plato => !horariosDisabled[plato.id_horario]);
};