import Request from "../../../core/httpClient";

const request = new Request();

export const setPedido = async (pedido) => {
    try {
        const data = { ...pedido };

        const response = await request.post("/restaurantes/pedido/create", data);

        if (!response) return { error: "Sin conexión " };

        if (response.error) return { error: response.error };

        return { created: response.created };

    } catch (error) {
        console.log(error);
        return { error };
    }
};

export const getPlatos = async (id_restaurante) => {
    try {
        const res = await request.post('/restaurantes/platos/get', { id_restaurante });

        if (res.platos) {
            return { platos: res.platos };
        } else {
            return { platos: [] };
        }
    } catch (error) {
        console.log(error);
        return { error };
    }
}

export const getSubfraccionamientos = async (Idsub) => {
    try {
        const data = { Idsub , filter: 1 }
        const response = await request.post("/admin/administracion/get/subfraccionamientos", data);

        if (!response || response.error) return { error: true, message: response ? response.message : "Error de conexión" };

        if (!response.subfraccionamiento || response.empty) return { empty: true, message: response.message, subfraccionamientos: [] };

        return { 
            subfraccionamientos: response.subfraccionamiento, 
            filterData: response.subfraccionamiento
        };

    } catch (error) {
        console.log(error);
        return { error };
    }
};

export const getInquilinos = async (idFrac, IdSub) => {
    try {
        const data = { idFrac, IdSub };

        const response = await request.post("/inquilinos/get/inquilinos", data);

        if(!response || response.error) return { error: true, message: response.message || "Error de conexión" };

        if (!response.inquilinos || response.empty) return { empty: true, message: response.message, inquilinos: [] };

        return { inquilinos: response.inquilinos };

    } catch (error) {
        console.log(error);
        return { error };
    }
};