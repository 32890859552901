// React and third-parties
import React, { useEffect, } from 'react';
import TimeField from 'react-simple-timefield';
import NumberFormat from "react-number-format";

// Components
import SuperModal from '../../../../components/modals/superModal/SuperModal';

// Hooks
import { usePedidoInfo, usePlatosInfo } from '../../../../hooks/restaurantes/pedidos/newPedidoHooks';

const tableWidths = {
    codigo: { width: "15%" },
    plato: { flex: 1 },
    precio: { width: "15%" },
    importe: { width: "20%" },
    cantidad: { width: "20%" },
    horario: { width: "20%" }
};

const tableMessages = {
    orden: {
        noOrder: "Escoge algun platillo"
    },
    platos: {
        noSelect: "Escoge algun restaurante",
        selectedEmpty: "Restaurante sin platillos"
    }
};

export default function NuevoPedidoModal(props) {

    const {
        idModal, getUser, isModalOpen, onCloseModal,
        fraccionamiento, restaurantes, onSuccesfulSubmit
    } = props;

    const {
        pedido, subfraccionamientos, inquilinos,
        loadingSetPedido, loadingSubfraccionamientos, loadingInquilinos,
        setRestauranteIndex, setSubfraccionamientoIndex, setInquilinoIndex,
        setProgramado, setHora, setMetodoPago, setComentario, addPlato, removePlato,
        submitPedido, resetPedido
    } = usePedidoInfo();

    const { metodos_pago, categorias, horarios } = pedido.restauranteIndex !== -1 ?
        restaurantes[pedido.restauranteIndex]
        : { categorias: [], metodos_pago: [], horarios: [] };

    useEffect(() => {

        if (isModalOpen) {
            resetPedido();
        }

    }, [isModalOpen, resetPedido]);

    const menuScreens = [
        {
            title: "Orden",
            component: (
                <OrdenForm
                    key={pedido.restauranteIndex}
                    pedido={pedido}
                    restaurantes={restaurantes}
                    categorias={categorias}
                    horarios={horarios}
                    onRestauranteIndexChange={setRestauranteIndex}
                    onComentarioChange={setComentario}
                    onAddPlato={addPlato}
                    onRemovePlato={removePlato}
                />
            )
        },
        {
            title: "Residente",
            component: (
                <ResidenteForm
                    fraccionamiento={fraccionamiento}
                    pedido={pedido}
                    subfraccionamientos={subfraccionamientos}
                    inquilinos={inquilinos}
                    metodos_pago={metodos_pago}
                    loadingSubfraccionamientos={loadingSubfraccionamientos}
                    loadingInquilinos={loadingInquilinos}
                    onSubfraccionamientoChange={setSubfraccionamientoIndex}
                    onInquilinoChange={setInquilinoIndex}
                    onProgramadoChange={setProgramado}
                    onHoraChange={setHora}
                    onMetodoPagoChange={setMetodoPago}
                />
            )
        }
    ];

    const handleSubmit = async (e) => {

        const user = await getUser();
        const { Id_Usuario, id_restaurante_administracion } = user.id;
        const created = await submitPedido(restaurantes, Id_Usuario, id_restaurante_administracion);

        if (created) onSuccesfulSubmit();

        return created;
    }

    return (
        <SuperModal
            id={idModal}
            size={"big"}
            title={"Nuevo Pedido"}
            menuScreens={menuScreens}
            openModal={isModalOpen}

            onCloseModal={onCloseModal}
            loading={loadingSetPedido}
            submitProps={SuperModal.SetFooterSubmitProps({ show: true, label: "ORDENAR", actionCheck: handleSubmit })}
            cancelProps={SuperModal.SetFooterCancelProps({ show: true, label: 'CANCELAR' })}
            nextProps={SuperModal.SetFooterNextProps({ show: true, label: "SIGUIENTE" })}
            previousProps={SuperModal.SetFooterPreviousProps({ show: true, label: "REGRESAR" })}
        />
    )
};

const ResidenteForm = (props) => {

    const {
        fraccionamiento, pedido, subfraccionamientos, inquilinos, metodos_pago,
        loadingInquilinos, loadingSubfraccionamientos,
        onSubfraccionamientoChange, onInquilinoChange,
        onProgramadoChange, onHoraChange, onMetodoPagoChange
    } = props;

    const { subfraccionamientoIndex, inquilinoIndex, restauranteIndex,
        programado, fechaHora, metodoPago } = pedido;

    return (
        <div className='newPedidoForm column full justify-start align-start' style={{ paddingTop: 8, marginBottom: 8, gap: 16 }}>
            <div className="row justify-start align-center" style={{ gap: 8 }}>
                <p><b>Fraccionamiento:</b></p>
                <input
                    name="fraccionamiento"
                    className="input input-modals"
                    value={fraccionamiento}
                    disabled
                    readOnly
                />
            </div>
            <div className="row justify-start align-center" style={{ gap: 8 }}>
                <p><b>Subfraccionamiento:</b></p>
                <select
                    name="id_subfraccionamiento"
                    className="input input-modals"
                    value={subfraccionamientoIndex}
                    disabled={loadingSubfraccionamientos}
                    onChange={(e) => onSubfraccionamientoChange(+e.target.value)}
                >
                    <option value="-1">Ninguno</option>
                    {subfraccionamientos.map((sub, index) =>
                        <option
                            key={sub.id_subfraccionamiento}
                            value={index}
                            disabled={!sub.activo}>{sub.nombre}
                        </option>
                    )}
                </select>
            </div>
            <div className="row justify-start align-center" style={{ gap: 8 }}>
                <p><b>Residente:</b></p>
                <select
                    name="id_inquilino"
                    className="input input-modals"
                    value={inquilinoIndex}
                    disabled={loadingInquilinos || subfraccionamientoIndex === -1 || loadingSubfraccionamientos}
                    onChange={(e) => onInquilinoChange(+e.target.value)}
                >
                    <option value="-1">Ninguno</option>
                    {inquilinos.map((inquilino, index) =>
                        <option
                            key={inquilino.id_inquilino}
                            value={index}>
                            {inquilino.nombre + " - " + inquilino.numero_registro}
                        </option>
                    )}
                </select>
            </div>
            <div className="row justify-start align-center" style={{ gap: 8 }}>
                <p><b>Telefono:</b></p>
                <input
                    name="telefono"
                    className="input input-modals"
                    value={inquilinoIndex !== -1 ? inquilinos[inquilinoIndex].telefono : ""}
                    disabled
                    readOnly
                />
            </div>
            <div className="row justify-start align-center" style={{ gap: 8 }}>
                <p style={{ whiteSpace: "nowrap" }}><b>Programar Pedido:</b></p>
                <label className="container-check">
                    <input
                        type="checkbox"
                        name="Activo"
                        disabled={false}
                        checked={programado}
                        onClick={(event) => onProgramadoChange(event.target.checked ? 1 : 0)}
                        readOnly
                    />
                    <span className="checkmark" ></span>
                </label>
                <p><b>Hora:</b></p>
                <TimeField
                    value={fechaHora}
                    onChange={(event) => onHoraChange(event.target.value)}
                    input={<input className="input input-modals" disabled={!programado}></input>}
                />
            </div>
            <div className="row justify-center align-center" style={{ gap: 8, paddingBottom: 8 }}>
                <p style={{ whiteSpace: "nowrap" }}><b>Metodo de pago:</b></p>
                <select
                    name="categoria"
                    className="input input-modals"
                    value={metodoPago}
                    disabled={restauranteIndex === -1}
                    onChange={(e) => onMetodoPagoChange(+e.target.value)}
                >
                    <option value="0">Ninguno</option>
                    {
                        metodos_pago.map(metodo =>
                            metodo.id_metodo_pago !== 3 &&
                            <option
                                key={metodo.id_metodo_pago}
                                value={metodo.id_metodo_pago}
                            >
                                {metodo.metodo_pago}
                            </option>
                        )
                    }
                </select>
            </div>
        </div>
    )
};

const OrdenForm = (props) => {

    const {
        restaurantes, pedido, categorias, horarios,
        onRestauranteIndexChange, onAddPlato, onRemovePlato, onComentarioChange
    } = props;

    const { restauranteIndex, lineas, comentario } = pedido;

    const { platos, loadingPlatos, categoria, horario, horariosDisabled, setCategoria, setHorario } = usePlatosInfo(restaurantes, restauranteIndex);

    return (
        <div className='newPedidoForm column full justify-start align-start' style={{ paddingTop: 8, marginBottom: 8, gap: 16 }}>
            <div className="row justify-start align-center" style={{ gap: 8 }}>
                <p><b>Restaurante:</b></p>
                <select
                    name="id_restaurante"
                    className="input input-modals"
                    value={restauranteIndex}
                    disabled={loadingPlatos || lineas.length > 0}
                    onChange={(e) => onRestauranteIndexChange(+e.target.value)}
                >
                    <option value="-1">Ninguno</option>
                    {restaurantes.map((res, index) =>
                        <option
                            key={res.id_restaurante}
                            disabled={res.cerrado}
                            value={index}>
                            {res.nombre + (res.cerrado ? " - " + res.cerrado : "")}
                        </option>
                    )}
                </select>
            </div>
            <div className="row justify-start align-center" style={{ gap: 8 }}>
                <p><b>Categoria:</b></p>
                <select
                    name="categoria"
                    className="input input-modals"
                    value={categoria}
                    disabled={restauranteIndex === -1}
                    onChange={(e) => setCategoria(+e.target.value)}
                >
                    <option value="0">{restauranteIndex !== -1 ? "Todas" : ""}</option>
                    {categorias.map((categoria, index) =>
                        <option
                            key={categoria.id}
                            disabled={!categoria.id_status}
                            value={categoria.id}>
                            {categoria.categoria}
                        </option>
                    )}
                </select>
            </div>
            <div className="row justify-start align-center" style={{ gap: 8 }}>
                <p><b>Horarios:</b></p>
                <select
                    name="horario"
                    className="input input-modals"
                    value={horario.index}
                    disabled={restauranteIndex === -1}
                    onChange={(e) => setHorario(e.target.value != -1 ?
                        horarios[+e.target.value]
                        : { index: -1, id: 0, hora_inicio: "", hora_fin: "" })
                    }
                >
                    <option value="-1">{restauranteIndex !== -1 ? "Todos" : ""}</option>
                    {horarios.map((hor, index) =>
                        <option
                            key={hor.id}
                            disabled={horariosDisabled[hor.id]}
                            value={index}>
                            {hor.descripcion}
                        </option>
                    )}
                </select>
                <p><b>Desde:</b></p>
                <input
                    name="horarioDesde"
                    className="input input-modals"
                    style={{ height: "100%", width: 65 }}
                    value={restauranteIndex !== -1 ? horario.hora_inicio : "--:--"}
                    readOnly
                />
                <p><b>Hasta:</b></p>
                <input
                    name="horarioDesde"
                    className="input input-modals"
                    style={{ height: "100%", width: 65 }}
                    value={restauranteIndex !== -1 ? horario.hora_fin : "--:--"}
                    readOnly
                />
            </div>
            <PlatosOrderSubTable
                title={"Platos"}
                type={"platos"}
                lineas={platos}
                loading={loadingPlatos}
                hasSelectedRestaurant={restauranteIndex !== -1}
                onAddPlato={(plato) => onAddPlato(plato)}
            />
            <PlatosOrderSubTable
                title={"Orden"}
                type={"orden"}
                lineas={lineas}
                total={pedido.total}
                hasSelectedRestaurant={restauranteIndex !== -1}
                onAddPlato={onAddPlato}
                onRemovePlato={onRemovePlato}
            />
            <div className="row justify-center align-start" style={{ gap: 8, paddingBottom: 8 }}>
                <p style={{ whiteSpace: "nowrap" }}><b>Comentarios:</b></p>
                <textarea
                    name="comentario"
                    className="textarea input input-modals text-big"
                    value={comentario}
                    style={{ height: 60 }}
                    maxLength={250}
                    onChange={(e) => onComentarioChange(e.target.value)}
                />
            </div>
        </div>
    )
};

const PlatosOrderSubTable = (props) => {

    const { title, lineas, type, total, loading, hasSelectedRestaurant, onAddPlato, onRemovePlato } = props;

    const getEmptyBodyMessage = () => {

        if (type === "orden") return tableMessages.orden.noOrder;

        return hasSelectedRestaurant ?
            tableMessages.platos.selectedEmpty :
            tableMessages.platos.noSelect;
    };

    const LoadingBody = <tr className='flex full align-center justify-center' style={{ height: 67 }}>
        <td colSpan={type === "orden" ? 5 : 4} className="column justify-center align-center">
            <i className="fas fa-spinner fa-spin"></i>
        </td>
    </tr>;

    const EmptyBody = <tr className='flex align-center justify-center' style={{ flex: 1, opacity: 0.6 }}>
        <td colSpan={type === "orden" ? 5 : 4} className="column align-center">
            <div className='white-space-8'></div>
            <i className="fas fa-utensils color-dark" style={{ fontSize: 24 }}></i>
            <div className='white-space-8'></div>
            <h5>
                {getEmptyBodyMessage()}
            </h5>
            {loading &&
                <div className="row full justify-center align-center" style={{ position: "absolute", height: "100%", backgroundColor: "#FFF", zIndex: 1 }}>
                    <i className="fas fa-spinner fa-spin"></i>
                </div>
            }
        </td>
    </tr>;

    const ContentBody = lineas.map((plato, index) =>
    (<tr key={plato.id_plato} style={{ display: "table", width: "100%" }} >
        <td className="text-left" style={tableWidths.codigo}>
            <p>{plato.codigo}</p>
        </td>
        <td className="text-left" style={tableWidths.plato}>
            <p>{plato.plato || plato.nombre}</p>
        </td>
        {type === "orden" ?
            <td className="text-center" style={{ width: tableWidths.cantidad.width, order: type === "orden" ? 2 : "auto" }}>
                <div className='row' style={{ justifyContent: "space-evenly", paddingBlock: 8 }}>
                    <button className='flex justify-center' type="button" onClick={() => onAddPlato(plato, index)}><i className="fas fa-plus color-green" style={{ fontSize: 18 }}></i></button>
                    <p style={{ fontSize: 18 }}>{plato.cantidad}</p>
                    <button className='flex justify-center' type="button" onClick={() => onRemovePlato(plato, index)}><i className="fas fa-minus color-red" style={{ fontSize: 18 }}></i></button>
                </div>
            </td>
            :
            <td className="text-center" style={{ width: tableWidths.cantidad.width, order: 2 }}>
                <p>{plato.horario_nombre}</p>
            </td>
        }
        <td className="text-right" style={tableWidths.precio}>
            <NumberFormat
                value={plato.precio}
                displayType={"text"}
                thousandSeparator={true}
                prefix={"$ "}
                decimalScale={2}
                fixedDecimalScale={true}
            />
        </td>
        {type === "orden" &&
            <td className="text-right" style={tableWidths.importe}>
                <NumberFormat
                    value={plato.importe}
                    displayType={"text"}
                    thousandSeparator={true}
                    prefix={"$ "}
                    decimalScale={2}
                    fixedDecimalScale={true}
                />
            </td>
        }
        {type === "platos" &&
            <td className="text-center" style={{ width: tableWidths.cantidad.width, order: type === "orden" ? 2 : "auto" }}>
                <div className='row' style={{ justifyContent: "space-evenly", paddingBlock: 8 }}>
                    <button className='flex justify-center' type="button" onClick={() => onAddPlato(plato, index)}><i className="fas fa-plus color-green" style={{ fontSize: 18 }}></i></button>
                </div>
            </td>
        }
    </tr>)
    );

    return (
        <div className="column full justify-center card-table">
            <div className="row justify-start align-center">
                <h4>{title}</h4>
            </div>
            <table className="full">
                <thead>
                    <tr style={{ display: "table", width: "100%" }}>
                        <th className="text-left" style={tableWidths.codigo}>
                            Código
                        </th>
                        <th className="text-left" style={tableWidths.plato}>
                            Plato
                        </th>
                        {type === "orden" ?
                            <th className="text-center" style={tableWidths.cantidad}>
                                Cantidad
                            </th>
                            :
                            <th className="text-center" style={tableWidths.horario}>
                                Horario
                            </th>
                        }
                        <th className="text-right" style={tableWidths.precio}>
                            Precio
                        </th>
                        {type === "orden" &&
                            <th className="text-right" style={tableWidths.importe}>
                                Importe
                            </th>
                        }
                        {type === "platos" &&
                            <th className="text-center" style={tableWidths.cantidad}>
                                Agregar
                            </th>
                        }
                    </tr>
                </thead>
                <tbody style={{ display: "block", maxHeight: 200, overflow: "auto" }}>
                    {!loading ?
                        lineas.length > 0 ?
                            ContentBody
                            :
                            EmptyBody
                        :
                        LoadingBody
                    }
                </tbody>
            </table>
            {total !== undefined &&
                <div className="row justify-end align-center" style={{ borderTop: "1px solid #6c757d55", gap: 2 }}>
                    <p>Total: </p>
                    <NumberFormat
                        value={total}
                        displayType={"text"}
                        thousandSeparator={true}
                        prefix={"$ "}
                        decimalScale={2}
                        fixedDecimalScale={true}
                    />
                </div>
            }
        </div>
    )
};
