// React and third-parties
import React, { useState, useEffect, useCallback } from "react";
import moment from "moment";
import cogoToast from 'cogo-toast';

// Validations
import { 
    checkValidDay, checkValidTime, checkOrdenBetweenPlatosHorarios, 
    getHorariosDisabled 
} from "../../../pages/restaurantes/pedidos/validations";

// Helpers
import { minutosPreviosAProcesamiento } from "../../../pages/restaurantes/pedidos/programadosHelper";

// Requests
import { setPedido as createPedido, getPlatos, getSubfraccionamientos, getInquilinos } from "../../../pages/restaurantes/pedidos/requests";

const minuteList = [
    { label: "00", value: "00" },
    { label: "15", value: "15" },
    { label: "30", value: "30" },
    { label: "45", value: "45" }
];

const getTimeOffset = () => {

    let dateCheck = moment().add(0, "minutes").add(0, "hours");
    const currenMinutesOffset = dateCheck.clone().add(4, "minutes");

    for (let index = minuteList.length - 1; index > -1; index--) {

        const minutes = parseInt(minuteList[index].value);
        
        if(currenMinutesOffset.minutes() >= minutes){
            dateCheck = currenMinutesOffset.startOf("hour").add(minutes + 15, "minutes");
            break;
        }
    }

    return dateCheck
};

const setObjectState = (key, value) => (prevValues) => { return { ...prevValues, [key]: value }};

const pedidoInit = { 
    restauranteIndex: -1,
    subfraccionamientoIndex: -1,
    inquilinoIndex: -1,
    programado: 0,
    metodoPago: 0,
    fechaHora: getTimeOffset().format("HH:mm"),
    comentario: "",
    lineas: [], 
    total: 0 
};

export const usePedidoInfo = () => {

    const [pedido, setPedido] = useState(pedidoInit);
    const [subfraccionamientos, setSubfraccionamientos] = useState([]);
    const [inquilinos, setInquilinos] = useState([]);

    const [loadingSetPedido, setLoadingSetPedido] = useState(false);
    const [loadingSubfraccionamientos, setLoadingSubfraccionamientos] = useState(false);
    const [loadingInquilinos, setLoadingInquilinos] = useState(false);

    const setRestauranteIndex = useCallback((index) => setPedido(setObjectState('restauranteIndex', index)), []);
    const setSubfraccionamientoIndex = useCallback((index) => setPedido(setObjectState('subfraccionamientoIndex', index)), []);
    const setInquilinoIndex =  useCallback((index) => setPedido(setObjectState('inquilinoIndex', index)), []);
    const setMetodoPago = useCallback((value) => setPedido(setObjectState('metodoPago', value)), []);
    const setProgramado = useCallback((value) => setPedido(setObjectState('programado', value)), []);
    const setHora = useCallback((value) => setPedido(setObjectState('fechaHora', value)), []);
    const setComentario = useCallback((value) => setPedido(setObjectState('comentario', value)), []);
    const resetPedido = useCallback(() => setPedido({ ...pedidoInit, fechaHora: getTimeOffset().format("HH:mm") }), []);

    const loadInquilinos = useCallback(async (IdSub) => {

        const fraccionamiento = localStorage.getItem('frac');

		if(!fraccionamiento) {
			return;
		}

		setLoadingInquilinos(true);

        const response = await getInquilinos(fraccionamiento, IdSub);

        if(response.error) {
            cogoToast.warn(response.message, {
                position: 'bottom-right',
            });
            return;    
        }

        if(response.inquilinos) {

            if (response.message) {
                cogoToast.warn(response.message, {
                    position: 'bottom-right',
                });
            }

            if(response.inquilinos.length === 1) {
                setInquilinoIndex(0);
            }

            setInquilinos(response.inquilinos);
        }

        setLoadingInquilinos(false);

	}, [setInquilinoIndex]);

    const loadSubfraccionamientos = useCallback(async () => {

        const fraccionamiento = localStorage.getItem('frac');

		if(!fraccionamiento) {
			return;
		}

        setLoadingSubfraccionamientos(true);

       const response = await getSubfraccionamientos(fraccionamiento); 
        
        if(response.error) {
            cogoToast.warn(response.message, {
                position: 'bottom-right',
            });
            return;    
        }

        if(response.subfraccionamientos) {

            if (response.message) {
                cogoToast.warn(response.message, {
                    position: 'bottom-right',
                });
            }

            if(response.subfraccionamientos.length === 1) {
                setSubfraccionamientoIndex(0);
            }

            setSubfraccionamientos(response.subfraccionamientos);
        }

        setLoadingSubfraccionamientos(false);

    }, [setSubfraccionamientoIndex]);

    const loadSetPedido = async (restaurantes, id_usuario, id_administracion) => {

        const { 
            error: submitError, 
            pedidoFormat 
        } = submitValidation(pedido, inquilinos, restaurantes, id_usuario, id_administracion);

        if(submitError) {
            cogoToast.warn(submitError, {
                position: 'bottom-right',
            });
            return;
        }
        
        setLoadingSetPedido(true);

        const { created, error: createError } = await createPedido(pedidoFormat);

        if (createError) {
            cogoToast.error('No se pudo agregar pedido. ' + createError, {
                position: 'bottom-right',
            });
        }

        if (created) {
            cogoToast.success('Pedido agregado.', {
                position: 'bottom-right',
            });
        }

        setLoadingSetPedido(false);

        return created;
    };

    const changeSubfraccionamiento = useCallback((index) => {

        setPedido(prevValues => {
            return {
                ...prevValues,
                subfraccionamientoIndex: index,
                inquilinoIndex: pedidoInit.inquilinoIndex
            }
        });

        if(index !== -1) loadInquilinos(subfraccionamientos[index].id_subfraccionamiento);

    }, [subfraccionamientos, loadInquilinos, setPedido]);

    const addPlato = (plato, index) => {

        const { id_plato, id_horario, importe, precio, cantidad, codigo, nombre } = plato;
        const filteredPlato = { id_plato, id_horario, importe, precio, cantidad, codigo, nombre };

        const platoFoundIndex = index || pedido.lineas.findIndex(linea => linea.id_plato === filteredPlato.id_plato);
        const selectedPlato = platoFoundIndex !== -1 ? pedido.lineas[platoFoundIndex] : filteredPlato;

        const newLinea = platoFoundIndex !== -1 ?
            { ...selectedPlato, cantidad: selectedPlato.cantidad + 1, importe: selectedPlato.importe + selectedPlato.precio }
            : { ...selectedPlato, cantidad: 1, importe: selectedPlato.precio };

        const newTotal = pedido.total + filteredPlato.precio;

        const newLineas = [...pedido.lineas];

        if (platoFoundIndex !== -1) {
            
            newLineas[platoFoundIndex] = newLinea;

        } else {
            newLineas.push(newLinea);
        }

        setPedido(prevValues => { return { ...prevValues, lineas: newLineas, total: newTotal }});
    };

    const removePlato = (plato, index) => {

        let newLineas;
        let newTotal;

        if(plato.cantidad === 1) {

            newLineas = [...pedido.lineas].filter(linea => linea.id_plato !== plato.id_plato);
            newTotal = pedido.total - plato.precio;

        } else {

            const newLinea = { ...plato, cantidad: plato.cantidad - 1, importe: plato.importe - plato.precio };
            newLineas = [...pedido.lineas];
            newLineas[index] = newLinea;

            newTotal = pedido.total - plato.precio;
        }

        setPedido(prevValues => { return { ...prevValues, lineas: newLineas, total: newTotal }});
    };

    useEffect(() => {
      loadSubfraccionamientos();
    }, [loadSubfraccionamientos]);

    return {
        pedido,
        subfraccionamientos,
        inquilinos,
        loadingSetPedido,
        loadingSubfraccionamientos,
        loadingInquilinos,
        addPlato,
        removePlato,
        setRestauranteIndex,
        setSubfraccionamientoIndex: changeSubfraccionamiento,
        setInquilinoIndex,
        setMetodoPago,
        setProgramado,
        setHora,
        setComentario,
        submitPedido: loadSetPedido,
        resetPedido,
    }
};

export const usePlatosInfo = (restaurantes, restauranteIndex) => {

    const [categoria, setCategoria] = useState(0);
    const [horario, setHorario] = useState({ index: -1, id: 0, descripcion: "", hora_inicio: "", hora_fin: "" });
    const [horariosDisabled, setHorariosDisabled] = useState({});
    const [platos, setPlatos] = useState([]);
    const [loadingPlatos, setLoadingPlatos] = useState(false);

    const applyPlatosFilters = () => {

        let filters = [];
        if(categoria !== 0) filters.push(plato => plato.id_categoria === categoria);
        if(horario.index !== -1) filters.push(plato => plato.id_horario === horario.id);

        return filters.length > 0 ?
            platos.filter(plato => filters.every(filter => filter(plato)))
            : platos;
    };

    const loadPlatos = useCallback(async ({ id_restaurante, horarios }, horariosDisabled) => {

        setLoadingPlatos(true);

        const { platos, error } = await getPlatos(id_restaurante);

        if(error) {
            cogoToast.error('No se pudieron cargar platos.', {
                position: 'bottom-right',
            });
        }

        if(platos) {
            const filterInactivos =
                platos.filter(plato => plato.activo && !horariosDisabled[plato.id_horario])
                    .map(plato => { return { 
                        ...plato, 
                        horario_nombre: horarios.find(hor => hor.id === plato.id_horario).descripcion 
                    }});
            setPlatos(filterInactivos);
        }

        setLoadingPlatos(false);
    }, []);

    useEffect(() => {
        if(restauranteIndex !== -1) {
            const newHorariosDisabled = getHorariosDisabled(restaurantes[restauranteIndex].horarios);
            setHorariosDisabled(newHorariosDisabled)
            loadPlatos(restaurantes[restauranteIndex], newHorariosDisabled);
        }
    }, [restauranteIndex, restaurantes, loadPlatos]);

    return {
        platos: applyPlatosFilters(),
        loadingPlatos,
        categoria,
        horario,
        horariosDisabled,
        setCategoria,
        setHorario,
    }
};

const submitValidation = (pedido, inquilinos, restaurantes, id_usuario, id_administracion) => {

    const { restauranteIndex, inquilinoIndex, total,
        lineas, programado, fechaHora, metodoPago, comentario } = pedido;

    if (inquilinoIndex === -1) return { error: "Seleccionar residente. " };
   
    if (restauranteIndex === -1) return { error: "Seleccionar restaurante. " };

    if (lineas.length === 0) return { error: "Seleccionar platillo(s). " };

    const { horario_semanal, horarios, dias_cierre, id_restaurante } = restaurantes[restauranteIndex];

    const validOrden = checkOrdenBetweenPlatosHorarios(pedido.lineas, horarios, 
        programado ? moment(fechaHora, "HH:mm").add(-minutosPreviosAProcesamiento, "minutes") : moment());

    if(!validOrden) return { error: "Hay platillos seleccionados fuera de su horario" };

    if(horario_semanal.length === 0) return { error: "Restaurante no ha establecido horarios." };

    let today = moment().day();
    today = today === 0 ? 6 : today - 1;

    const horarioHoy = horario_semanal[today];

    const timeToCheck = programado ? moment(fechaHora, "HH:mm") : getTimeOffset();
    const FullDate = moment().format('YYYY-MM-DD');
    const completeDate = moment(`${FullDate} ${timeToCheck.format("hh")}:${timeToCheck.format("mm")}:00 ${timeToCheck.format("A")}`, "YYYY-MM-DD hh:mm:ss A");

    const validDay = checkValidDay(timeToCheck, horarioHoy, dias_cierre);

    if(!validDay.open) return { error: "Restaurante cerro." };

    const validTime = checkValidTime(horarioHoy, FullDate, completeDate);

    if (validTime !== "Valida") return { error: "Fecha Invalida. " };

    if (metodoPago === 0) return { error: "Seleccionar metodo de pago. " };

    return {
        pedidoFormat: {
            id_restaurante,
            id_administracion,
            id_usuario,
            comentario,
            programado,
            pedido: JSON.stringify(lineas),
            idInquilino: inquilinos[inquilinoIndex].id_inquilino,
            fecha: completeDate.format("YYYY-MM-DD HH:mm:ss"),
            importe: total,
            id_metodo_pago: metodoPago
        }
    }
}