import moment from "moment";
import cogoToast from "cogo-toast";

let workerHandle = null;

export const minutosPreviosAProcesamiento = 30;
const workerPath = "/PedidosPendientesWorker.js";

const playSound = (url) => {
    const audio = new Audio(url);
    audio.play();
};

const startWorker = (time, onTimerDone) => {
    if (typeof(Worker) === "undefined") return;

    stopWorker();
    
    workerHandle = new Worker(workerPath);

    workerHandle.addEventListener("message", (e) => {
        const { event } = e.data;

        if(event && event === "stop") {
            onTimerDone();
            stopWorker();
        }
    });

    workerHandle.addEventListener("error", (ev) => {
        console.log("Worker error event data: ", ev);
        workerHandle = null;
    });

    //console.log("Starting worker for " + time + " mins");

    workerHandle.postMessage({ event: "start", time: time * 60000 });
}

const stopWorker = () => {
    if(workerHandle) {
        workerHandle.terminate();
        workerHandle = null;
    };
};

const setAndGetPedidosProgramados = (pedidos) => {

    let programadosCount = 0;

    if (pedidos[0].fecha_orden_programada) {
        for (let index = 0; index < pedidos.length; index++) {

            if (pedidos[index].fecha_orden_programada === null) break;
            programadosCount = index + 1;
        }
    }

    for (let index = programadosCount; index < pedidos.length; index++) {
        const pedido = pedidos[index];
        
        if (pedido.fecha_orden_programada !== null && pedido.id_estado === 0) {
            pedido.id_estado = 1;
        }
    }

    return programadosCount !== -1 ? pedidos.slice(0, programadosCount) : [];
};

export const checkProgramados = (pedidos, onTimerDone = undefined) => {

    const programados = setAndGetPedidosProgramados(pedidos);
    const programadosPending = JSON.parse(localStorage.getItem("PedidosProgramadosPendientes") || "[]");
    const newProgramadosPending = [];
    const programadosNotified = [];
    let firstProgramadoPendienteInfo = { fechaDiff: undefined, index: -1 };

    /* Activar sonido y toast de notificacion */
    const notifyNewPendiente = () => {

        try {
            playSound("https://cdn.freesound.org/previews/616/616698_12364629-lq.mp3");
        } catch (error) {
            console.log(error);
        }

        cogoToast.info('Pedido programado esta listo para procesar.', { hideAfter: 8 });
    }

    /* Notificar Programados apenas listos a procesar
        y/o guarda la informacion del siguiente Programado para activar el background timer */
    const programadosNotifyAndSetTimerInfo = () => {

        for (let index = programados.length - 1; index > -1; index--) {
            const pedido = programados[index];
            const fechaHoy = moment().add(0, "days").add(0, "hours");
            const fechaProgramada = moment(pedido.fecha_orden_programada);
            const fechaDiff = fechaProgramada.diff(fechaHoy, "minutes");

            if (pedido.id_estado !== 0) continue;

            if (fechaDiff <= minutosPreviosAProcesamiento) {
                pedido.id_estado = 1;

                if (onTimerDone && programadosPending.includes(pedido.id_pedido)) {
                    programadosNotified.push(pedido.id_pedido);
                    notifyNewPendiente();
                }

            } else if (onTimerDone) {

                if (firstProgramadoPendienteInfo.index === -1)
                    firstProgramadoPendienteInfo = { fechaDiff, index };

                if (!programadosPending.includes(pedido.id_pedido))
                    newProgramadosPending.push(pedido.id_pedido);
            }
        }
    }

    /* En la lista local de Programados quita los recien notificados 
        y/o agrega los nuevos Programados */
    const filterProgramadosPending = () => {

        return programadosPending.reduce((prevValue, currentId) => {

            if (!programadosNotified.includes(currentId)) {
                prevValue.push(currentId);
            }

            return prevValue;

        }, newProgramadosPending);
    }

    /* Activa el background timer si el tiempo de espera no es mayor a un dia (1440 minutos)
        Cuando acaba el timer se recargan los pedidos */
    const checkSetTimer = ({ fechaDiff, index }) => {
        if (index !== -1) {
            if (fechaDiff < 1440) {
                startWorker(fechaDiff - minutosPreviosAProcesamiento, onTimerDone);
            }
        }
    }

    /* Actualiza la lista local de Programados pendientes a ser notificados */
    const checkSetStoragePendientes = () => {
        if (newProgramadosPending.length > 0
            || programadosNotified.length > 0) {
            const filteredProgramadosPending = filterProgramadosPending();
            localStorage.setItem("PedidosProgramadosPendientes", JSON.stringify(filteredProgramadosPending));
        }
    }

    programadosNotifyAndSetTimerInfo();

    if(onTimerDone) {
        checkSetTimer(firstProgramadoPendienteInfo);
        checkSetStoragePendientes();
    }
};

export const resetTimer = () => stopWorker();


