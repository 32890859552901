import React, { useState, useEffect, useLayoutEffect, useRef } from "react";
import { randomBytes } from 'crypto';

const getElementId = () => {

    const randomString = randomBytes(16).toString('hex');
    return "multi-select-wrapper-" + randomString;
}

export default function MultiSelect(props) {

    const { options = [], selectedOptionsIndeces = [], onSelectedChange, zIndex = 1 } = props;

    const elementRef = useRef(getElementId());

    const [openOptions, setOpenOptions] = useState(false);
    const [modalLayout, setModalLayout] = useState({ width: 0, top: 0, left: 0 })

    const disabled = options.length === 0;

    useLayoutEffect(() => {
      
        if(openOptions) {
            const inputElement = document
            .getElementById(elementRef.current)
            .getElementsByClassName('multi-select-input')[0];

            setModalLayout({ 
                width: inputElement.offsetWidth, 
                top: inputElement.offsetTop + inputElement.offsetHeight + 2, 
                left: inputElement.offsetLeft
            });
        }

    }, [openOptions]);

    useEffect(() => {
      
        if(openOptions) document.getElementById(elementRef.current).focus();

    }, [openOptions]);
    
    const selectOption = (index) => {

        const currentlySelected = selectedOptionsIndeces.indexOf(index);

        onSelectedChange(currentlySelected === -1 ?
            [...selectedOptionsIndeces, index]
            : [...selectedOptionsIndeces].filter(sel => sel !== index)
        );
    };

    const getselectedOptionsText = () => {

        if(options.length === 0) return "Sin opciones";

        const optionsSelected = selectedOptionsIndeces.map((option) => options[option]);

        return optionsSelected.length > 0 ?
            optionsSelected.length === options.length ?
            "Todos"
            : optionsSelected.join(", ")
            : "Seleccionar";
    };

    const captureEscapeAndEnter = (e) => {

        if (e.key === "Escape" || e.key === "Enter") {
            e.stopPropagation();
            document.getElementById(elementRef.current).blur();
        }
    };

    const selectedOptionsText = getselectedOptionsText();

    return (
        <div
            id={elementRef.current}
            className="multi-select"
            tabIndex={"0"}
            style={{ zIndex }}
            onKeyDown={captureEscapeAndEnter}
            onBlur={() => setOpenOptions(false)}
        >
            <div
                className={'multi-select-input row full'}
                onClick={!disabled ? () => setOpenOptions(true) : undefined}
            >
                <p>
                    {selectedOptionsText}
                </p>
            </div>
            {openOptions &&
                <div className='multi-select-modal column' style={{ ...modalLayout }}>
                    {
                        options.map((option, index) =>
                            <div
                                key={index}
                                className='row align-center multiple-option'
                                onPointerDown={() => selectOption(index)}
                            >
                                <input
                                    type='checkbox'
                                    checked={selectedOptionsIndeces.indexOf(index) !== -1}
                                    readOnly
                                />
                                <p>{option}</p>
                            </div>
                        )
                    }
                </div>
            }
        </div>
    )
}